<div class="float-end pt-3 pe-3">
  <div class="btn-group">
      <button type="button" class="btn bg-transparent border-0 p-0" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-three-dots bi-large"></i>
      </button>
      <ul class="dropdown-menu">
        <li>
            <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('editPerson', actorInSendgroup)"
              id="editPersonButton" [ngClass]="{ 'disabled': !actorInSendgroup.allowedToEdit }"
              translate="Request.Actions.EditPerson">
            </a>
          </li>
          <li>
            <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('resendRequest', actorInSendgroup)"
              id="sendReminderButton" [ngClass]="{ 'disabled': !actorInSendgroup.allowedToResendInvite }"
              translate="Request.Actions.SendReminder"></a>
          </li>

          <li><a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('openEmailPreview', actorInSendgroup)" translate="Button.ShowInvite"></a></li>
      </ul>
  </div>
</div>

<div class="d-flex align-items-center justify-content-center accordion-mobile-item">
  <div class="ps-3 w-100 align-items-center justify-content-center py-3" [ngClass]="{'text-primary fw-bold cursor-pointer': actorInSendgroup.grouped && !itemGrouped }">

    <ng-container *ngIf="!itemGrouped">
      <span *ngIf="actorInSendgroup.grouped"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample" class="cursor-pointer">
        {{actorInSendgroup.fullName}}
        <i class="bi" [ngClass]="{ 'bi-caret-down-fill': !isCollapsed, 'bi-caret-up-fill': isCollapsed}"></i>
      </span>
      <span *ngIf="!actorInSendgroup.grouped">
        {{actorInSendgroup.fullName}}
      </span>
    </ng-container>
    <br />
    <span class="mobile-text" [ngClass]="{ 'text-secondary': !actorInSendgroup.grouped, 'cursor-pointer': actorInSendgroup.grouped }">{{getActionsByActor()}}</span><br />
    <span class="mobile-text" [ngClass]="{ 'text-secondary': !actorInSendgroup.grouped, 'cursor-pointer': actorInSendgroup.grouped }">{{getDocumentsByActor()}}</span>

    <div class="break-words" *ngIf="actorInSendgroup.declineMessage">
      <div class="alert alert-danger p-1 mt-0 white-space table-alert-box-font-size me-2 mt-1 mb-0" role="alert">
          {{actorInSendgroup.declineMessage}}
      </div>
    </div>

  </div>
</div>