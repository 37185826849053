import { Injectable, OnDestroy } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from "rxjs";
import { ActorActionEnum, Is_ANY_Download_Action, Is_NON_Download_Action, Is_Sign_Action } from "src/app/constants/actor-action.enum";
import { DocumentTypeEnum } from "src/app/constants/document-type.enum";
import { DocumentActor } from "src/app/models/actors";
import { DocumentModel } from "src/app/models/document.model";
import { EditorActor } from "src/app/models/requestEditor";
import { BuilderRequest, ValidationSummaryClass } from "src/app/models/requests";
import { Workflow, WorkflowStep1Placeholder, WorkflowStep3Placeholder } from "src/app/models/workflow";
import { ActorApiClient } from "src/app/services/api-clients/actor.api-client";
import { WorkflowService } from "src/app/services/workflow.service";
import { EmailPreviewDialogComponent, EmailPreviewModalOptions } from "./email-preview-dialog/email-preview-dialog.component";
import { LoggingService } from "src/app/services/logging.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PdfPreparationDialog } from "./pdf-preparation-dialog/pdf-preparation-dialog.component";
import { PlatformHubService } from "src/app/services/platformhub.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IdentityOrganisationApiClient } from "src/app/services/api-clients/identity-organisation.api-client";
import { User } from "src/app/models/user";
import { SendGroupModel, UpdateSendGroupDeadlineDaysModel, UpdateSendGroupDeadlineModel, UpdateSendGroupNameModel } from "src/app/models/sendgroup.model";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "src/app/services/toast.service";
import { DocumentApiClient } from "src/app/services/document.apiclient";
import { RequestApiClient } from "src/app/services/request.apiclient";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { BaseActorCreateClass } from "src/app/models/api/actor-create.model";
import { DialogAddActionComponent } from "./dialog-add-action/dialog-add-action.component";
import { ChangeActionsInPersonSendGroupModel } from "src/app/models/api/create-sendgroup.model";
import { DoesNotExistsIn, SingleOrDefault } from "src/app/_helpers/ArrayExtensionMethodHelper.functions";
import { IdentityProviderConstants } from "src/app/constants/identity-provider.constants";
import { ModalOpenerService } from "src/app/modals/modal-opener.service";
import { BuilderSendgroupApiClient } from "src/app/services/api-clients/builder-sendgroup.api-client";

@Injectable({ providedIn: 'root' })
export class RequestBuilderService implements OnDestroy {
  private _request!: BuilderRequest;
  private _documents!: DocumentModel[];
  private _actors!: DocumentActor[];
  private _validationSummary: ValidationSummaryClass = new ValidationSummaryClass();
  private _persons!: DocumentActor[];
  private _sendgroups!: SendGroupModel[];
  private _requestGuid!: string;
  private _progress: number[] = [0, 0, 0];

  public request$: BehaviorSubject<BuilderRequest> = new BehaviorSubject<BuilderRequest>({} as BuilderRequest);
  public documents$: BehaviorSubject<DocumentModel[]> = new BehaviorSubject<DocumentModel[]>([] as DocumentModel[]);
  public persons$: BehaviorSubject<DocumentActor[]> = new BehaviorSubject<DocumentActor[]>([] as DocumentActor[]);
  public validationSummary$: BehaviorSubject<ValidationSummaryClass> = new BehaviorSubject<ValidationSummaryClass>(this._validationSummary);
  public sendgroups$: BehaviorSubject<SendGroupModel[]> = new BehaviorSubject<SendGroupModel[]>([] as SendGroupModel[]);
  public actors$: BehaviorSubject<DocumentActor[]> = new BehaviorSubject<DocumentActor[]>([] as DocumentActor[]);
  public workflowSteps$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([] as string[]);
  public workflowData$: BehaviorSubject<Workflow> = new BehaviorSubject<Workflow>({} as Workflow);
  public progress$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([] as number[]);
  public isInFirstSendgroup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public requestGuid$: BehaviorSubject<string> = new BehaviorSubject<string>({} as string);
  private requestSignalRSubscription: Subscription | null = null;
  private usersubscription: Subscription | null = null;

  private downloadDeadlineDays = 30;
  private workflowId: string = "";

  public tempNewBulkActions: BaseActorCreateClass[] = [];


  workflowData?: Workflow;
  signYourself: boolean = false;
  secureFileSending: boolean = false;
  placeholdersStep3: boolean = false;
  sent: boolean = false;
  currentTab: string = "AddDocuments";
  isMobile: boolean = false;
  notSupported: boolean = false;

  /*
    This window that is openend when creating a request whree you are the first actor
    Needed as variable since the opening of the window needs special care.
  */
  windowToOpenForIfActorInFirstSendGroup: Window | null = null;

  public signYourselfWorkflow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentTab$: BehaviorSubject<string> = new BehaviorSubject(this.currentTab);



  /**
   * the ALLOWED identity providers linked to the initialized Workflow
   * Can be Empty, which means all NORMAL Identity providers should be used
   */
  onlyAllowedIdentityProviders: string[] = [];

  /**
   * This stores Closed/Expanded Sendgroups for Step 4.
   * They are stored here so they are retained on refrshes :)
   */
  collapsedSendgroups: string[] = [];

  private builderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  builderData$: Observable<number> = this.builderSubject.asObservable();
  modalRef!: any;

  deadlineDays!: number;
  deadline!: Date;
  workflowSteps: string[] = [];
  organisationHasKVK: boolean = false;
  user!: User;
  translations: any = <any>{};
  countDocuments: number = 0;
  countPersons: number = 0;

  constructor(private translateService: TranslateService, private toastService: ToastService, private workflowService: WorkflowService, private actorApiClient: ActorApiClient, private modalService: NgbModal, private loggingService: LoggingService, private sanitizer: DomSanitizer,
    private platformHub: PlatformHubService, private authenticationService: AuthenticationService, private documentService: DocumentApiClient, private identityOrganisationService: IdentityOrganisationApiClient, private requestService: RequestApiClient,
    private router: Router, private sendGroupApiClient: BuilderSendgroupApiClient, private modalOpenerService: ModalOpenerService,
  ) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
    this.usersubscription = this.authenticationService.currentUser.subscribe((data) => {
      this.user = data;
    });
    this.requestSignalRSubscription = this.platformHub.builderRequest$.subscribe((request: BuilderRequest) => {
      if (request && request.id === this._requestGuid) {
        if (request.workgroup && !request.workgroupName) {
          let workgroup = this.authenticationService.workgroups.find(workgroup => workgroup.Guid === request.workgroup);
          if (workgroup != null) request.workgroupName = workgroup.Name;
        }
        if (request.documentInfo) {
          this.RemoveInvalidDocuments().catch((error) => this.loggingService.logException(error));
        }
        this.setRequest(request);
      }
    });


    this.isMobile = /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/.exec(navigator.userAgent) !== null;
  }

  ngOnDestroy(): void {
    if (this.usersubscription) {
      this.usersubscription.unsubscribe();
    }
    if (this.requestSignalRSubscription) {
      this.requestSignalRSubscription.unsubscribe();
    }
  }

  getTranslations() {
    this.translateService.get([
      'Toasts.ToastsRequestDocumentDeleted',
      'Toasts.UpdateDossierError',
      'Toasts.GetDocumentError',
      'Toasts.RequestSentError',
      'Toasts.RequestsPurgeError',
      'Toasts.DocumentTypeNotSupportedInWorkflow',
      'Toasts.Error',
      'Toasts.Sent',
      'Toasts.RequestSent',
      'Toasts.Deleted',
      'Toasts.Saved',
      'Toasts.KvKHeader',
      'Toasts.KvKBody',
      'Toasts.ContainsPreviousSignatures',
      'Toasts.RequestDocumentDeleteError'
    ]).subscribe(translation => {
      this.translations.toastsRequestDocumentDeleted = translation['Toasts.ToastsRequestDocumentDeleted'];
      this.translations.toastsUpdateDossierError = translation['Toasts.UpdateDossierError'];
      this.translations.toastsGetDocumentError = translation['Toasts.GetDocumentError'];
      this.translations.toastsRequestSentError = translation['Toasts.RequestSentError'];
      this.translations.toastsRequestsPurgeError = translation['Toasts.RequestsPurgeError'];
      this.translations.toastsDocumentTypeNotSupportedInWorkflow = translation['Toasts.DocumentTypeNotSupportedInWorkflow'];
      this.translations.error = translation['Toasts.Error'];
      this.translations.toastsSent = translation['Toasts.Sent'];
      this.translations.toastsRequestSent = translation['Toasts.RequestSent'];
      this.translations.toastsDeleted = translation['Toasts.Deleted'];
      this.translations.toastsSaved = translation['Toasts.Saved'];
      this.translations.KvKHeader = translation['Toasts.KvKHeader'];
      this.translations.KvKBody = translation['Toasts.KvKBody'];
      this.translations.toastsContainsPreviousSignatures = translation['Toasts.ContainsPreviousSignatures'];
      this.translations.toastsRequestDocumentDeleteError = translation['Toasts.RequestDocumentDeleteError']
    });
  }

  // Method to update the count
  updateNotPlacedSignFieldCount(newCount: number): void {
    this.builderSubject.next(newCount);
  }
  /**
   * 
   * @param workflowId Loads the workflow associated with the Id into this service for Use
   */
  async initializeForWorkflow(workflowId: string) {
    this.workflowData = await this.workflowService.getWorkflowById(workflowId)
    this.onlyAllowedIdentityProviders = this.workflowData.step2.allowedIdentityProviders;

    this.checkWorkflows();
  }

  public changeCurrentTab(tabName: string) {
    this.currentTab = tabName;
    // if()
    this.currentTab$.next(this.currentTab);

    this.checkWorkflows();
  }

  private async checkWorkflows() {
    this.signYourself = (this.workflowData?.step2?.skipStep && this.workflowData?.step4?.skipStep) ?? false;
    this.signYourselfWorkflow$.next(this.signYourself);
    this.secureFileSending = this.workflowData?.step3?.skipStep ?? false;
    this.placeholdersStep3 = (this.workflowData?.step3?.placeholders && this.workflowData?.step3?.placeholders.length > 0) ?? false;

    if (this.signYourself && this.currentTab === "AddActions") await this.addActionsSignYourselfWorkflow(this._documents);
    else if (this.placeholdersStep3 && this.currentTab === "AddActions") await this.addPlaceholderActions(this.workflowData?.step3?.placeholders ?? []);
    if (this.secureFileSending && this.currentTab === "ReviewAndSend") await this.addSecureFileSendingActions(this._persons);
  }

  public getRequestSendGroups(): SendGroupModel[] {
    return this._request.builderSendgroups!;
  }

  public getRequestUniqueActors() {
    return this._actors.reduce((unique: any[], o: any) => {
      if (!unique.some((obj: EditorActor) => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
  }

  public getCountActionsOfActor(actorId: string) {
    return this._actors.filter(actor => actor.dossierPersonId === actorId).length ?? 0;
  }

  public getCountActorsOfDocument(documentId: string) {
    return this._actors.filter(actor => actor.documentId === documentId).length ?? 0;
  }

  public getActionsPerDocument(documentId: string) {
    return this._actors.filter(actor => actor.documentId === documentId);
  }

  async openEmailPreview(event: any) {
    let actor = this._actors.filter(item => item.dossierPersonId === event.id && item.action)[0];
    if (actor && actor !== undefined) {
      (await this.actorApiClient.getEmailTemplate(this._request.id, actor.id)).subscribe({
        next: (value: any) => {

          const modalRef = this.modalService.open(EmailPreviewDialogComponent, EmailPreviewModalOptions);
          modalRef.componentInstance.emailTemplate = value;
        }
      });
    }
  }

  public openActionDialog(documentGuid: string, action: ActorActionEnum) {
    // SHORTCUT if only 1 Person:
    if (this._persons && this._persons.length == 1) {
      let personId = this._persons[0].dossierPersonId;
      this.AddNewBulkActorForRequest(action, personId, documentGuid);
      this.SendBulkActorsRequest(action);
      return;
    }

    let ngbModalOptions: NgbModalOptions = {
      keyboard: false,
      centered: true,
      size: 'md',
      backdrop: 'static'
    };
    this.modalRef = this.modalService.open(DialogAddActionComponent, ngbModalOptions);
    this.modalRef.componentInstance.action = action;
    this.modalRef.componentInstance.request = this._request;
    let y = this;
    this.modalRef.componentInstance.newAction.subscribe(async (dataModel: any) => {
      this.modalRef.close();

      dataModel.personIds.forEach(async (personId: string) => {
        this.AddNewBulkActorForRequest(action, personId, documentGuid);
      });
      this.SendBulkActorsRequest(action);

    });
  }

  public openActionDialogWithoutDocumentId(action: ActorActionEnum) {
    this.openActionDialog(this._documents[0].id, action);
  }

  public setRequestGuid(requestGuid: string) {
    let previousRequestGuid = this._requestGuid;
    this._requestGuid = requestGuid;
    if (previousRequestGuid == this._requestGuid) {
      // no changes
      return;
    }

    this.requestGuid$.next(this._requestGuid);

    if (this._requestGuid === "") {
      // we are resetting requestGuid;
      this.setRequest({} as BuilderRequest);
      return;
    }
  }
  public setRequest(request: BuilderRequest) {
    this._request = request;
    if (!this._request) return;
    this.request$.next(this._request);
    if (this._request.documentInfo) this.setDocuments(this._request.documentInfo);
    if (this._request.dossierPersons) this.setPersons(this._request.dossierPersons);
    if (this._request.actors) this.setActors(this._request.actors);
    if (this._request.validationSummary) this.setValidationSummary(this._request.validationSummary);
    if (this._request.builderSendgroups) this.setSendgroups(this._request.builderSendgroups);
    this.updateDocumentProgress();
    // set sendgroups?
  }

  public OpenXMLView(event: any) {
    this.modalOpenerService.Open_ViewXml(this._requestGuid, event.document);
  }

  public OpenXBRLViewer(event: any) {
    this.modalOpenerService.Open_ViewXblrOrTxt(this._request, event.document);
  }


  async OpenPDFViewer(event: any) {
    // if (this.modalService.hasOpenModals()) return;
    let doc = this._documents.find(d => d.id === event.documentGuid);

    let ngbModalOptions: NgbModalOptions;
    if (!this.isMobile) {
      ngbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: false,
        modalDialogClass: 'pkiMd',
        windowClass: 'addSignatureFieldModal'
      };
    }
    else {
      ngbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: false,
        size: 'xl',
        windowClass: 'addSignatureFieldModalMobile'
      };
    }
    this.modalRef = this.modalService.open(PdfPreparationDialog, ngbModalOptions);
    let modal: PdfPreparationDialog = this.modalRef.componentInstance;

    //TODO: refactor to proper inputs and events
    modal.document = doc!;
    // modal.request = this._request;
    modal.actorGuid = event.actor;
    //modal.isMobile = this.isMobile!;
    modal.place = event.place ?? true;
    modal.displayfields = event.displayfields ?? true;
    modal.setMoreSignaturefields = event.actors && event.actors.length > 0;
    modal.allowedMultipleSignaturefields = event.allowedMultipleSignaturefields ?? true;
    modal.showEditDocument = event.showEditDocument ?? false;
    // modal.getRequest.subscribe(() => this.getRequest().catch((error: any) => {
    //   this.loggingService.logException(error);
    // }));
    if (!this.isMobile) {
      modal.zoomLevelChanged.subscribe(async (item: any) => {
        this.modalRef._windowCmptRef.instance.modalDialogClass = item.className;
        setTimeout(() => modal.afterZoomLevelChanged(), 100);
      });
    }
    modal.signatureFieldChanged.subscribe(async (sigField: any) => {
      modal.close();

      await this.updateActionPerson(sigField);
      this.updatePersonProgress();
      if (event.actors && event.actors.length > 0) {
        if (sigField.newActorGuid) {
          modal.actorGuid = sigField.newActorGuid;
          this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: sigField.newActorGuid, actors: event.actors });
        } else {
          let actor = event.actors[0];
          event.actors.splice(0, 1);
          let existingModal = document.querySelector(".addSignatureFieldModal");
          if (existingModal) existingModal.parentNode?.removeChild(existingModal);
          modal.actorGuid = actor;
          this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: actor, actors: event.actors });
        }
      } else if (sigField.newActorGuid) {
        modal.actorGuid = sigField.newActorGuid;
        this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: sigField.newActorGuid });
      }
    });
  }


  public DeleteActorFromRequest(inviteId: string) {
    this.actorApiClient.Delete(this._requestGuid, inviteId).subscribe();
  }
  public CreateSingularActorForRequest(action: ActorActionEnum, personGuid: string, documentGuid: string) {
    this.actorApiClient.CreateSingular(this._requestGuid, action, personGuid, documentGuid).subscribe();
    // THIS REFRESHES IN BE
  }
  /**
   * YOU CAN ASSIGN NEW ACTIONS FOR THE BULK SENDING
   * ALWAYS USE:  SendBulkActorsRequest to finalize this call
   * @param action 
   * @param personGuid 
   * @param documentGuid 
   * @param workflowPlaceholderId OPTIONAL, whether this action is linked to a workflow placeholder
   * @param allowedQtsps OPTIONAL, allowed signing method Qtsps, this is used to filter
   * @param identityProvider OPTIONAL, IdentityProvider that is required for login (used to force Vidua)
   */
  public AddNewBulkActorForRequest(action: ActorActionEnum, personGuid: string, documentGuid: string, workflowPlaceholderId: number | null = null, allowedQtsps: string[] | null = null, identityProvider: string | null = null) {
    if (!this.tempNewBulkActions) {
      this.tempNewBulkActions = [];
    }
    var newActionModel = new BaseActorCreateClass();
    newActionModel.action = action;
    newActionModel.personGuid = personGuid;
    newActionModel.documentGuid = documentGuid;
    newActionModel.workflowPlaceholderId = workflowPlaceholderId;
    newActionModel.allowedQtsps = allowedQtsps;
    newActionModel.identityProvider = identityProvider;
    this.tempNewBulkActions.push(newActionModel);
  }
  /**
   * SENDS all setup BULK actions
   * ALWAYS USE:  AddNewbulkActorForRequest to Setup this call
   * @param action 
   * @param personGuid 
   * @param documentGuid 
   */
  public SendBulkActorsRequest(action: string | null = null) {
    if (!this.tempNewBulkActions || this.tempNewBulkActions.length === 0) {
      return;
    }
    this.actorApiClient.CreateBulk(this._requestGuid, this.tempNewBulkActions).subscribe({
      next: (data: any) => {
        if (action === ActorActionEnum.Sign) {
          let actorInviteIds = data.filter((actor: any) => actor.inviteId != data[0].inviteId);
          var matchedDocument = SingleOrDefault(this._documents, "id", this.tempNewBulkActions[0].documentGuid);
          if (matchedDocument && matchedDocument.documentType == DocumentTypeEnum.RegularPdf) {
            this.OpenPDFViewer({ documentGuid: this.tempNewBulkActions[0].documentGuid, actor: data[0].inviteId, actors: actorInviteIds.map((d: any) => d.inviteId) });
          }
        }
        this.tempNewBulkActions = [];
        if(this.secureFileSending && this.currentTab === "ReviewAndSend") {
          this.mergeSendgroups();
        }
      }, error: () => {
        this.tempNewBulkActions = [];
      }
    });

  }

  public async updateActionPerson(event: any) {
    if (!event.actor || !event.actor.id) return;
    try {
      let updateActionPerson = await this.actorApiClient.updateActionToPerson(this._requestGuid!, event.documentId, event.actor);
      let value: any = await lastValueFrom(updateActionPerson);

      let actorIndex = this._actors?.findIndex(item => item.id == value.id);
      if (this.workflowData?.step3.placeholders && actorIndex && actorIndex === -1) {
        this._actors[actorIndex] = value;
        event.actor = value;
      } else if (actorIndex && actorIndex > -1) {
        this._actors[actorIndex] = value;
        event.actor = value;
      }
      if (this.signYourself && this._documents.length === 1 && ((event.actor.sigFieldW === 0 && event.actor.sigFieldH === 0 && event.actor.sigFieldX === 0 && event.actor.sigFieldY === 0) || (event.actor.sigFieldW === undefined && event.actor.sigFieldH === undefined && event.actor.sigFieldX === undefined && event.actor.sigFieldY === undefined))) {
        this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: event.actor.id });
      }
    } catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  private async addPlaceholderActions(placeholders: WorkflowStep3Placeholder[], i: number = 0) {
    if (this._actors.length > 0) {
      return;
    }
    if (placeholders.length <= i) {
      let signActorsWithoutFields = [...this._actors.filter(s => Is_Sign_Action(s.action) && s.sigFieldPage < 0)];
      if (signActorsWithoutFields.length > 0 && this._documents.find(doc => doc.id === signActorsWithoutFields[0].documentId)?.documentType === "RegularPdf") {
        signActorsWithoutFields.splice(signActorsWithoutFields.findIndex(s => s.actorId === signActorsWithoutFields[0].actorId), 1);
        this.OpenPDFViewer({ documentGuid: this._actors.filter(s => Is_Sign_Action(s.action))[0].documentId, actor: this._actors.filter(s => Is_Sign_Action(s.action))[0].id, allowedMultipleSignaturefields: true });
      }
      return;
    }

    if (!placeholders || placeholders.length <= 0) {
      return;
    }
    placeholders.forEach(placeholder => {
      if ((placeholder.defaultPersonPlaceholderId === null || placeholder.defaultPersonPlaceholderId === undefined) &&
        (placeholder.defaultDocumentPlaceholderId === null || placeholder.defaultDocumentPlaceholderId === undefined) &&
        (placeholder.defaultActions === null || placeholder.defaultActions === undefined || placeholder.defaultActions.length == 0)) {
        return;
      }
      // ARRANGE document for creation
      let placeholderDocumentId = this._documents.find(s => s.workflowPlaceholderId === placeholder.defaultDocumentPlaceholderId)?.id;
      // some actions have no DOCUMENTid, but its still required
      if ((placeholderDocumentId === null || placeholderDocumentId === undefined)) {
        return;
      }
      // Arrange personId(S) for creation
      let placeholderPersons: DocumentActor[] = this._persons.filter(s => s.workflowPlaceholderId === placeholder.defaultPersonPlaceholderId)!;
      if (placeholderPersons.length === 0 && placeholder.alternativePersonPlaceholderId) {
        placeholderPersons = this._persons.filter(s => s.workflowPlaceholderId === placeholder.alternativePersonPlaceholderId)!;
      }
      // now for the persons in placeholders:
      for (let dossierPerson of placeholderPersons) {
        // we are going to assign EVERY default action instead of 1
        for (let defaultAction of placeholder.defaultActions) {
          this.AddNewBulkActorForRequest(defaultAction as ActorActionEnum, dossierPerson.dossierPersonId, placeholderDocumentId!, placeholder.placeholderId, placeholder.allowedQtsps, dossierPerson.identityProvider);
        }
      }
    });

    this.SendBulkActorsRequest();

  }

  private async addActionsSignYourselfWorkflow(documents: DocumentModel[], i: number = 0) {
    let tempDocumentList = documents;
    if (!tempDocumentList || tempDocumentList.length < 1) {
      return;
    }
    if (this._actors.length > 0) {
      return;
    }
    if (this._persons.length > 0) {
      tempDocumentList.forEach(document => {
        this.AddNewBulkActorForRequest(ActorActionEnum.Sign, this._persons[0].dossierPersonId, document.id);
      });
      this.SendBulkActorsRequest(ActorActionEnum.Sign);
    }
  }

  private async addSecureFileSendingActions(persons: DocumentActor[]) {
    if (!persons || persons.length < 1) {
      return;
    }
    let personsWithoutActions: DocumentActor[] = persons.filter(s => DoesNotExistsIn(this._actors, "dossierPersonId", s.dossierPersonId));
    
    if (personsWithoutActions && personsWithoutActions.length > 0) {
      personsWithoutActions.forEach(person => {
        this.AddNewBulkActorForRequest(ActorActionEnum.Download, person.dossierPersonId, this._documents[0].id);
      });
      this.SendBulkActorsRequest();
    }
  }

  public setPersons(persons: DocumentActor[] = []) {
    this._persons = persons;
    this.persons$.next(this._persons);
  }

  public setActors(actors: DocumentActor[] = []) {
    this._actors = actors;
    this.actors$.next(this._actors);
  }

  public setValidationSummary(validationSummary: ValidationSummaryClass | undefined | null) {
    if (!validationSummary) {
      this._validationSummary = new ValidationSummaryClass();
      this.validationSummary$.next(this._validationSummary);

      return;
    }
    this._validationSummary = validationSummary;
    this.validationSummary$.next(this._validationSummary);
  }

  public setSendgroups(sendgroups: SendGroupModel[] | undefined | null) {
    if (!sendgroups) {
      return;
    }
    this._sendgroups = sendgroups;
    this.sendgroups$.next(this._sendgroups);
    if (this._sendgroups?.length > 0 && this._actors?.length > 0) {
      let isCurrentUserAnActorIndex = this._actors.findIndex(s => s.email.toLocaleLowerCase() === this.user.email.toLocaleLowerCase() && s.mobile.toLocaleLowerCase() === this.user.phoneNumber.toLocaleLowerCase());
      if (isCurrentUserAnActorIndex > -1) {
        let isCurrentUserAnActorAsDossierPersonId = this._actors[isCurrentUserAnActorIndex].dossierPersonId;
        if (this._sendgroups[0].actions.filter(s => s.personGuid === isCurrentUserAnActorAsDossierPersonId).length > 0) {
          this.isInFirstSendgroup$.next(true);
        } else {
          this.isInFirstSendgroup$.next(false);
        }
      } else {
        this.isInFirstSendgroup$.next(false);
      }
    }
  }

  public updateSendgroupName(sendGroupGuid: string, sendGroupName: string) {
    var updateModel = new UpdateSendGroupNameModel();
    updateModel.sendGroupGuid = sendGroupGuid;
    updateModel.sendGroupName = sendGroupName;
    this.sendGroupApiClient.updateSendGroupName(this._requestGuid, updateModel).subscribe();
  }

  public updateSendgroupDeadline(sendGroupGuid: string, sendgroupDeadline: Date) {
    var updateModel = new UpdateSendGroupDeadlineModel();
    updateModel.sendGroupGuid = sendGroupGuid;
    updateModel.deadline = sendgroupDeadline;

    this.sendGroupApiClient.updateSendGroupDeadline(this._requestGuid, updateModel).subscribe();
  }

  public updateSendgroupDeadlineDays(sendGroupGuid: string, sendGroupDeadlineDays: number) {
    var updateModel = new UpdateSendGroupDeadlineDaysModel();
    updateModel.sendGroupGuid = sendGroupGuid;
    updateModel.deadlineDays = sendGroupDeadlineDays;

    this.sendGroupApiClient.updateSendGroupDeadlineDays(this._requestGuid, updateModel).subscribe();
  }

  public setDocuments(documents: DocumentModel[] = []) {
    this._documents = documents;
    this.documents$.next(this._documents);
  }

  public setCurrentTab(currentTab: string = "AddDocuments") {
    this.currentTab = currentTab;
    this.currentTab$.next(this.currentTab);
  }

  public setWorkflowId(workflowId: string) {
    this.workflowId = workflowId;
  }

  public getWorkflowId() {
    return this.workflowId;
  }

  async setWorkflow() {
    this.workflowSteps = ['AddDocuments', 'AddPersons', 'AddActions', 'ReviewAndSend'];
    if (!this.workflowId) {
      // somehow you tried to open the builder without 'instructions' (workflow)
      // make sure instructions are loaded:
      let defaultWorkflowGuid = this.workflowService.getDefaultWorkflowGuid();
      this.workflowId = defaultWorkflowGuid;
    }
    // ALWAYS load a workflow:
    try {
      let value: Workflow = await this.workflowService.getWorkflowById(this.workflowId);
      // also load the workflow data into the service so it can be used anywhere in the page!
      this.initializeForWorkflow(this.workflowId);

      this.workflowData = value;
      if (this.workflowData?.step1?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('AddDocuments'), 1);
      if (this.workflowData?.step2?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('AddPersons'), 1);
      if (this.workflowData?.step3?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('AddActions'), 1);
      if (this.workflowData?.step4?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('ReviewAndSend'), 1);

      if (this.workflowData?.requireKVK) {
        this.checkKVK();
      }
      this.workflowData$.next(this.workflowData);
      this.workflowSteps$.next(this.workflowSteps);

    } catch (error: any) {
      this.loggingService.logException(error);
    }
    // this.activeTab = this.workflowSteps[0];
    this.signYourself = (this.workflowData?.step2?.skipStep && this.workflowData?.step4?.skipStep) ?? false;

  }

  private async checkKVK() {
    this.identityOrganisationService.getOrganisationKvk(this.user.organisationGuid).subscribe((KVK) => {
      this.organisationHasKVK = (KVK != null && KVK != "");
    });
  }

  async RemoveInvalidDocuments() {
    if (this._request?.documentInfo?.length > 0) {
      for (let doc of this._request.documentInfo) {

        // remove docs with previous signatures
        if (doc.containsPreviousSignatures && !this.workflowData?.step1.allowPreviousSignatures) {
          // Only delete documents if all documents are done loading, otherwise signalR adds the deleted documents again.
          let allDocumentsHaveBeenProcessed = this._request.documentInfo.filter(d => d.documentStatus !== 'New').length == 0;
          if (allDocumentsHaveBeenProcessed && (doc.documentStatus == 'New' || doc.documentStatus == 'Active')) {
            this.toastService.showError(this.translations.error, this.translations.toastsContainsPreviousSignatures);
            this.deleteDocument(doc.id, false);
          }
          continue;
        }

        // remove forbidden doc types based on workflow:
        if (!this.checkDocumenttypeAllowedInWorkflow(doc.documentType, doc.workflowPlaceholderId)) {
          let placeholder: WorkflowStep1Placeholder | undefined = this.shouldBeOnPlaceholderPosition(doc.documentType);
          if (placeholder == undefined) {
            doc.error = "DocumentTypeNotSupportedInWorkflow";
            if (!this.notSupported) {
              this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsDocumentTypeNotSupportedInWorkflow + "");
            }
            this.notSupported = true;

            if (doc.documentStatus == 'New') {
              this.deleteDocument(doc.id);
            }
          }
          else {
            let existingDocumentForPlaceholder = this._request.documentInfo.find(document => document.workflowPlaceholderId == placeholder!.placeholderId);

            if (existingDocumentForPlaceholder)
              this.updatePlaceholderId(existingDocumentForPlaceholder, null)

            this.updatePlaceholderId(doc, placeholder.placeholderId);
          }
        }

      }
      this.updateDocumentProgress();
    }
    this.calcProgress();
  }

  public deleteDocument(docId: string, showToast: boolean = true): void {
    let document = this._request.documentInfo.find(document => document.id === docId);
    if (!document) return;
    this.documentService.deleteDocument(this._request.id, docId)
      .then((value: any) => {
        lastValueFrom(value).then(() => {
          if (showToast) { this.toastService.showSuccess("" + this.translations.toastsDeleted + "", "" + this.translations.toastsRequestDocumentDeleted + "") };

        }).catch(() => {
          this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestDocumentDeleteError + "");
        });
      }).catch(() => {
        this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestDocumentDeleteError + "");
      });
  }

  public checkDocumenttypeAllowedInWorkflow(documentType: string, placeholderId?: number | null): boolean {
    if (!this.workflowData || !documentType || documentType.toLocaleLowerCase() === "unknown") {
      return true;
    } else if (this.workflowData
      && (!this.workflowData.step1.placeholders || (this.workflowData.step1.allowAddDocuments && placeholderId == null))
      && this.workflowData.step1.supportedDocumentTypes.includes(documentType)) {
      return true;
    }
    else if (this.workflowData
      && this.workflowData.step1.placeholders
      && this.workflowData.step1.placeholders.find(s => s.placeholderId === placeholderId)?.supportedDocumentTypes.includes(documentType)) {
      return true;
    }
    else if (this.workflowData && !this.workflowData.step1.allowRendering) {
      return true;
    }
    else {
      return false;
    }
  }

  shouldBeOnPlaceholderPosition(documentType: string): WorkflowStep1Placeholder | undefined {
    let hasStep1Placeholders: boolean | undefined = this.workflowData?.step1.placeholders && this.workflowData.step1.placeholders.length > 0;

    if (hasStep1Placeholders) {
      let placeholderWithMatchingDocumentType = this.workflowData!.step1.placeholders!.find(placeholder => placeholder.supportedDocumentTypes.includes(documentType));
      let placeholderHasCorrectDocument = this._request.documentInfo.findIndex(document => document.workflowPlaceholderId == placeholderWithMatchingDocumentType?.placeholderId
        && placeholderWithMatchingDocumentType?.supportedDocumentTypes.includes(document.documentType)) > -1;

      if (!placeholderWithMatchingDocumentType || placeholderHasCorrectDocument) return;

      return placeholderWithMatchingDocumentType;
    }
    return;
  }

  updatePlaceholderId(document: DocumentModel, placeholderId: number | null) {
    document.workflowPlaceholderId = placeholderId;
    this.documentService.updateDocumentPlaceholderId(this._request.id, document.id, placeholderId ?? -1)
      .then((data: any) => {
        lastValueFrom(data)
          .catch(error => this.loggingService.logException(error))
      }).catch(error => this.loggingService.logException(error));
  }

  public updateDocumentProgress() {
    if (this.meetsDocumentAmount() && this._request.documentInfo && this._request.documentInfo.length > 0 && this._request.documentInfo.filter(s => s.error).length === 0) {
      this.setProgress(0, ((this._request.documentInfo.filter(p => p.documentStatus !== 'Processing').length / this._request.documentInfo.length) * 100));
    } else {
      this.setProgress(0, 0);
    }
  }

  public setProgress(index: number, progress: number) {
    this._progress[index] = progress;
    this.progress$.next(this._progress);
  }



  public meetsDocumentAmount(): boolean {
    return !this.workflowData
      || !this.workflowData.step1
      || (!this.workflowData.step1.minDocumentAmount && !this.workflowData.step1.placeholders)
      || (!this.workflowData.step1.placeholders && this.workflowData.step1.minDocumentAmount && this._request.documentInfo && this.workflowData.step1.minDocumentAmount <= this._request.documentInfo.length)
      || (this.workflowData.step1.placeholders !== undefined && this.workflowData.step1.placeholders.length === this._request.documentInfo?.filter(docInfo => docInfo.workflowPlaceholderId != null && docInfo.workflowPlaceholderId != undefined).length)
      || (this.workflowData.step1.placeholders !== undefined && this.workflowData.step1.placeholders.filter(placeholder => !placeholder.optional).map(s => s.placeholderId).every((placeholderId) => {
        return this._request.documentInfo?.filter(docInfo => docInfo.workflowPlaceholderId != null && docInfo.workflowPlaceholderId != undefined).map(d => d.workflowPlaceholderId).indexOf(placeholderId) !== -1
      }));
  }

  public calcProgress() {
    if (!this.meetsActionAmount()) {
      this.setProgress(2, 0);
    } else {
      let amountOfObjects: number = 0;
      let completedObjects: number = 0;
      this._request.dossierPersons?.forEach(p => {
        amountOfObjects += 1;
        if (this._request?.actors?.findIndex(a => a.dossierPersonId == p.dossierPersonId && a.action !== null && a.action !== undefined && a.documentId !== null && a.documentId !== undefined && a.documentId !== "") > -1) {
          completedObjects += 1;
        }
      });
      this._request.documentInfo?.filter(s => !s.attachment).forEach(p => {
        amountOfObjects += 1;
        if (this._request?.actors?.findIndex(actionActor => (actionActor.documentId == p.id && (actionActor.action !== null && actionActor !== undefined) && !Is_ANY_Download_Action(actionActor.action)) || (Is_ANY_Download_Action(actionActor.action) && this._request.actors.every(allActor => Is_ANY_Download_Action(allActor.action)))) > -1) {
          // do the sign actions WITHOUT fields but being PDFs match the Total sign actions?
          var signActorsWithoutFieldCount = this._request.actors.filter(signActorWithoutField => (Is_Sign_Action(signActorWithoutField.action) && (!(!signActorWithoutField.sigFieldW && !signActorWithoutField.sigFieldH) || p.documentType !== 'RegularPdf'))).length;
          var totalSignActorsCount = this._request.actors.filter(allSignActor => Is_Sign_Action(allSignActor.action)).length;
          if (signActorsWithoutFieldCount === totalSignActorsCount) {
            completedObjects += 1;
          }
        }
      });
      if (this._request.actors != null && this._request.actors != undefined) {
        this._request.actors.forEach(a => {
          amountOfObjects += 1;
          if (a.action !== null && a.action !== undefined && a.documentId !== null && a.documentId !== undefined && a.documentId !== "") {
            completedObjects += 1;
          }
        })
      }
      // cannot divide by 0
      if (amountOfObjects == 0) { amountOfObjects = 1; }
      let progress: number = ((completedObjects / amountOfObjects) * 100);
      this.setProgress(2, progress);
    }
  }

  public meetsActionAmount(): boolean {
    return !this.workflowData
      || !this.workflowData.step3
      || (!this.workflowData.step3.minActionAmount && !this.workflowData.step3.placeholders)
      || (!this.workflowData.step3.placeholders && this.workflowData.step3.minActionAmount && this._request.actors && this.workflowData.step3.minActionAmount <= this._request.actors.length)
      || (this.workflowData.step3.placeholders !== undefined && (this.workflowData.step3.placeholders.length === this._request.actors?.filter(actor => actor.workflowPlaceholderId != null && actor.workflowPlaceholderId != undefined).length
        || this.workflowData?.step3.placeholders?.filter(placeholder => !placeholder.optional).map(s => s.placeholderId).every((placeholderId) => {
          return this._request.actors?.filter(actor => actor.workflowPlaceholderId != null && actor.workflowPlaceholderId != undefined)
            .map(d => d.workflowPlaceholderId)
            .indexOf(placeholderId) !== -1 && this._request?.actors?.filter(s => Is_NON_Download_Action(s.action)).filter((v, i, a) => a.findIndex(v2 => (v2.documentId === v.documentId)) === i).length === this._request?.documentInfo?.length
        })));
  }

  updateProgress(position: number, progress: number) {
    this.setProgress(position, progress);
    this.getDocumentsWithoutActions();
    this.getPersonsWithoutActions();
    this.getActionsWithoutPlacedSignatures();
  }


  public getActionsWithoutPlacedSignatures() {
    let signAbleActions = this._request.actors?.filter(actor => Is_Sign_Action(actor.action) && this._request.documentInfo.find(x => x.id == actor.documentId)?.documentType == DocumentTypeEnum.RegularPdf);
    if (signAbleActions != null && signAbleActions != undefined) {
      let totalSignAbleActions = signAbleActions.length;
      let totalSignedActions = signAbleActions.filter(a => (!(!a.sigFieldW && !a.sigFieldH))).length;
      this.updateNotPlacedSignFieldCount(totalSignAbleActions - totalSignedActions);
    }
  }
  getDocumentsWithoutActions() {
    this.countDocuments = 0;
    // has only download Actors, and no other Actions?
    if (this._request.actors?.filter(p => Is_ANY_Download_Action(p.action)).length > 0 && this._request.actors?.filter(p => Is_NON_Download_Action(p.action)).length === 0) {
      this.countDocuments = 0;
    } else {
      // or document has no Actors/only download
      this._request.documentInfo?.forEach((document, i) => {
        if (!document.actors || document.actors === undefined || document.actors.filter(actor => Is_NON_Download_Action(actor.action)).length === 0) {
          this.countDocuments += 1;
        }
      });
    }
  }

  getPersonsWithoutActions() {
    this.countPersons = this._request.dossierPersons?.length;
    this._request.dossierPersons?.forEach((s, i) => {
      if (this._request.actors.filter(p => p.dossierPersonId === s.dossierPersonId).length > 0) {
        this.countPersons -= 1;
      }
    });
  }

  async addMyselfForSigning() {
    let myself = new DocumentActor();
    myself.firstname = this.user.firstname;
    myself.prefix = this.user.prefix ?? '';
    myself.lastname = this.user.lastname;
    myself.email = this.user.email;
    myself.mobile = this.user.phoneNumber;
    myself.language = localStorage.getItem('language')?.toUpperCase() ?? 'NL';
    myself.validateRealIdentity = false;
    myself.identityProvider = this.workflowData?.step2?.allowedIdentityProviders && this.workflowData?.step2?.allowedIdentityProviders.length > 0 ? this.workflowData?.step2?.allowedIdentityProviders[0] : IdentityProviderConstants.Default;
    myself.ordinal = 0;
    await this.addPersonCall(myself);
    this.updatePersonProgress();

  }
  private async addPersonCall(person: DocumentActor) {
    (await this.actorApiClient.addDossierPerson(this._request.id, person)).subscribe({
      error: (error: HttpErrorResponse) => {
        this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsAddPersonError + "");
      },
      next: (value: any) => {
      }
    });
  }


  public async getRequest() {
    await this.requestService.getRequestForBuilder(this._requestGuid!).then(dossierResp => {
      lastValueFrom(dossierResp)
        .then(async (request: BuilderRequest) => {
          this._request = request;
          this.setRequestGuid(this._requestGuid!);
          this.setRequest(request);
          if (this._request.workflowId) {
            this.workflowId = this._request.workflowId;

            this.setWorkflowId(this.workflowId);
            // also load the workflow data into the service so it can be used anywhere in the page!
            this.initializeForWorkflow(this.workflowId);
          }
          await this.setWorkflow();
          if (Object.keys(this._request).length !== 0) {
            if (this._request.status && this._request.status.toLocaleLowerCase() != "new" && this._request.status.toLocaleLowerCase() != "active" && this._request.status.toLocaleLowerCase() != "error" && this._request.status.toLocaleLowerCase() != "processing")
              this.router.navigate(["/request/" + this._requestGuid + ""])
                .catch(error => {
                  this.loggingService.logException(error);
                });

            let documentModels: DocumentModel[] = this._request.documentInfo ?? [];
            for (let doc of documentModels) {
              if (!this.checkDocumenttypeAllowedInWorkflow(doc.documentType, doc.workflowPlaceholderId)) {
                doc.error = "DocumentTypeNotSupportedInWorkflow";
                if (!this.notSupported) {
                  this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsDocumentTypeNotSupportedInWorkflow + "");
                }
                this.notSupported = true;

                if (doc.documentStatus == 'New') {
                  this.deleteDocument(doc.id);
                }
              }
            }
            if (this._request.documentInfo && this._request.documentInfo.length > 0 && this._request.documentInfo.every(s => s.documentStatus.toLocaleLowerCase() === 'new' || s.documentStatus.toLocaleLowerCase() === 'active') && this.meetsDocumentAmount() && this._request.documentInfo.filter(s => s.error).length === 0) this.setProgress(0, 100)
            this.updatePersonProgress();

            if (this._request.actors && this._request.actors.length > 0) {
              this.calcProgress();
            }
          }
          this.getDocumentsWithoutActions();
          this.getPersonsWithoutActions();
          this.getActionsWithoutPlacedSignatures();
        }).catch((error) => this.loggingService.logException(error));
    });

  }

  public updatePersonProgress() {
    if (this.meetsPersonAmount() && this._request.dossierPersons && this._request.dossierPersons.length > 0) {
      this.setProgress(1, 100);
    } else {
      this.setProgress(1, 0);
    }
  }

  public meetsPersonAmount(): boolean {
    return !this.workflowData
      || !this.workflowData.step2
      || (!this.workflowData.step2.minPersonAmount && !this.workflowData.step2.placeholders)
      || (!this.workflowData.step2.placeholders && this.workflowData.step2.minPersonAmount && this._request.dossierPersons && this.workflowData.step2.minPersonAmount <= this._request.dossierPersons.length)
      || (this.workflowData.step2.placeholders != undefined && this.workflowData.step2.placeholders.length === this._request.dossierPersons?.filter(person => person.workflowPlaceholderId != null && person.workflowPlaceholderId != undefined).length)
      || (this.workflowData.step2.placeholders !== undefined && this.workflowData.step2.placeholders.filter(placeholder => !placeholder.optional).map(s => s.placeholderId).every((placeholderId) => {
        return this._request.dossierPersons?.filter(dossierPerson => dossierPerson.workflowPlaceholderId != null && dossierPerson.workflowPlaceholderId != undefined).map(d => d.workflowPlaceholderId).indexOf(placeholderId) !== -1
      }));
  }

  public updateRequest(event: any) {
    if (event?.dossierPersons == undefined) event.dossierPersons = [];
    this.setRequest(event);
    if (this._requestGuid == undefined || this._requestGuid == null) {
      this._requestGuid = event.id;
    }
    if (this._request.documentInfo?.length > 0 && this.meetsDocumentAmount()) {
      this.setProgress(0, ((this._request.documentInfo.filter(p => p.documentStatus !== 'Processing').length / this._request.documentInfo.length) * 100));
    }
    this.calcProgress();
    this.getDocumentsWithoutActions();
    this.getPersonsWithoutActions();
    this.getActionsWithoutPlacedSignatures();
  }

  public getRequestById(event: string) {
    if (this._requestGuid === null || this._requestGuid === undefined) {
      this.setRequestGuid(event);
    }
    this.requestService.extendDeadlinesIfNeeded(this._requestGuid).subscribe();
    this.getRequest().then().catch((error: any) => {
      this.loggingService.logException(error);
    });
  }

  public async changeOrderActors(actor: DocumentActor) {
    (await (this.actorApiClient.changeOrder(this._requestGuid, actor.documentId, actor.id, this._request.actors))).subscribe({
      error: (error: HttpErrorResponse) => { },
      next: (value: any) => {
      }
    });
  }

  public async mergeSendgroups() {
    await (this.sendGroupApiClient.mergeSendgroups(this._requestGuid)).subscribe();
  }

  async SendRequest() {
    if (this.workflowData?.requireKVK && !this.organisationHasKVK) {
      if (this.toastService.toasts.findIndex(p => p.header === this.translations.KvKHeader) == -1)
        this.toastService.showError(this.translations.KvKHeader, this.translations.KvKBody, 10000);
      return;
    }
    let currentUserIsActor = this._actors.findIndex(s => s.email === this.user.email && s.mobile === this.user.phoneNumber);
    if (currentUserIsActor > -1) {
      let firstSendGroup = this._sendgroups.find(s => Math.min(s.order))!;
      let userInFirstSendgroup = firstSendGroup.actions.find(s => s.personGuid === this._actors[currentUserIsActor].dossierPersonId)
      if (userInFirstSendgroup) {
        this.windowToOpenForIfActorInFirstSendGroup = window.open(undefined, '_blank');
      }
      let url = this.router.serializeUrl(this.router.createUrlTree(["/view/" + this._requestGuid]));
      if (Is_ANY_Download_Action(firstSendGroup.actions[0].actionType)) {
        url = this.router.serializeUrl(this.router.createUrlTree(["/download/" + this._requestGuid]));
      }
      if (this.windowToOpenForIfActorInFirstSendGroup !== undefined && this.windowToOpenForIfActorInFirstSendGroup !== null) {
        this.windowToOpenForIfActorInFirstSendGroup.location.href = url;
      }
      this.router.navigate(["/dashboard"])
        .catch(error => {
          this.loggingService.logException(error);
        });
    } else {
      this.toastService.showSuccess(this.translations.toastsSent, this.translations.toastsRequestSent);
      this.router.navigate(["/dashboard"])
        .catch(error => {
          this.loggingService.logException(error);
        });
    }
    try {
      let sendReq = await this.requestService.sendRequest(this._request.id);
      await lastValueFrom(sendReq);
      this.setRequestGuid("");
    } catch (error: any) {
      this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestSentError + "")
    }
  }

  public async createSendgroup(requestGuid: string, order: number, ForPersonGuids: string[]) {
    await (this.sendGroupApiClient.createSendgroup(requestGuid, order, ForPersonGuids)).subscribe();
  }

  public async assignToSendgroup(requestGuid: string, sendGroupGuid: string, ForPersonGuids: string[]) {
    await (this.sendGroupApiClient.assignSendgroup(requestGuid, sendGroupGuid, ForPersonGuids)).subscribe();
  }

  public async changeOrderActorsInSendGroup(actorsToUpdate: ChangeActionsInPersonSendGroupModel[], dossierPersonGuid: string) {
    (await (this.sendGroupApiClient.changeActorsByPersonOrder(this._requestGuid, dossierPersonGuid, actorsToUpdate))).subscribe({
      error: (error: HttpErrorResponse) => { },
      next: (value: any) => {
      }
    });
  }
}
