<div class="row w-100 d-none d-lg-flex p-0 align-items-center"
  [ngClass]="{'text-primary fw-bold cursor-pointer': actorInSendgroup.grouped && !itemGrouped }">
  <div class="col-3 firstcol text-truncate">
    <ng-container *ngIf="!itemGrouped">
      <span *ngIf="actorInSendgroup.grouped" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
        class="cursor-pointer">
        {{actorInSendgroup.fullName }}
        <i class="bi" [ngClass]="{ 'bi-caret-down-fill': !isCollapsed, 'bi-caret-up-fill': isCollapsed}"></i>
      </span>
      <span *ngIf="!actorInSendgroup.grouped">
        {{actorInSendgroup.fullName}}
      </span>
    </ng-container>
  </div>
  <div class="col-2 text-truncate">
    <app-badge-status-display [badgeStatus]="returnStatus()"></app-badge-status-display>
    {{getActionsByActor()}}
  </div>
  <div class="col-4 text-truncate">{{getDocumentsByActor()}}</div>
  <div class="col-2 text-truncate">
    <ng-container *ngIf="itemGrouped && actorInSendgroup.dateSigned">
      <!-- Person with 1 action -->
      {{getCompletionDateByActor() | valueByTypePipe : DataTypeEnum.CompletionDate}}
    </ng-container>
    <ng-container *ngIf="!itemGrouped && actorInSendgroup.dateSigned">
      <!-- Person with multiple actions -->
      <!-- Get latest date of completion for action -->
      {{actorInSendgroup.maxCompletionDate | valueByTypePipe : DataTypeEnum.CompletionDate}}
    </ng-container>
  </div>
  <div class="col-1 text-end">
    <ng-container *ngIf="!actorInSendgroup.dateSigned">
      <span class="float-end">
        <div class="btn-group">
          <button type="button" class="btn bg-transparent border-0 p-0" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots bi-large"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('editPerson', actorInSendgroup)"
                id="editPersonButton" [ngClass]="{ 'disabled': !actorInSendgroup.allowedToEdit }"
                translate="Request.Actions.EditPerson">
              </a>
            </li>
            <li>
              <a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('resendRequest', actorInSendgroup)"
                id="sendReminderButton" [ngClass]="{ 'disabled': !actorInSendgroup.allowedToResendInvite }"
                translate="Request.Actions.SendReminder"></a>
            </li>
            <li><a class="dropdown-item pt-1 pb-1" (click)="performActionForAnId('openEmailPreview', actorInSendgroup)"
                translate="Button.ShowInvite"></a>
            </li>
          </ul>
        </div>
      </span>
    </ng-container>
  </div>
</div>