<ng-container *ngFor="let column of displayedColumns">
  <div class="d-flex align-items-center justify-content-center accordion-mobile-item" *ngIf="column.showMobile"
    [ngClass]="{ 'mt-2': column.columnName === 'status' && request.reasons !== '' }">
    <div class="ps-3 w-100 align-items-center justify-content-center">
      <span class="lato-font text-secondary mobile-text" translate="Table.{{column.transTag}}"></span><br />
      <span>{{ tableData[column.columnName] | valueByTypePipe : column.type }}
        <ng-container *ngIf="column.type === 'Guid'">
          <em class="bi bi-copy cursor-pointer float-end pe-3" aria-hidden="true"
            (click)="copyToClipboard(tableData[column.columnName])"
            onKeyDown="copyToClipboard(tableData[column.columnName])"></em>
        </ng-container>
        <ng-container *ngIf="column.columnName === 'status'">
          <div class="break-words me-1" *ngIf="request.reasons !== ''">
            <div class="alert alert-danger p-1 mt-0 white-space table-alert-box-font-size me-2 mb-3" role="alert">
              {{request.reasons}}
            </div>
          </div>
        </ng-container>
      </span>
    </div>
  </div>
</ng-container>