export enum DataTypeEnum {
    ActorAction = "ActorAction",
    DateTime = "DateTime",
    DocumentType = "DocumentType",
    General = "General",
    FileSize = "FileSize",
    RequestType = "RequestType",
    Status = "Status",
    Guid = "Guid",

    // Specific for sendgroups
    SendDate = "SendDate",
    PlusDays = "PlusDays",
    CompletionDate= "CompletionDate"
}