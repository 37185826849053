import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { HttpResponseCodesEnum } from '../constants/http-request-statusses.enum';

@Injectable()
export class PaymentRequiredInterceptor implements HttpInterceptor {

    constructor(private toastService: ToastService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === HttpResponseCodesEnum.PaymentRequired) {
                    this.toastService.showPaymentRequiredToast();
                    return throwError(() => { return error; });
                }
                return throwError(() => { return error; });
            })
        );
    }
}
