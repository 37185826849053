import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-emailpreviewdialog',
  templateUrl: './email-preview-dialog.component.html'
})
export class EmailPreviewDialogComponent implements OnInit {
  emailPreview!: SafeHtml;

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {
    // Must not be empty. It's approved by a comment.
  }
  emailTemplate!: string;

  ngOnInit(): void {
    this.renderHtml();
  }

  renderHtml() {
    let temp = document.createElement('div');
    temp.innerHTML = this.emailTemplate;
    Array.from(temp.querySelectorAll('a')).forEach(function(ele) {
      ele.href = "javascript:void(0)";
      ele.target = "";
    });
    this.emailPreview = this.sanitizer.bypassSecurityTrustHtml(temp.innerHTML);
  }

  close() {
    this.activeModal.close();
  }
}
