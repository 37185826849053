import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestStatusService } from 'src/app/components/requeststatus/requeststatus.service';
import { CannotContainOnlySpaceValidator } from 'src/app/directives/cannot-contain-only-space-validator.directive';
import { MarkFormGroupTouchedAndDirty } from 'src/app/directives/mark-form-group-as-touched-dirty.function';
import { UpdateSendGroupModel } from 'src/app/models/sendgroup.model';

export const StatusEditSendgroupNameOptions: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  centered: true,
  size: 'md'
};

@Component({
  templateUrl: './status-edit-sendgroup-name.modal.html'
})
export class StatusEditSendgroupNameModal implements OnInit {
  private _sendgroupToUpdate!: UpdateSendGroupModel;
  @Input() set sendgroupToUpdate(val: UpdateSendGroupModel) {
    this._sendgroupToUpdate = val;
  }
  get sendgroupToUpdate(): UpdateSendGroupModel {
    return this._sendgroupToUpdate;
  }
  @Output('setName') setName: EventEmitter<any> = new EventEmitter();

  name!: string;
  form!: UntypedFormGroup;
  nameValue: FormControl;

  constructor(private formBuilder: UntypedFormBuilder, private activeModal: NgbActiveModal, private requestStatusService: RequestStatusService) {
    this.nameValue = new FormControl("", [Validators.required, CannotContainOnlySpaceValidator()]);
    this.form = this.formBuilder.group(
      {
        name: this.nameValue
      }
    );
  }

  ngOnInit() {
    this.form.controls['name'].setValue(this.sendgroupToUpdate.sendGroupName);
  }

  public closeModal() {
    this.activeModal.close();
  }

  save() {
    if (this.form.invalid) {
      MarkFormGroupTouchedAndDirty(this.form);
      return;
    }
    let newName = this.form.controls['name'].value;
    this.sendgroupToUpdate.sendGroupName = newName;
    this.requestStatusService.updateSendgroup(this.sendgroupToUpdate);
    this.closeModal();
  }
}
