import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocumentActor } from '../../models/actors';
import { UpdateExistingActorModel } from 'src/app/models/api/update-existing-actor.model';
import { Observable } from 'rxjs';
import { ActorSendGroupStatusInformationModel, ActorStatusInformationModel } from 'src/app/models/api/actor-status-information.model';
import { ActorResendInviteModel, SendGroupResendInviteModel } from 'src/app/models/api/actor-resend-invite.model';
import { ActorUpdateDeadlineModel } from 'src/app/models/api/actor-update-deadline.model';
import { ActorDeleteModel } from 'src/app/models/api/actor-delete.model';
import { ActorActionEnum } from 'src/app/constants/actor-action.enum';
import { BaseActorCreateClass, BulkActorCreateModel } from 'src/app/models/api/actor-create.model';

/**
 * The Api Client for the ActorsController
 */
@Injectable({
  providedIn: 'root'
})
export class ActorApiClient {

  constructor(private httpClient: HttpClient) { }


  GetForStatusOverview(forRequestGuid: string): Observable<ActorSendGroupStatusInformationModel[]> {
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.get<ActorSendGroupStatusInformationModel[]>(environment.apiUrl + "/actors/" + forRequestGuid + "/status");
  }

  /** CREATE a new Actor, based on existing INFO */
  CreateSingular(forRequestGuid: string, action: ActorActionEnum, personGuid: string, documentGuid: string) {
    var newAction: BaseActorCreateClass = {
      action: action,
      personGuid: personGuid,
      documentGuid: documentGuid
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.CreateBulk(forRequestGuid, [newAction]);
  }
  /** CREATE a new Actor, based on existing INFO */
  CreateBulk(forRequestGuid: string, newActions: BaseActorCreateClass[]) {
    var body: BulkActorCreateModel = {
      requestGuid: forRequestGuid,
      newActions: newActions,
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.post(environment.apiUrl + "/actors/create", body);
  }
  /** Update an existing Actor -> Person information */
  UpdateActorPerson(forRequestGuid: string, forInviteId: string, givenEmail: string, givenMobile: string, givenLanguage: string, givenPersonalMessage: string) {
    var body: UpdateExistingActorModel = {
      requestGuid: forRequestGuid,
      inviteId: forInviteId,
      email: givenEmail,
      mobile: givenMobile,
      language: givenLanguage,
      personalMessage: givenPersonalMessage
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.put(environment.apiUrl + "/actors/actor", body);
  }
  /** Update an existing Actor -> Person information */
  ResendInvite(forRequestGuid: string, forInviteId: string) {
    var body: ActorResendInviteModel = {
      requestGuid: forRequestGuid,
      inviteId: forInviteId
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.post(environment.apiUrl + "/actors/actor/resend-invite", body);
  }
  /** Update an existing Actor -> Person information */
  UpdateDeadlineDays(forRequestGuid: string, forInviteId: string, chosenDayCount: number) {
    var body: ActorUpdateDeadlineModel = {
      requestGuid: forRequestGuid,
      inviteId: forInviteId,
      newDeadlineDayCount: chosenDayCount,
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.post(environment.apiUrl + "/actors/actor/change-deadline", body);
  }
  /** Update an existing Actor -> Person information */
  UpdateDeadline(forRequestGuid: string, forInviteId: string, pickedDeadline: Date) {
    var body: ActorUpdateDeadlineModel = {
      requestGuid: forRequestGuid,
      inviteId: forInviteId,
      newDeadline: pickedDeadline,
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.post(environment.apiUrl + "/actors/actor/change-deadline", body);
  }
  /** Delete an existing Actor  */
  Delete(forRequestGuid: string, forInviteId: string) {
    var body: ActorDeleteModel = {
      requestGuid: forRequestGuid,
      inviteId: forInviteId
    };
    // THIS IS NOT THE GENERIC ACTORS endpoint:
    return this.httpClient.post(environment.apiUrl + "/actors/actor/delete", body);
  }



  // TODO: refactor away, but dashboard relies on this
  async resendRequest(dossierId: string, actorId: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/actors/" + actorId + "/ResendInvite", {});
  }

  async addDossierPerson(dossierId: string, dossierPerson: DocumentActor) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/persons", dossierPerson);
  }

  async updateDossierPerson(dossierId: string, dossierPerson: DocumentActor) {
    return this.httpClient.put(environment.apiUrl + "/requests/" + dossierId + "/persons/" + dossierPerson.dossierPersonId, dossierPerson)
  }

  async removeDossierPerson(dossierId: string, dossierPerson: DocumentActor) {
    return this.httpClient.delete(environment.apiUrl + "/requests/" + dossierId + "/persons/" + dossierPerson.dossierPersonId)
  }

  async changeOrder(dossierId: string, documentId: string, actorId: string, orderPersons: DocumentActor[]) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/actors/ChangeOrder", orderPersons);
  }

  async addActionToPerson(dossierId: string, documentId: string, documentPerson: DocumentActor) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId + "/actors", documentPerson);
  }
  async updateActionToPerson(dossierId: string, documentId: string, documentPerson: DocumentActor) {
    return this.httpClient.put(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId + "/actors/" + documentPerson.id, documentPerson);
  }

  async updateSignaturefieldToPerson(dossierId: string, documentId: string, documentPerson: DocumentActor) {
    return this.httpClient.put(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId + "/actors/" + documentPerson.id + "/signaturefield", documentPerson);
  }

  async getEmailTemplate(dossierId: string, actorId: string) {
    const headers: HttpHeaders = new HttpHeaders({ 'Accept': 'text/html' });
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/actors/" + actorId + "/GetInviteEmailTemplate", { headers: headers, responseType: 'text' });
  }


  async documentsWithdrawn(dossierId: string, actorId: string) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/actors/" + actorId + "/WithdrawInvite", {});
  }
}
