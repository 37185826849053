import { ActorActionEnum } from "src/app/constants/actor-action.enum";

export class BaseActorCreateClass {
    /** Guid */
    personGuid!: string;
    /** Guid */
    documentGuid!: string;
    /** For what action */
    action!: ActorActionEnum;

}

/**
* Model for the Actor Api-Client (controller) to CREATE a new actor
*/
export class ActorCreateModel extends BaseActorCreateClass {
    /** Guid */
    requestGuid!: string;
}
/**
* Model for the Actor Api-Client (controller) to CREATE BULK new actors
*/
export class BulkActorCreateModel {
    /** Guid */
    requestGuid!: string;
    newActions: BaseActorCreateClass[] = [];
}
