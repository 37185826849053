import { Component, Input } from '@angular/core';
import { PillBadgeStatusEnum } from '../../constants/badge-status.enum';

@Component({
  selector: 'pillbadge-status-display',
  templateUrl: './pillbadge-status-display.component.html',
  styleUrl: './pillbadge-status-display.component.scss'
})
export class PillBadgeStatusDisplayComponent {

  @Input() status: PillBadgeStatusEnum | string | null = PillBadgeStatusEnum.Info;
  @Input() disable: boolean = false;
  @Input() iconTag: string = "";
  @Input() data!: string | any;


  private InfoStyle = "info";
  private SecondaryStyle = "secondary";
  private SuccessStyle = "success";
  private WarningStyle = "warning";
  private DangerStyle = "danger";

  get badgeStatusStyleClass(): string {

    let mainOrbStyle = "text-bg-";
    switch (this.status) {
      case PillBadgeStatusEnum.Info:
        return mainOrbStyle + this.InfoStyle;

      case PillBadgeStatusEnum.Secondary:
        return mainOrbStyle + this.SecondaryStyle

      case PillBadgeStatusEnum.Success:
        return mainOrbStyle + this.SuccessStyle

      case PillBadgeStatusEnum.Warning:
        return mainOrbStyle + this.WarningStyle

      case PillBadgeStatusEnum.Danger:
        return mainOrbStyle + this.DangerStyle

      case null:
      case undefined:
      default:
        return mainOrbStyle + this.InfoStyle
    }
  }

  constructor() {
   // comment 
  }

}
