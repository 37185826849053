import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DossierService } from 'src/app/services/dossier.service';
import { ToastService } from 'src/app/services/toast.service';
import { PKIUrls } from 'src/app/constants/pkiurls.constants';
import { DashboardDataService } from '../dashboard-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { RequestItemInDashboard } from 'src/app/models/dashboard';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialogchangeworkgroup',
  templateUrl: './dialogchangeworkgroup.component.html'
})
export class DialogChangeWorkgroupComponent {
  private selectedRequestsSubscription!: Subscription;
  public ShareRequestWorkgroupUrl: string = PKIUrls.ShareRequestWorkgroupUrl;
  workgroups = this.authenticationService.workgroups;
  currentWorkgroup: string =  "";
  currentWorkgroupName: any;
  currentClearancelevel: any;
  clearancelevel: number = 1;
  maxWorkgroupClearanceLevel: any = 1;
  newClearancelevel: number | undefined;
  newWorkgroupId: any;
  newWorkgroupName: string | undefined;
  selectedRequests: any;
  translations = <any>{};

  constructor(
    modalConfig: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private dossierService: DossierService,
    private translateService: TranslateService,
    private dashboardService: DashboardDataService,
    public toastService: ToastService) {  
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
    
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    })

    this.selectedRequestsSubscription = this.dashboardService.selectedRequests$.subscribe((selectedRequests: SelectionModel<RequestItemInDashboard>) => {
        this.selectedRequests = selectedRequests;
        this.currentClearancelevel = this.selectedRequests.selected[0].clearancelevel.toString();
        this.currentWorkgroup = this.selectedRequests.selected[0].workgroup != undefined ? this.selectedRequests.selected[0].workgroup : "";
        this.currentWorkgroupName = this.selectedRequests[0].workgroupName != undefined ? this.selectedRequests.selected[0].workgroupName : "";
        this.maxWorkgroupClearanceLevel = this.workgroups.find(s => s.Guid === this.selectedRequests.selected[0].workgroup || s.Guid === this.selectedRequests.selected[0].workgroupGuid) != undefined ? this.workgroups.find(s => s.Guid === this.selectedRequests.selected[0].workgroup || s.Guid === this.selectedRequests.selected[0].workgroupGuid)?.Clearancelevel : 1;
        this.newWorkgroupId = this.currentWorkgroup;
        this.newWorkgroupName = this.currentWorkgroupName;
    })
  }

  getTranslations() {
    this.translateService.get([
      'requestoverview.DialogChangeWorkgroup.SuccessfullMovedToOtherWorkgroup',
      'requestoverview.DialogChangeWorkgroup.SuccessfullMovedMultiToOtherWorkgroup',
      'requestoverview.DialogChangeWorkgroup.SuccessfullRemovedFromWorkgroup',
      'requestoverview.DialogChangeWorkgroup.SuccessfullRemovedMultiFromWorkgroup',
      'requestoverview.DialogChangeWorkgroup.NoWorkgroup',
      'Toasts.AddToWorkgroupError',
      'Toasts.Error'
    ])
    .subscribe(translation => {
      this.translations.successfullMovedToOtherWorkgroup = translation['requestoverview.DialogChangeWorkgroup.SuccessfullMovedToOtherWorkgroup'];
      this.translations.successfullMovedMultiToOtherWorkgroup = translation['requestoverview.DialogChangeWorkgroup.SuccessfullMovedMultiToOtherWorkgroup'];
      this.translations.successfullRemovedFromWorkgroup = translation['requestoverview.DialogChangeWorkgroup.SuccessfullRemovedFromWorkgroup'];
      this.translations.successfullRemovedMultiFromWorkgroup = translation['requestoverview.DialogChangeWorkgroup.SuccessfullRemovedMultiFromWorkgroup'];
      this.translations.NoWorkgroup = translation['requestoverview.DialogChangeWorkgroup.NoWorkgroup'];
      this.translations.AddToWorkgroupError = translation['Toasts.AddToWorkgroupError'];
      this.translations.error = translation['Toasts.Error'];
    });
  }

  counter(min: number, max:number ) {
    let numbers = [];
    for (let i = min; i <= max; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  async saveFileInWorkgroupButton() {
    console.log(this.newWorkgroupId, this.newClearancelevel, this.selectedRequests.selected);
    await this.dossierService.updateWorkgroupForRequests(this.newWorkgroupId, this.newClearancelevel, this.selectedRequests.selected.map((p:any) => p.requestId)).subscribe({
         error: (error: HttpErrorResponse) => {this.toastService.showError("" + this.translations.error + "", "" + this.translations.AddToWorkgroupError + "")},
         next: () => {this.activeModal.close(this.newWorkgroupName)}
    })
  }

  changeCurrentClearancelevel(event: Event) {
    let clearancelevel: any = (event.target as HTMLInputElement).value;
    this.newClearancelevel = clearancelevel;
  }

  changeWorkgroup(event: Event){
    let workgroupId: any = (event.target as HTMLInputElement).value;
    this.newWorkgroupId = workgroupId;
    this.newWorkgroupName = this.workgroups.find(s => s.Guid === this.newWorkgroupId) != undefined ? this.workgroups.find(s => s.Guid === this.newWorkgroupId)?.Name : "";
    this.workgroups.forEach(item => {
      if(item.Guid === workgroupId){
        this.maxWorkgroupClearanceLevel = item.Clearancelevel;
        if(this.currentClearancelevel > this.maxWorkgroupClearanceLevel) {
          this.currentClearancelevel = 1;
        }
      }
    });
    if(!workgroupId){
      this.maxWorkgroupClearanceLevel = 1;
      this.currentClearancelevel = 1;
    }
  }
}
