import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { StringHelper } from "./string-helper";

/** The formcontrol must (when having value) b ea 'Valid' email */
export function VerifyAsEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let helper = new StringHelper();

    // do not count/validate for no input
    if (!control.value) {
      return null;
    }

    const passedValidation = helper.IsEmail(control.value);
    return !passedValidation ? { IsNotEmail: { value: control.value} } : null;
  };
}