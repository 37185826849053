<div class="checklist">
    <div class="d-flex justify-content-between align-items-center list-header cursor-pointer" (click)="setCollapse();">
        <span
            class="text-primary fw-bold ps-4 pe-3 cursor-pointer w-100"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            {{title}}

            <ng-container *ngIf="helpText && helpText.length">
                <app-closeable-info-button labelName="{{helpText}}"></app-closeable-info-button>
            </ng-container>
            
            <i class="bi" [ngClass]="{ 'bi-caret-down-fill': isCollapsed, 'bi-caret-up-fill': !isCollapsed, 'float-end': floatIconRight}"></i>
        </span>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" [horizontal]="false" class="ps-4 pt-0 pe-4 pb-4">

        <!-- Data from other component -->
        <ng-content></ng-content>

    </div>
</div>