import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExistsIn, SingleOrDefault } from 'src/app/_helpers/ArrayExtensionMethodHelper.functions';
import { ActorActionEnum } from 'src/app/constants/actor-action.enum';
import { DocumentActor } from 'src/app/models/actors';
import { EditorActor } from 'src/app/models/requestEditor';
import { BuilderRequest } from 'src/app/models/requests';
;

@Component({
  selector: 'app-dialog-add-action',
  templateUrl: './dialog-add-action.component.html',
  styleUrls: ['./dialog-add-action.component.css']
})
export class DialogAddActionComponent implements OnDestroy {

  @Input() action!: string;
  @Input() actors!: DocumentActor;
  @Input() request!: BuilderRequest;
  @Output() newAction: EventEmitter<any> = new EventEmitter<any>();

  selectedPersonIds: string[] = [];
  selectedPersonHasActions: boolean = false;

  constructor(private activeModal: NgbActiveModal) {
    // Must not be empty. It's approved by a comment.
  }
  ngOnDestroy(): void {
    this.selectedPersonIds = [];
  }

  close() {
    this.activeModal.close();
  }



  setSelectedPerson(event: string) {
    if(this.selectedPersonIds.includes(event)){
      this.selectedPersonIds.splice(this.selectedPersonIds.findIndex(s => s === event), 1);
    } else {
      this.selectedPersonIds.push(event);
    }
  }

  addAction() {
    this.newAction.emit({ personIds: this.selectedPersonIds });
  }
}
