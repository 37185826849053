import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Subscription } from 'rxjs/internal/Subscription';
import { RequestModel as RequestModel, SignalrRequest, DashboardRequest, StatusRequest, BuilderRequest } from '../models/requests';
import { PlatformHub } from '../platform.hub';
import packageInfo from '../../../package.json';
import { LoggingService } from './logging.service';
import { WorkflowService } from './workflow.service';
import { WorkflowListItem } from '../models/workflowlistitem';
import { AuthenticationService } from './authentication.service';
import { RequestItemInDashboard } from '../models/dashboard';
import { TranslateService } from '@ngx-translate/core';
import { StatusEnum } from '../constants/status.enum';
import { ActorSendGroupStatusInformationModel } from '../models/api/actor-status-information.model';
import { EventLogModel } from './api-clients/event-log.api-client';
import { ExistsIn, SingleOrDefault } from '../_helpers/ArrayExtensionMethodHelper.functions';
import { DashboardApiClient } from './api-clients/dashboard.api-client';
import { User } from '../models/user';
import { VerifyRequestActionService } from './verify-request-action.service';

@Injectable({
    providedIn: 'root'
})
export class PlatformHubService implements OnDestroy {

    public version: string = packageInfo.version;
    public user!: User;

    public dashboardRequest$: BehaviorSubject<DashboardRequest> = new BehaviorSubject({} as DashboardRequest);
    public statusRequest$: BehaviorSubject<StatusRequest> = new BehaviorSubject({} as StatusRequest);
    public builderRequest$: BehaviorSubject<BuilderRequest> = new BehaviorSubject({} as BuilderRequest);
    public updatedRequest: Subject<RequestModel> = new Subject<RequestModel>();

    public loadingobserver: Subject<boolean> = new Subject<boolean>();

    private platformHubSubscription: Subscription = new Subscription();
    private removeRequestsHubSubscription: Subscription = new Subscription();
    private updateRequestsHubSubscription: Subscription = new Subscription();

    public requestSource: RequestItemInDashboard[] = [];
    public dashboardRequests$: BehaviorSubject<RequestItemInDashboard[]> = new BehaviorSubject(this.requestSource);
    public workflows: WorkflowListItem[] = [];
    public loading: boolean = true;
    public get RequestsInWorkgroup(): boolean { return !this.requestSource.every(p => p.workgroupName == null || p.workgroupName == ""); };

    constructor(private dashboardApiClient: DashboardApiClient,
        private loggingService: LoggingService,
        private workflowService: WorkflowService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        public platformHub: PlatformHub,
        private verifyRequestActionService: VerifyRequestActionService) {
        this.platformHubSubscription = this.platformHub.pkisEvent.subscribe({
            next: (data: any[]) => {
                try {
                    this.platformHubEventObserver(data);
                } catch (e) {
                    console.error(e);
                }
            }, error: (error: any) => {
                this.loggingService.logException(error);
            }
        });

        this.removeRequestsHubSubscription = this.platformHub.requestGuidToRemoveSubject$.subscribe(requestGuid => {
            let hasRequest = SingleOrDefault(this.requestSource, "requestId", requestGuid);
            if (hasRequest) {
                const index = this.requestSource.indexOf(hasRequest, 0);
                if (index > -1) {
                    this.requestSource.splice(index, 1);
                    this.dashboardRequests$.next(this.requestSource);
                }
            }

        });
        this.updateRequestsHubSubscription = this.platformHub.singularRequestUpdatedSubject$.subscribe(requestDashboardModel => {
            let hasRequest = ExistsIn(this.requestSource, "requestId", requestDashboardModel.requestId);
            this.updateRequests(requestDashboardModel);
            if (hasRequest) {
                let indexToUpdate = this.requestSource.findIndex(existingRequestModel => existingRequestModel.requestId === requestDashboardModel.requestId);
                this.requestSource[indexToUpdate] = requestDashboardModel;
            } else {
                this.requestSource.push(requestDashboardModel);
            }
            // only needed if CHANGEDETECTION does not work:
            // this.requestSource = Object.assign([], this.requestSource);

            this.dashboardRequests$.next(this.requestSource);

        });

        this.user = this.authenticationService.currentUserValue;
    }

    ngOnDestroy() {
        if (this.platformHubSubscription) {
            this.platformHubSubscription.unsubscribe();
        }
        if (this.removeRequestsHubSubscription) {
            this.removeRequestsHubSubscription.unsubscribe();
        }
        if (this.updateRequestsHubSubscription) {
            this.updateRequestsHubSubscription.unsubscribe();
        }


    }

    public async start(openSignalrLink: boolean) {
        await this.loadRequests();
        if (openSignalrLink) this.platformHub.start();
        this.loading = false;
        this.loadingobserver.next(false);
        this.sortRequests();
        this.workflowService.getWorkflowList()
            .then(values => {
                this.workflows = values;
            }).catch(error => {
                this.loggingService.logException(error);
            });
    }

    /**
     * NEVER call this without also calling the STOPListening version
     * @param requestGuid
     * @returns
     */
    public ListenToActorList(requestGuid: string): Observable<ActorSendGroupStatusInformationModel[]> {
        this.platformHub.subscribeToRequestStatusGroup(requestGuid);
        return this.platformHub.actors$;
    }
    public StopListeningToActorList(requestGuid: string) {
        this.platformHub.unsubscribeToRequestStatusGroup(requestGuid);
    }

    /**
     * NEVER call this without also calling the STOPListening version
     * @param requestGuid
     * @returns
     */
    public ListenToEventLog(requestGuid: string): Observable<EventLogModel[]> {
        this.platformHub.subscribeToRequestEventLog(requestGuid);
        return this.platformHub.eventLog$;
    }
    public StopListeningToEventLog(requestGuid: string) {
        this.platformHub.unsubscribeToRequestEventLog(requestGuid);
    }


    public async loadRequests() {
        let showAllDocsInOrg = sessionStorage.getItem("ShowAllDocsInOrg");
        let requests: RequestItemInDashboard[] = [];
        if (showAllDocsInOrg == undefined || showAllDocsInOrg == null || showAllDocsInOrg == "false") {
            requests = await this.dashboardApiClient.getDashboard();
        } else {
            requests = await this.dashboardApiClient.getRequestsFromOrg();
        }
        requests = requests.filter(x => x != null);
        for (let request of requests) {
            request.workgroupName = request.workgroupName ? request.workgroupName : request.workgroupGuid ? this.getWorkgroupName(request.workgroupGuid) : "";
            this.updateRequests(request);
        };
        this.requestSource = requests;
        this.dashboardRequests$.next(this.requestSource);
    }

    private sortRequests() {
        this.requestSource.sort(
            function (a, b) { return new Date(b.created).getTime() - new Date(a.created).getTime(); }
        );
        this.dashboardRequests$.next(this.requestSource);
    }

    private platformHubEventObserver(data: any[]) {
        if (data[0] === "error") return;
        let request: RequestItemInDashboard = <RequestItemInDashboard>{};
        let signalrData: SignalrRequest = data[1];
        let updateRequest: RequestItemInDashboard | undefined = this.requestSource.find(d => d.requestId == signalrData.id);
        if (updateRequest === undefined) updateRequest = this.requestSource.find(d => d.requestId == signalrData.guid);
        if (data[0] !== "noFurtherActionRequired" && data[0] !== "deleted" && data[0] !== "DossierUpdated" && data[0] !== "ReportProgress") {
            request = this.tranformSignalrToDashboard(request, signalrData);
        } else {
            request.requestId = signalrData.id;
        }
        switch (data[0]) {
            case "DossierUpdated":
                this.builderRequest$.next(signalrData);
                this.statusRequest$.next(signalrData);
                break;
        }
    }

    private getWorkgroupName(workgroupId: string): string {
        let workgroups = this.authenticationService.workgroups;
        let workgroup = workgroups.find(s => s.Guid === workgroupId)
        return workgroup?.Name ?? "";
    }

    private updateRequests(request: RequestItemInDashboard): RequestItemInDashboard {
        request._translatedType = this.translateRequestData("type", "" + request.dossierType + "", request.workflowId, request.requestId);

        request.isOwner = this.verifyRequestActionService.isOwner(request.ownerGuid);
        request.inWorkgroup = this.verifyRequestActionService.isInWorkgroup(request.workgroupGuid);
        let currentUserIsOwnerOrInWorkgroup = request.isOwner || request.inWorkgroup;

        if (request.workgroupGuid) {
            request.workgroupName = this.getWorkgroupName(request.workgroupGuid);
        }

        if (!currentUserIsOwnerOrInWorkgroup && request.actionRequired && request.status !== StatusEnum.Completed) {
            request._translatedPreviousAction = this.translateService.instant("dashboard.activitylist.actions.Sent");
        }
        if (!currentUserIsOwnerOrInWorkgroup && request.actionRequired && request.status !== StatusEnum.Completed) {
            request._translatedPreviousAction = this.translateService.instant("dashboard.activitylist.actions.Completed");
        }
        if (request.previousAction) {
            request._translatedPreviousAction = this.translateService.instant("dashboard.activitylist.actions." + request.previousAction);
        }

        this.user = this.authenticationService.currentUserValue;
        if (this.user && this.user.email && this.user.phoneNumber) {
            request.actionRequired = request.personsWithActiveActions.some(x => x.email.toLowerCase() == this.user?.email.toLowerCase() && x.mobile.toLowerCase() == this.user?.phoneNumber.toLowerCase());
        }

        return request;
    }

    private translateRequestData(item: string, value: string, workflowId: any, requestId: any) {
        let translatedValue;
        if (item === "type") {
            if ((workflowId == undefined || workflowId == null) && (requestId == undefined || requestId == null) && this.requestSource.findIndex(s => s.requestId === requestId) > -1) {
                workflowId = this.requestSource.filter(s => s.requestId === requestId)[0].workflowId;
            }
            if (workflowId == undefined || workflowId == null) {
                translatedValue = this.translateService.instant("Type." + value)
            } else {
                translatedValue = this.translateService.instant("WorkFlowRequests.ByWorkflowId." + workflowId)
            }
        } else {
            // TODO: link this to the ActionToTranslateKeyPipe zodat deze gelijk lopen
            translatedValue = value.toLowerCase() !== "unknown" && value.toLowerCase() !== "undefined" ? this.translateService.instant("dashboard.activitylist.actions." + value) : null;
        }
        return translatedValue;
    }


    private tranformSignalrToDashboard(request: RequestItemInDashboard, signalrData: SignalrRequest): RequestItemInDashboard {
        request.requestId = signalrData.id;
        request.requestName = signalrData.jobName;
        request.status = signalrData.status;
        request.dossierType = signalrData.dossierType;
        request.created = signalrData.created;
        request.stage = signalrData.stage;
        // first set NORMAL deadline
        if (signalrData.deadline != undefined) request.deadline = signalrData.deadline;
        // than if present, overwrite with EXPIRE
        if (signalrData.expireDeadline != undefined) request.deadline = signalrData.expireDeadline;
        // than if present, overwrite with DELETE
        if (signalrData.deleteDeadline != undefined) request.deadline = signalrData.deleteDeadline;
        request.lastUpdate = signalrData.lastUpdate;
        request.ownerGuid = signalrData.documentOwner.userId;
        request.ownerName = signalrData.ownerFullName;
        request.workgroupGuid = signalrData.workgroup;
        request.clearancelevel = signalrData.clearancelevel;
        if (signalrData.workflowId != undefined && signalrData.workflowId != null) request.workflowId = signalrData.workflowId;
        if (signalrData.statusItem != null && signalrData.statusItem.length > 0) {
            if (request.deadline == undefined) {
                request.deadline = signalrData.statusItem[0].deadline;
            }
        }
        request.previousAction = signalrData.previousAction!;
        request.previousActor = signalrData.previousActor!;
        request._translatedType = this.translateRequestData("type", "" + signalrData.dossierType + "", signalrData.workflowId, signalrData.id);
        request._translatedPreviousAction = this.translateRequestData("status", "" + request.previousAction + "", null, null);
        request.progress = signalrData.progress;

        this.updateRequests(request);
        return request;
    }
}
