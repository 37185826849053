<div class="container-fuid bg-white default-boxrounded">
    <div class="col-12 w-100">
        <h3 class="title col-9">
            <span translate="Modals.SendgroupEditName.Title"></span>
        </h3>
    </div>
    <div class="p-4 pt-0">
        <form class="row needs-validation has-validation" [formGroup]="form" (ngSubmit)="save()">
            <div class="mb-3">
                <label for="name" class="form-label" translate="Modals.SendgroupEditName.SendGroupName"></label>
                <input type="text" id="name" formControlName="name" class="form-control" [value]="sendgroupToUpdate.sendGroupName">
                <app-formcontrol-error-messages [forControl]="nameValue"></app-formcontrol-error-messages>
            </div>
            <div class="col-12 mt-4 pb-2 d-table w-100">
                <span class="float-start"><button type="button" class="btn btn-sm btn-outline-primary" (click)="closeModal()" translate>Button.Back</button></span>
                <span class="float-end"><button type="submit" class="btn btn-sm btn-primary" translate>Button.Confirm</button></span>
            </div>
        </form>
    </div>
</div>