import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Organisation } from '../../models/organisation';
import { Observable } from 'rxjs';
import { StandardIdModel } from 'src/app/models/api/standard-id.model';
import { UpdateOrganisationModel } from 'src/app/models/api/update-organisation.model';

@Injectable({
  providedIn: 'root'
})

export class IdentityOrganisationApiClient {

  constructor(private http: HttpClient) { }

  getOrganisation(organisation: string): Observable<Organisation> {
    return this.http.get<Organisation>(environment.identityUrl + "/api/organisations/" + organisation);
  }
  /**
   * Request the KVK property of an Organisation
   * (Needs Admin if requesting not users KVK)
   * @param organisation OrganisationGuid to request
   * @returns NULL or the String KVK
   */
  getOrganisationKvk(organisationGuid: string): Observable<string> {
    return this.http.get<string>(environment.identityUrl + "/api/organisations/" + organisationGuid + "/kvk");
  }

  async addOrganisation(organisation: Organisation) {
    return this.http.post(environment.identityUrl + "/api/organisations", organisation);
  }

  async deleteOrganisation(organisationGuid: string) {
    return this.http.delete(environment.identityUrl + "/api/organisations/" + organisationGuid);
  }

  async updateOrganisation(organisationGuid: string, organisation: Organisation) {
    return this.http.put(environment.identityUrl + "/api/organisations/" + organisationGuid, organisation);
  }
  /**
   * Updates an Organisation by update model, not by Entity.
   * 
   * IMPORTANT: this leads to a HIGHER Admin endpoint, NOT FOR ORGANISATION ADMINS
   * @param updateModel the Information that you can update 
   * @returns 
   */
  updateOrganisationByModel(updateModel: UpdateOrganisationModel) {
    return this.http.put(environment.identityUrl + "/api/organisations/" + updateModel.organisationGuid + "/update", updateModel);
  }

  getSigningsData(organisationGuid: string): Observable<any[]> {
    return this.http.get<any[]>(environment.identityUrl + "/api/organisations/" + organisationGuid + "/getSigningsByWeeknr");
  }

  getWhitelabelProviders(organisationGuid: string): Observable<StandardIdModel[]> {
    return this.http.get<StandardIdModel[]>(environment.identityUrl + "/api/organisations/" + organisationGuid + "/GetWhitelabelproviders");
  }
  // todo: future no async
  private async getSetting(organisationGuid: string, settingKey: "azureblobstorage" | "twofactorrequired" | "ssorequired" | "ssoprovider" | "identificationmethod" | "ViduaAuthentication" | "ViduaIdentification" ) {
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/applicationsettings/" + settingKey);
  }
  async getIdentificationmethod(organisationGuid: string) {
    return this.getSetting(organisationGuid, "identificationmethod");
  }
  async getSsoprovider(organisationGuid: string) {
    return this.getSetting(organisationGuid, "ssoprovider");
  }
  getSsorequired(organisationGuid: string) {
    // future change, but didnt want to remove all the above Async
    return this.http.get(environment.identityUrl + "/api/organisations/" + organisationGuid + "/applicationsettings/ssorequired");
    // return this.getSetting(organisationGuid, "ssorequired");
  }
  async getTwoFactorRequired(organisationGuid: string) {
    return this.getSetting(organisationGuid, "twofactorrequired");
  }
  async getAzureblobstorage(organisationGuid: string) {
    return this.getSetting(organisationGuid, "azureblobstorage");
  }
  async getViduaAuthentication(organisationGuid: string) {
    return this.getSetting(organisationGuid, "ViduaAuthentication");
  }
  async getViduaIdentification(organisationGuid: string) {
    return this.getSetting(organisationGuid, "ViduaIdentification");
  }

  private async postSetting(organisationGuid: string, body: any, settingKey: "azureblobstorage" | "twofactorrequired" | "ssorequired" | "ssoprovider" | "identificationmethod" | "ViduaAuthentication" | "ViduaIdentification") {
    return this.http.post(environment.identityUrl + "/api/organisations/" + organisationGuid + "/applicationsettings/" + settingKey, body);
  }
  async postIdentificationmethod(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "identificationmethod");
  }
  async postSsoprovider(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "ssoprovider");
  }
  async postSsorequired(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "ssorequired");
  }
  async postTwoFactorRequired(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "twofactorrequired");
  }
  async postAzureblobstorage(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "azureblobstorage");
  }
  async postViduaAuthentication(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "ViduaAuthentication");
  }
  async postViduaIdentification(organisationGuid: string, body: any) {
    return this.postSetting(organisationGuid, body, "ViduaIdentification");
  }
  
}
