import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { StringHelper } from "./string-helper";

/** The formcontrol must (when having value) b ea 'Valid' phonenumber */
export function VerifyAsMobileValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let helper = new StringHelper();

    // do not count/validate for no input
    if (!control.value) {
      return null;
    }

    const passedValidation = helper.IsAPhoneNumber(control.value);
    return !passedValidation ? { IsNotPhonenumber: { value: control.value } } : null;
  };
}