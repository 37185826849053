<span class="badge pillbadge-status rounded-pill {{badgeStatusStyleClass}} fw-bold">
    <!-- Pill badge data -->
    <span *ngIf="deleteAll">{{filterValue}}</span>
    <span *ngIf="filterType === 'filterStatus'" translate="Status.{{filterValue}}"></span>
    <span *ngIf="filterType === 'workgroupName' || filterType === 'deadline'" translate="{{filterValue}}"></span>
    <span *ngIf="filterType === 'dossierType'" translate="Type.{{filterValue}}"></span>
    <!-- <span *ngIf="filterType === 'deadline'" translate="{{filterValue}}"></span> -->
    <!-- Generic icon, based on Bootstrap Icons -->
    <icon *ngIf="deleteAll" class="ms-1" icon="x-lg" (click)="$event.stopPropagation(); deleteAllSelectedFilters()"></icon>
    <icon *ngIf="!deleteAll" class="ms-1" icon="x-lg" (click)="$event.stopPropagation(); deleteSelectedFilter()"></icon>
</span>