import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UpdateSendGroupModel } from 'src/app/models/sendgroup.model';
import { CreateSendGroupModel } from 'src/app/models/api/create-sendgroup.model';
import { SendGroupResendInviteModel } from 'src/app/models/api/actor-resend-invite.model';

@Injectable({
    providedIn: 'root'
})

export class SendgroupApiClient {

    private ControllerEndpoint(requestGuid: string) {
        return "/fe-api/sendgroup/" + requestGuid;
    }

    constructor(private httpClient: HttpClient) { }

    createSendgroup(requestGuid: string, order: number, ForPersonGuids: string[]) {
        let model: CreateSendGroupModel = {
            Order: order,
            ForPersonGuids: ForPersonGuids
        };
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/create", model);
    }

    mergeSendgroups(requestGuid: string) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/mergeSendgroups", {});
    }

    updateSendgroup(requestGuid: string, updateSendGroupModel: UpdateSendGroupModel) {
        return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(requestGuid) + "/update", updateSendGroupModel);
    }
    resendSendGroupInvites(forRequestGuid: string, forSendGroupId: string) {
      var body: SendGroupResendInviteModel = {
        requestGuid: forRequestGuid,
        sendGroupGuid: forSendGroupId
      };
      // THIS IS NOT THE GENERIC ACTORS endpoint:
      return this.httpClient.post(environment.apiUrl + this.ControllerEndpoint(forRequestGuid) + "/resend-invite", body);
    }

}


