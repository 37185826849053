import { Component, Input } from '@angular/core';
import { PillBadgeStatusEnum } from '../../constants/badge-status.enum';
import { DashboardDataService } from '../dashboard/dashboard-data.service';

@Component({
  selector: 'pillbadge-filter-display',
  templateUrl: './pillbadge-filter-display.component.html',
  styleUrl: './pillbadge-filter-display.component.scss'
})
export class PillBadgeFilterDisplayComponent {

  @Input() status: PillBadgeStatusEnum | string | null = PillBadgeStatusEnum.Info;
  @Input() iconTag: string = "";
  @Input() filterType!: string;
  @Input() filterValue!: string;
  @Input() deleteAll: boolean = false;


  private InfoStyle = "info";
  private SecondaryStyle = "secondary";
  private SuccessStyle = "success";
  private WarningStyle = "warning";
  private DangerStyle = "danger";

  get badgeStatusStyleClass(): string {

    let mainOrbStyle = "text-bg-";
    switch (this.status) {
      case PillBadgeStatusEnum.Info:
        return mainOrbStyle + this.InfoStyle;

      case PillBadgeStatusEnum.Secondary:
        return mainOrbStyle + this.SecondaryStyle

      case PillBadgeStatusEnum.Success:
        return mainOrbStyle + this.SuccessStyle

      case PillBadgeStatusEnum.Warning:
        return mainOrbStyle + this.WarningStyle

      case PillBadgeStatusEnum.Danger:
        return mainOrbStyle + this.DangerStyle

      case null:
      case undefined:
      default:
        return mainOrbStyle + this.InfoStyle
    }
  }

  constructor(private dashboardDataService: DashboardDataService) {
   // comment 
  }
  
  deleteAllSelectedFilters(){
    this.dashboardDataService.deleteAllSelectedFilters();
  }

  deleteSelectedFilter() {
    this.dashboardDataService.assignNewFilter({ filterType: this.filterType, filterValue: this.filterValue });
  }

}
