import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { DataTypeEnum } from 'src/app/constants/data-type.enum';
import { RequestItemInDashboard } from 'src/app/models/dashboard';
import { CreateColumn, CreateColumnFilter, TableColumn, TableColumnFilter } from 'src/app/models/tableColumn';
import { PlatformHubService } from 'src/app/services/platformhub.service';
import { DashboardFilterOptionModel } from './models/dashboard-filter-option.model';
import { MatTableDataSource } from '@angular/material/table';
import { RequestStatuses, StatusEnum } from 'src/app/constants/status.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogChangeWorkgroupComponent } from './dialogchangeworkgroup/dialogchangeworkgroup.component';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { RequestApiClient } from 'src/app/services/request.apiclient';
import { OrganisationApiClient } from 'src/app/services/organisation.apiclient';
import { Plugin } from 'src/app/models/plugin';
// import { AppComponent } from '../app/app.component';
import CloudItem from 'src/app/models/clouditem';
import { ModalOpenerService } from 'src/app/modals/modal-opener.service';
import { CloudLocationsService } from 'src/app/services/cloudlocations.service';
import saveAs from 'file-saver';
import { WithdrawRequestModal } from 'src/app/modals/withdraw-request/withdraw-request.modal';
import { SelectionModel } from '@angular/cdk/collections';
import { LoggingService } from 'src/app/services/logging.service';
import { DossierService } from 'src/app/services/dossier.service';
import { ActorApiClient } from 'src/app/services/api-clients/actor.api-client';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SortDirection } from '@angular/material/sort';
import { request } from 'http';
import { FirstOrDefault } from 'src/app/_helpers/ArrayExtensionMethodHelper.functions';

@Injectable({ providedIn: 'root' })
export class DashboardDataService implements OnDestroy {
    translations = <any>{};
    public columns: TableColumn[] = [
        CreateColumn<RequestItemInDashboard>('lastUpdate', 'Updated', DataTypeEnum.DateTime, 1, false, true),
        CreateColumn<RequestItemInDashboard>("requestName", 'Name', DataTypeEnum.General, 3, true, true),
        CreateColumn<RequestItemInDashboard>("status", 'Status', DataTypeEnum.Status, 1, false, true),
        CreateColumn<RequestItemInDashboard>("dossierType", 'Type', DataTypeEnum.RequestType, 2, false, true),
        CreateColumn<RequestItemInDashboard>("workgroupName", 'Workgroup', DataTypeEnum.General, 2, false, true),
        CreateColumn<RequestItemInDashboard>("stage", 'Stage', DataTypeEnum.Stage, 2, false, true),
        CreateColumn<RequestItemInDashboard>("deadline", 'Deadline', DataTypeEnum.DateTime, 1, true, true),
    ];
    public columnsToShow: TableColumnFilter[] = [
        CreateColumnFilter('Updated', true, false),
        CreateColumnFilter("Name", true, true),
        CreateColumnFilter("Status", true, false),
        CreateColumnFilter("Type", true, false),
        CreateColumnFilter("Workgroup", true, false),
        CreateColumnFilter("Stage", true, false),
        CreateColumnFilter("Deadline", true, false)
    ];

    public allRequestSource: RequestItemInDashboard[] = [];
    public allRequestSource$: BehaviorSubject<RequestItemInDashboard[]> = new BehaviorSubject(this.allRequestSource);

    private dashboard: RequestItemInDashboard[] = [];
    private requestSource!: MatTableDataSource<RequestItemInDashboard>;
    public dashboardRequests$: BehaviorSubject<MatTableDataSource<RequestItemInDashboard>> = new BehaviorSubject(this.requestSource);

    public filteredRequestSource: RequestItemInDashboard[] = [];
    public filteredRequestSource$: BehaviorSubject<RequestItemInDashboard[]> = new BehaviorSubject(this.filteredRequestSource);
    public columnsToShow$: BehaviorSubject<TableColumnFilter[]> = new BehaviorSubject(this.columnsToShow);
    public showedColumns$: BehaviorSubject<TableColumn[]> = new BehaviorSubject(this.columns);
    public selectedRequests: SelectionModel<RequestItemInDashboard> = new SelectionModel<RequestItemInDashboard>(true, []);;
    public selectedRequests$: BehaviorSubject<SelectionModel<RequestItemInDashboard>> = new BehaviorSubject(this.selectedRequests);
    public clearSelection$: BehaviorSubject<null> = new BehaviorSubject(null);
    private dashboardDataSubscription: Subscription;
    private showPurgedFiles: boolean = false;

    anyWorkgroup: boolean = false;
    workgroup: boolean = true;
    plugins: Plugin[] = [];
    private orderBy: SortDirection = "desc";
    private columnName: string = "lastUpdate";

    // private selectedFilters: DashboardFilterOptionModel[] = [];
    private selectedFilters: any = {}
    private currentPossibleFilters: DashboardFilterOptionModel[] = [];
    public currentPossibleFilters$: BehaviorSubject<DashboardFilterOptionModel[]> = new BehaviorSubject(this.currentPossibleFilters);
    private sorting = { active: this.columnName, direction: this.orderBy };
    public sorting$: BehaviorSubject<any> = new BehaviorSubject(this.sorting);
    


    constructor(private platformHubService: PlatformHubService, private modalService: NgbModal, private toastService: ToastService, private translateService: TranslateService, 
                private requestService: RequestApiClient, private organisationApiClient: OrganisationApiClient, private modalOpenerService: ModalOpenerService,
                private cloudLocationsService: CloudLocationsService, private loggingService: LoggingService, private dossierService: DossierService, private actorService: ActorApiClient,
                private localStorageService: LocalStorageService,
    ) {
        this.setColums();
        this.dashboardDataSubscription = this.platformHubService.dashboardRequests$.subscribe((dashboardRequests) => {
            this.dashboard = dashboardRequests;
            this.setCorrectDashboard();
            this.setCorrectFilters();
            this.setCorrectSorting();
            this.applyFilter();
        });

        this.getTranslations();
        translateService.onLangChange.subscribe(() => {
          this.getTranslations();
        });

        // this.organisationApiClient.getEnabledPlugins(this.appComponent?.user.organisationGuid).subscribe({
        //     next: (data: any) => {
        //       this.plugins = data;
        //     }
        //   });
    }
    
    private getTranslations() {
        this.translateService.get([
          'Toasts.NoRequestsSelected',
          'Toasts.RequestsDownloadError',
          'Toasts.RequestsExportError',
          'Toasts.RequestsPurgeError',
          'Toasts.RequestsDeleteError',
          'Toasts.RequestsResendOne',
          'Toasts.RequestsResendMultiple',
          'Toasts.RequestsResendError',
          'Toasts.WithdrawError',
          'Toasts.MultiNotAllowedWithdrawError',
          'Toasts.Error',
          'Toasts.Sent',
          'Toasts.Changed',
          'Toasts.WorkgroupRemoved',
          'Toasts.Purged',
          'Toasts.Deleted',
          'Toasts.RequestPurged',
          'Toasts.RequestDeleted',
          'Toasts.RequestOwnerChanged',
          'Toasts.DownloadTitle',
          'Toasts.DownloadBody',
          'Toasts.ExportTitle',
          'Toasts.ExportBody',
          'Toasts.RequestRestored'
        ]).subscribe(translation => {
          this.translations.toastsRequestsDownloadError = translation['Toasts.RequestsDownloadError'];
          this.translations.toastsRequestsExportError = translation['Toasts.RequestsExportError'];
          this.translations.toastsRequestsPurgeError = translation['Toasts.RequestsPurgeError'];
          this.translations.toastsRequestsDeleteError = translation['Toasts.RequestsDeleteError'];
          this.translations.toastsRequestsResendOne = translation['Toasts.RequestsResendOne'];
          this.translations.toastsRequestsResendMultiple = translation['Toasts.RequestsResendMultiple'];
          this.translations.toastsRequestsResendError = translation['Toasts.RequestsResendError'];
          this.translations.toastsWithdrawError = translation['Toasts.WithdrawError'];
          this.translations.notAllowedWithdrawError = translation['Toasts.MultiNotAllowedWithdrawError'];
          this.translations.success = translation['Toasts.Success'];
          this.translations.error = translation['Toasts.Error'];
          this.translations.noRequestsSelected = translation['Toasts.NoRequestsSelected'];
          this.translations.toastsSent = translation['Toasts.Sent'];
          this.translations.toastsChanged = translation['Toasts.Changed'];
          this.translations.toastsWorkgroupRemoved = translation['Toasts.WorkgroupRemoved'];
          this.translations.toastsPurged = translation['Toasts.Purged'];
          this.translations.toastsDeleted = translation['Toasts.Deleted'];
          this.translations.toastsRequestPurged = translation['Toasts.RequestPurged'];
          this.translations.toastsRequestDeleted = translation['Toasts.RequestDeleted'];
          this.translations.toastsRequestOwnerChanged = translation['Toasts.RequestOwnerChanged'];
          this.translations.DownloadTitle = translation['Toasts.DownloadTitle'];
          this.translations.DownloadBody = translation['Toasts.DownloadBody'];
          this.translations.ExportTitle = translation['Toasts.ExportTitle'];
          this.translations.ExportBody = translation['Toasts.ExportBody'];
          this.translations.toastsRequestRestored = translation['Toasts.RequestRestored'];
        });
      }


    ngOnDestroy() {
        if (this.dashboardDataSubscription) {
            this.dashboardDataSubscription.unsubscribe();
        }
    }
    
    private setColums(){
      if(this.localStorageService.hasSavedDashboardColumns){
        let columns = JSON.parse(this.localStorageService.savedDashboardColumns || '');
          this.columns.forEach((column) => {
              if(!columns.includes(column.transTag)) {
                  column.visible = false;
              }
          });
          this.columnsToShow.forEach((column) => {
              if(!columns.includes(column.name)) {
                  column.isSelected = false;
              }
          });
      }
      this.showedColumns$.next(this.columns);
      this.columnsToShow$.next(this.columnsToShow);
    }

    public setColumnVisibility(column: TableColumnFilter) {
        let columnToShow = this.columns.find(p => p.transTag === column.name);
        if (columnToShow) {
            columnToShow.visible = column.isSelected;
            this.localStorageService.saveDashboardColumns = JSON.stringify(this.columns.filter((column) => column.visible).map((column) => column.transTag));
            this.showedColumns$.next(this.columns);
        }
    }

    public setcolumsForMobile(showOnlyMobileColumns: boolean) {
        if(showOnlyMobileColumns) {
            this.showedColumns$.next(this.columns.filter((column) => column.showMobile));
        } else {
            this.showedColumns$.next(this.columns);
        }
    }

    public setSelectedRequests(selectedRequests: SelectionModel<RequestItemInDashboard>) {
        this.selectedRequests = selectedRequests;
        console.log(this.selectedRequests)
        this.selectedRequests$.next(this.selectedRequests);
    }

    public async performBulkAction(event: string) {
        if (!this.selectedRequests || this.selectedRequests.selected.length == 0) {
          return;
        }
        switch (event) {
          case "download":
            await this.downloadRequest();
            this.clearSelection$.next(null)
            break;
          case "export":
            await this.exportRequest();
            break;
          case "purge":
            await this.purgeRequest();
            this.clearSelection$.next(null)
            break;
          case "delete":
            await this.deleteRequest();
            this.clearSelection$.next(null)
            break;
          case "withdraw":
            this.openModalForWithdrawnRequests();
            break;
          case "change-workgroup":
            this.changeWorkgroup();
            break;
          case "send-reminder":
            this.resendRequest();
            this.clearSelection$.next(null)
            break;
          case "become-owner":
            await this.changeOwner();
            this.clearSelection$.next(null)
            break;
          case "restore":
            await this.restoreRequest();
            this.clearSelection$.next(null)
            break;
        }
    }

    public enableDashboardWithPurgeFiles(showPurged: boolean) {
        this.showPurgedFiles = showPurged;
        this.setCorrectDashboard();
        this.setCorrectFilters();
        this.applyFilter();
    }

    public deleteAllSelectedFilters() {
      this.selectedFilters = {};
      this.localStorageService.saveDashboardFilters = JSON.stringify(this.selectedFilters);
      this.setCorrectFilters();
      this.applyFilter();
    }

    assignNewFilter(newfilter: any) {
      if (!this.selectedFilters.hasOwnProperty(newfilter.filterType) || this.selectedFilters.hasOwnProperty("requestName")) {
          this.selectedFilters[newfilter.filterType] = [newfilter.filterValue];
      } else {
        this.selectedFilters[newfilter.filterType].includes(newfilter.filterValue)
          ? (this.selectedFilters[newfilter.filterType] = this.selectedFilters[newfilter.filterType].filter(
              (filterValue:any) => filterValue !== newfilter.filterValue
          ))
          : this.selectedFilters[newfilter.filterType].push(newfilter.filterValue);
      }
      this.localStorageService.saveDashboardFilters = JSON.stringify(this.selectedFilters);
      this.setCorrectFilters();
      this.applyFilter();
    }

    private applyFilter(){
        if(!this.showPurgedFiles && this.selectedFilters && this.selectedFilters["filterStatus"] && this.selectedFilters["filterStatus"].includes("Expired")){
            let expiredStatusIndex = this.selectedFilters["filterStatus"].indexOf("Expired");
            this.selectedFilters["filterStatus"].splice(expiredStatusIndex, 1);
        }
        this.requestSource.filter = JSON.stringify(this.selectedFilters);
        this.dashboardRequests$.next(this.requestSource);
    }

    private setCorrectDashboard(){
        this.requestSource = new MatTableDataSource(this.dashboard);
        if(!this.showPurgedFiles){
            this.requestSource = new MatTableDataSource(this.dashboard.filter(p => p.status != StatusEnum.Expired));
        } 
        this.requestSource.filterPredicate = (
            data: RequestItemInDashboard,
            filter: string
          ): boolean => {
            const filterValues = JSON.parse(filter);
            let conditions = true;
            for (let filterKey in filterValues) {
              if (filterKey === 'requestName') {
                let searchValue = filterValues[filterKey][0].toLowerCase().trim();
                conditions =
                  conditions &&
                  (data as any)[filterKey].toLowerCase()
                    .trim()
                    .indexOf(searchValue) !== -1;
              } else if (filterValues[filterKey].length) {
                if(filterKey === "deadline"){
                  filterValues["deadline"].forEach((filterValue: string) => {
                    let time: number = Number(filterValue);
                    conditions =
                      conditions &&
                      new Date((data as any)[filterKey].trim()).getTime() < (new Date().getTime() + (1000 * 60 * 60 * time)) &&
                      new Date((data as any)[filterKey].trim()).getTime() > (new Date().getTime());
                  });
                } else {
                  conditions =
                    conditions &&
                    filterValues[filterKey].includes(
                      (data as any)[filterKey].trim()
                    );
                }
              }
            }

            return conditions;
          };
        this.dashboardRequests$.next(this.requestSource);
    }

    private setCorrectFilters(){
      if(this.localStorageService.hasSavedDashboardFilters){
        this.selectedFilters = JSON.parse(this.localStorageService.savedDashboardFilters || '');
      }
      let currentPossibleFiltersForStatus = this.requestSource.data.map(p => p.filterStatus).filter((value, index, self) => self.indexOf(value) === index).map(p => {
          let filterModel = new DashboardFilterOptionModel();
          filterModel.filterType = "filterStatus";
          filterModel.filterValue = p;
          filterModel.checked = this.selectedFilters.filterStatus?.includes(p) || false;
          return filterModel;
      });
      currentPossibleFiltersForStatus = currentPossibleFiltersForStatus.sort((a, b) => a.filterValue.localeCompare(b.filterValue));
      let currentPossibleFiltersForWorkgroup = this.requestSource.data.map(p => p.workgroupName).filter((value, index, self) => value != null && value != '' &&self.indexOf(value) === index).map(p => {
          let filterModel = new DashboardFilterOptionModel();
          filterModel.filterType = "workgroupName";
          filterModel.filterValue = p!;
          filterModel.checked = this.selectedFilters.workgroupName?.includes(p) || false;
          return filterModel;
      });
      currentPossibleFiltersForWorkgroup = currentPossibleFiltersForWorkgroup.sort((a, b) => a.filterValue.localeCompare(b.filterValue));
      let currentPossibleFiltersForRequestType = this.requestSource.data.map(p => p.dossierType).filter((value, index, self) => self.indexOf(value) === index).map(p => {
          let filterModel = new DashboardFilterOptionModel();
          filterModel.filterType = "dossierType";
          filterModel.filterValue = p!;
          filterModel.checked = this.selectedFilters.dossierType?.includes(p) || false;
          return filterModel;
      });
      currentPossibleFiltersForRequestType = currentPossibleFiltersForRequestType.sort((a, b) => a.filterValue.localeCompare(b.filterValue));
      
      let requestsExpiringWithin24Hours = this.requestSource.data.filter(request =>
          new Date(request.deadline!).getTime() < (new Date().getTime() + (1000 * 60 * 60 * 24)) &&
          new Date(request.deadline!).getTime() > (new Date().getTime())
      ).map(p =>{            
        let filterModel = new DashboardFilterOptionModel();
        filterModel.filterType = "deadline";
        filterModel.filterValue = "24",
        filterModel.checked = this.selectedFilters.deadline?.includes("24") || false;
        return filterModel;
      });
      let requestsExpiringWithin48Hours = this.requestSource.data.filter(request =>
          new Date(request.deadline!).getTime() < (new Date().getTime() + (1000 * 60 * 60 * 48)) &&
          new Date(request.deadline!).getTime() > (new Date().getTime())
      ).map(p =>{              
          let filterModel = new DashboardFilterOptionModel();
          filterModel.filterType = "deadline";
          filterModel.filterValue = "48",
          filterModel.checked = this.selectedFilters.deadline?.includes("48") || false;
          return filterModel;
      });
      let requestsExpiringWithin72Hours = this.requestSource.data.filter(request =>
          new Date(request.deadline!).getTime() < (new Date().getTime() + (1000 * 60 * 60 * 72)) &&
          new Date(request.deadline!).getTime() > (new Date().getTime())
      ).map(p =>{         
          let filterModel = new DashboardFilterOptionModel();
          filterModel.filterType = "deadline";
          filterModel.filterValue = "72",
          filterModel.checked = this.selectedFilters.deadline?.includes("72") || false;
          return filterModel;
      });
      let expireFilters: DashboardFilterOptionModel[] = [];
      if(requestsExpiringWithin24Hours.length > 0) expireFilters.push(FirstOrDefault(requestsExpiringWithin24Hours, 'filterType', 'deadline')!);
      if(requestsExpiringWithin24Hours.length > 0) expireFilters.push(FirstOrDefault(requestsExpiringWithin48Hours, 'filterType', 'deadline')!);
      if(requestsExpiringWithin24Hours.length > 0) expireFilters.push(FirstOrDefault(requestsExpiringWithin72Hours, 'filterType', 'deadline')!);
      this.currentPossibleFilters$.next(currentPossibleFiltersForStatus.concat(currentPossibleFiltersForWorkgroup).concat(currentPossibleFiltersForRequestType).
                                        concat(expireFilters));
    }

    public saveSorting(sorting: any) {
      this.localStorageService.saveDashboardSorting = JSON.stringify(sorting);
      this.columnName = sorting["active"];
      this.orderBy = sorting["direction"];
      this.sorting = { active: this.columnName, direction: this.orderBy };
      this.sorting$.next(this.sorting);
    }

    private setCorrectSorting(){
      if(this.localStorageService.hasSavedDashboardSorting){
        let sort:any = JSON.parse(this.localStorageService.savedDashboardSorting!);
        this.columnName = sort["active"];
        this.orderBy = sort["direction"];
      } 
      this.sorting = { active: this.columnName, direction: this.orderBy };
      this.sorting$.next(this.sorting);
    }

    private changeWorkgroup(): void {
        const modalRef = this.modalService.open(DialogChangeWorkgroupComponent, { centered: true, size: 'sm' });
        modalRef.componentInstance.selectedItems = this.selectedRequests;
        modalRef.closed.subscribe((value: string) => {
          if (value === undefined) return;
    
          this.showChangeWorkgroupToast(value);
    
          this.clearSelection$.next(null)
          this.anyWorkgroup = !this.requestSource.filteredData.every(p => p.workgroupName == null || p.workgroupName == "");
        });
    }
    private showChangeWorkgroupToast(value: string): void {
        const selectedCount = this.selectedRequests.selected.length;
        let toastText = '';
    
        if (value) {
          toastText = selectedCount === 1
            ? this.translateService.instant('Toasts.WorkgroupChanged', { workgroupName: value })
            : this.translateService.instant('Toasts.WorkgroupsChanged', { workgroupName: value, amount: selectedCount });
        } else {
          toastText = selectedCount === 1
            ? this.translations.toastsWorkgroupRemoved
            : this.translateService.instant('Toasts.WorkgroupsRemoved', { amount: selectedCount });
        }
    
        this.toastService.showSuccess(this.translations.toastsChanged, toastText);
      }
    
      private async restoreRequest() {
        const selectedCount = this.selectedRequests.selected.length;
        for (const item of this.selectedRequests.selected) {
          await this.requestService.restoreRequest(item.requestId, item.requestName).subscribe();
        };
        this.showRestoreRequestToast(selectedCount);
      }
    
      private showRestoreRequestToast(selectedCount: number): void {
        let toastText = selectedCount == 1 ? this.translations.toastsRequestRestored : this.translateService.instant('Toasts.RequestsRestored', { amount: selectedCount });
        this.toastService.showSuccess(this.translations.toastsPurged, toastText);
      }
    
      private async downloadRequest() {
        this.toastService.showDefault(this.translations.DownloadTitle, this.translations.DownloadBody);
    
        await this.selectedRequests.selected.forEach((item: any) => {
          (async () => {
            let index = this.requestSource.filteredData.findIndex(d => d === item);
            try {
              let downloadRequest = await this.requestService.downloadRequest(this.requestSource.filteredData[index].requestId)
              let file = await lastValueFrom(downloadRequest);
              if (file && file.body && file.headers) {
                let filename = file.headers.get("Content-Disposition")!.replace("attachment; filename=", "");
                this.saveFile(file.body, filename);
              }
            } catch (error: any) {
              this.toastService.showError(this.translations.error, this.translations.toastsRequestsDownloadError + "");
            }
          })()
        });
      }
    
      private async exportRequest() {
        const modalRef = this.modalOpenerService.Open_CloudDocumentExport(this.plugins);
        modalRef.componentInstance.close.subscribe(async (event: CloudItem) => {
          if (event && event.folderId !== null && event.folderId !== undefined && event.parentId !== null && event.parentId !== undefined) {
            this.toastService.showDefault(this.translations.ExportTitle, this.translations.ExportBody);
            await this.selectedRequests.selected.forEach((item: any) => {
              (async () => {
                let index = this.requestSource.filteredData.findIndex(d => d === item);
                try {
                  this.cloudLocationsService.saveParentExternalContent(event.provider, event.parentId, event.folderId, this.requestSource.filteredData[index].requestId).subscribe({
                    next: () => {
                    },
                    error: () => {
                      this.toastService.showError(this.translations.error, this.translations.toastsRequestsExportError);
                    }
                  })
                } catch (error: any) {
                  this.toastService.showError(this.translations.error, this.translations.toastsRequestsExportError);
                }
              })()
            });
            modalRef.close();
            this.clearSelection$.next(null)
          } else {
            modalRef.close();
            this.clearSelection$.next(null)
          }
        });
    
      }
    
      private async purgeRequest() {
        for (const item of this.selectedRequests.selected) {
          let index = this.requestSource.filteredData.findIndex(d => d === item);
          let previousStatus = this.requestSource.filteredData[index].status;
          this.requestSource.filteredData[index].status = "Expired";
          this.requestService.purgeRequest(item.requestId)
            .then(result => {
              lastValueFrom(result).catch(error => {
                this.loggingService.logException(error);
                this.toastService.showError(this.translations.error, this.translations.toastsRequestsPurgeError + "");
                this.requestSource.filteredData[index].status = previousStatus;
              });
            }).catch(error => {
              this.loggingService.logException(error);
              this.toastService.showError(this.translations.error, this.translations.toastsRequestsPurgeError + "");
              this.requestSource.filteredData[index].status = previousStatus;
            });
        }
        this.showPurgeRequestToast();
      }
    
      private showPurgeRequestToast(): void {
        const selectedCount = this.selectedRequests.selected.length;
        let toastText = selectedCount == 1 ? this.translations.toastsRequestPurged : this.translateService.instant('Toasts.RequestsPurged', { amount: selectedCount });
        this.toastService.showSuccess(this.translations.toastsPurged, toastText);
      }
    
      private async deleteRequest() {
        let countItem = 1;
        for (const item of this.selectedRequests.selected) {
          let itemToRemove = this.requestSource.filteredData.findIndex(d => d.requestId === item.requestId);
          this.requestSource.filteredData.splice(itemToRemove, 1);
          if (countItem == this.selectedRequests.selected.length) {
            this.requestSource.filteredData = [...this.requestSource.filteredData];
          }
          countItem++;
    
          try {
            let deleteRequest = await this.requestService.deleteRequest(item.requestId);
            await lastValueFrom(deleteRequest);
          } catch (error: any) {
            this.loggingService.logException(error);
            this.toastService.showError(this.translations.error, this.translations.toastsRequestsDeleteError + "");
            this.requestSource.filteredData.push(item);
          }
        };
    
        this.showDeleteRequestToast();
      }
    
      private showDeleteRequestToast(): void {
        const selectedCount = this.selectedRequests.selected.length;
        let toastText = selectedCount == 1 ? this.translations.toastsRequestDeleted : this.translateService.instant('Toasts.RequestsDeleted', { amount: selectedCount });
        this.toastService.showSuccess(this.translations.toastsDeleted, toastText);
      }
    
      private resendRequest() {
        let sendReminderAllowedStatussen: string[] = RequestStatuses.ThatCanBeReminded;
    
        this.selectedRequests.selected.forEach((item: any) => {
          this.requestSource.filteredData.findIndex(d => d === item);
    
          if ((item.isOwner || item.inWorkgroup) && sendReminderAllowedStatussen.includes(item.status)) {
            this.actorService.resendRequest(item.requestId, item.requestId) //2nd guid is ignored (23-04) so doesn't matter that it is double
              .then(value => {
                lastValueFrom(value)
                  .then(() => {
                    this.showResendRequestToast(item.nextActor);
                  })
                  .catch(error => {
                    this.toastService.showError(this.translations.error, this.translations.toastsRequestsResendError + "");
                    this.loggingService.logException(error);
                  })
              })
              .catch(error => {
                this.toastService.showError(this.translations.error, this.translations.toastsRequestsResendError + "");
                this.loggingService.logException(error);
              });
          }
        });
      }

      private showResendRequestToast(fullName: string): void {
        let reminderSent = this.translateService.instant('Toasts.ReminderSent', {
          name: fullName
        });
        this.toastService.showSuccess(this.translations.toastsSent, reminderSent);
      }
    
      private saveFile(blobContent: Blob, fileName: string) {
        const blob = new Blob([blobContent], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
      }
    
      updateWorkgroupVisibility(action: boolean) {
        this.workgroup = action;
      }
    
      public openModalForWithdrawnRequests() {
        let withdrawAllowedStatussen: string[] = RequestStatuses.ThatCanBeWithdrawn;
        let selectedRequests = this.selectedRequests?.selected;
    
        // need any selected requests
        if (!selectedRequests || !(selectedRequests?.length > 0)) {
          this.toastService.showWarning(this.translations.error, this.translations.noRequestsSelected);
          return;
        }
    
        // all requests must be allowed for withdrawal
        if (selectedRequests.filter(request => !withdrawAllowedStatussen.includes(request.status)).length > 0) {
          this.toastService.showWarning(this.translations.error, this.translations.notAllowedWithdrawError);
          return;
        }
    
        let requestIds = selectedRequests.map(request => request.requestId);
        const withdrawnRequestModalRef = this.modalOpenerService.Open_WithDrawnRequest(requestIds);
        (withdrawnRequestModalRef.componentInstance as WithdrawRequestModal).itemsWithdrawn.subscribe(() => {
            this.clearSelection$.next(null)
        });
      }
    
      public async changeOwner() {
        for (const activity of this.selectedRequests.selected) {
          let changeOwner = await this.dossierService.ChangeOwner(activity.requestId);
          await lastValueFrom(changeOwner);
        }
    
        const selectedCount = this.selectedRequests.selected.length;
        let toastText = selectedCount == 1 ? this.translations.toastsRequestOwnerChanged : this.translateService.instant('Toasts.RequestsOwnerChanged', { amount: selectedCount });
        this.toastService.showSuccess(this.translations.toastsChanged, toastText);
      }
}