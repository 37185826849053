<div class="container-fuid bg-white default-boxrounded p-3">
  <div class="row">
      <div class="col-12">
          <h2 translate="requestoverview.DialogChangeWorkgroup.Title"></h2>
      </div>
  </div>
  <div class="py-3">
    <p>
      <ngb-alert [dismissible]="false" type="info" class="workgroup-modal-information" translate="requestoverview.DialogChangeWorkgroup.WorkgroupsInformation">
        <strong translate="requestoverview.DialogChangeWorkgroup.Workgroups"></strong><br />
        
      <br /> <br />
      <a [href]="ShareRequestWorkgroupUrl" target="_blank" rel="noopener" translate="requestoverview.DialogChangeWorkgroup.MoreInformation"></a>
      </ngb-alert>
    </p>

    <p translate="requestoverview.DialogChangeWorkgroup.SelectWorkgroup"></p>

    <div>
      <div ngbDropdown class="dropdown" [(ngModel)]="currentWorkgroup" ngDefaultControl name="workgroup" id="workgroup">
        <button class="btn workgroup dropdown-toggle text-truncate" id="dropdownMenu" ngbDropdownToggle>{{ newWorkgroupName ? newWorkgroupName : this.translations.NoWorkgroup }}
            <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
        </button>
    
        <div ngbDropdownMenu aria-labelledby="dropdownMenu">
            <button ngbDropdownItem value="" (click)="changeWorkgroup($event)" translate="requestoverview.DialogChangeWorkgroup.NoWorkgroup"></button>
            <button ngbDropdownItem *ngFor='let workgroup of workgroups' value="{{workgroup.Guid}}" (click)="changeWorkgroup($event)">{{workgroup.Name}}</button>
        </div>
      </div>
      <br />
    
      <div ngbDropdown class="dropdown" [(ngModel)]="currentClearancelevel" ngDefaultControl name="clearancelevel" id="clearancelevel"  *ngIf="maxWorkgroupClearanceLevel != 1">
        <button class="btn workgroup dropdown-toggle text-truncate" type="button" id="dropdownMenu" ngbDropdownToggle>{{ newClearancelevel ? newClearancelevel : currentClearancelevel }}
            <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
        </button>
    
        <div ngbDropdownMenu aria-labelledby="dropdownMenu">
            <button ngbDropdownItem *ngFor='let j of counter(1, maxWorkgroupClearanceLevel); let i = index' value={{j}} (click)="changeCurrentClearancelevel($event)">{{j}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2 mt-3 d-table w-100">
    <span class="float-start"><button type="button" class="btn btn-sm btn-outline-primary" (click)="activeModal.close()" translate="Button.Back"></button></span>
    <span class="float-end"><button type="button" class="btn btn-sm btn-primary" (click)="saveFileInWorkgroupButton()" translate="Button.Apply"></button></span>
  </div>
</div>