import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DocumentForDownloadModel } from '../models/api/document-for-download.model';
import { DocumentForOverviewModel } from '../models/api/document-for-overview.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiClient {

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets a Document list for Download page
   * @param requestGuid 
   * @returns 
   */
  getForDownloadList(requestGuid: string): Observable<DocumentForDownloadModel[]> {
    return this.httpClient.get<DocumentForDownloadModel[]>(environment.apiUrl + "/requests/" + requestGuid + "/documents/downloadList");
  }
  /**
   * Gets a Document list for status screen
   * @param requestGuid 
   * @returns 
   */
  getForOverview(requestGuid: string): Observable<DocumentForOverviewModel[]> {
    return this.httpClient.get<DocumentForOverviewModel[]>(environment.apiUrl + "/requests/" + requestGuid + "/documents/overview");
  }

  async createDocument(dossierId: string, formData: FormData) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/documents", formData, {
      reportProgress: true,
      observe: "events"
    });
  }

  async updateDocument(dossierId: string, documentId: string, name: string) {
    return this.httpClient.put(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId, { "Name": name });
  }

  async updateDocumentPlaceholderId(dossierId: string, documentId: string, placeholderId: number) {
    return this.httpClient.put(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId, { "WorkflowPlaceholderId": placeholderId.toString() });
  }

  async getDocument(dossierId: string, documentId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId);
  }

  async getHtmlDetails(dossierId: string, documentId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId + "/htmldata");
  }

  async getXBRLDetails(dossierId: string, documentId: string) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId + "/XBRLDetails");
  }

  async getRenderedHtml(dossierId: string, documentId: string) {
    const headers: HttpHeaders = new HttpHeaders({ 'Accept': 'text/html' });
    return this.httpClient.get(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/RenderedHtml", { headers: headers, responseType: 'text' });
  }
  async deleteDocument(dossierId: string, documentId: string) {
    return this.httpClient.delete(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId);
  }

  sign(dossierId: string, documentId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/Sign", {});
  }

  signDone(dossierId: string, documentId: string, signDoneSession: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/SignDone", signDoneSession, httpOptions);
  }

  approve(dossierId: string, documentId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/Approve", {});
  }

  async downloadDocument(dossierId: string, documentId: string) {
    return this.httpClient.get(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/Download", { observe: 'response', responseType: 'blob' });
  }

  async downloadHtml(dossierId: string, documentId: string) {
    return this.httpClient.get(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/Downloadhtml", { observe: 'response', responseType: 'blob' });
  }


}
