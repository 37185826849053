import { Injectable } from "@angular/core";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: 'root'
})
/**
 * Services that stores stuff in Local Storage, with easy USUABLE functions
 */
export class LocalStorageService {

  /** Set the signingImage */
  public get signingImage(): string | null {
    return this._signingImage;
  };

  /** Holds the dashboardcolumns */
  public set signingImage(value: string | null) {
    this._signingImage = value;
    if (!value) localStorage.removeItem("SigningImage");
    else localStorage.setItem("SigningImage", this.signingImage!);
  };

  public get hasSigningImage() {
    return this._signingImage != null;
  }

  /** Set the dashboard columns */
  public get savedDashboardColumns(): string | null {
    return this._savedDashboardColumns;
  };

  /** Holds the dashboard columns */
  public set saveDashboardColumns(value: string | null) {
    this._savedDashboardColumns = value;
    if (!value) localStorage.removeItem("DashboardColumns");
    else localStorage.setItem("DashboardColumns", this.savedDashboardColumns!);
  };

  public get hasSavedDashboardColumns() {
    return this._savedDashboardColumns != null;
  }

  /** Set the dashboard filters */
  public get savedDashboardFilters(): string | null {
    return this._savedDashboardFilters;
  };

  /** Holds the dashboard filters */
  public set saveDashboardFilters(value: string | null) {
    this._savedDashboardFilters = value;
    if (!value) localStorage.removeItem("DashboardFilters");
    else localStorage.setItem("DashboardFilters", this.savedDashboardFilters!);
  };

  public get hasSavedDashboardFilters() {
    return this._savedDashboardFilters != null;
  }

  /** Set the dashboard sorting */
  public get savedDashboardSorting(): string | null {
    return this._savedDashboardSorting;
  };

  /** Holds the dashboard sorting */
  public set saveDashboardSorting(value: string | null) {
    this._savedDashboardSorting = value;
    if (!value) localStorage.removeItem("DashboardSorting");
    else localStorage.setItem("DashboardSorting", this.savedDashboardSorting!);
  };

  public get hasSavedDashboardSorting() {
    return this._savedDashboardSorting != null;
  }

  /** Set a Version number
   * This verifies it with the currently stored, and if CHANGED shows the new version popup
  */
  public verifyStoredVersionNumber(versionNumber: string, urlForPatchNotes :string) {
    this._storedVersionNumber = versionNumber;
    let previousStoredVersionNumber = localStorage.getItem("StoredVersionNumber");
    if (previousStoredVersionNumber != versionNumber) {
      //We only show a updateMessage when an update is done a week after the previous update
      if(this.toastService.ShouldShowNewVersionMessage(previousStoredVersionNumber ?? versionNumber, versionNumber)){
        this.toastService.DisplayNewVersion(versionNumber,urlForPatchNotes);
      }
      localStorage.setItem("StoredVersionNumber", this._storedVersionNumber!);
    }
  };


  /** Holds the closable wrapper */
  private _closableWrapperCollapsed: string | null | undefined;

  public set closableWrapperCollapsed(value: string | null) {
    this._closableWrapperCollapsed = value;
    if (!value) localStorage.removeItem("closableWrapperCollapsed");
    else localStorage.setItem("closableWrapperCollapsed", this.closableWrapperCollapsed!);
  };

  public get hasClosableWrapperCollapsed() {
    return this._closableWrapperCollapsed != null;
  }


  private _signingImage: string | null = null;
  private _savedDashboardColumns: string | null = null;
  private _savedDashboardFilters: string | null = null;
  private _savedDashboardSorting: string | null = null;
  private _storedVersionNumber: string | null = null;

  constructor(private toastService: ToastService) {
    // get initial signingImage
    let signingImage = localStorage.getItem("SigningImage");
    if (signingImage) {
      this._signingImage = signingImage!;
    }

    // get initial dashboard columns
    let dashboardColumns = localStorage.getItem("DashboardColumns");
    if (dashboardColumns) {
      this._savedDashboardColumns = dashboardColumns!;
    }  

    // get initial dashboard filters
    let dashboardFilters = localStorage.getItem("DashboardFilters");
    if (dashboardFilters) {
      this._savedDashboardFilters = dashboardFilters!;
    }  

    // get initial dashboard sorting
    let dashboardSorting = localStorage.getItem("DashboardSorting");
    if (dashboardSorting) {
      this._savedDashboardSorting = dashboardSorting!;
    }  

    // get Version number of previous time
    let storedVersionNumber = localStorage.getItem("StoredVersionNumber");
    if (storedVersionNumber) {
      this._storedVersionNumber = storedVersionNumber!;
    }
  }

}