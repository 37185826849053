import { Injectable, OnDestroy } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, lastValueFrom, Observable, Subscription } from "rxjs";
import { ActorActionEnum, Is_ANY_Download_Action, Is_NON_Download_Action, Is_Sign_Action } from "src/app/constants/actor-action.enum";
import { DocumentTypeEnum } from "src/app/constants/document-type.enum";
import { DocumentActor } from "src/app/models/actors";
import { DocumentModel, PreloadedPages, PreloadedPagesByDocument } from "src/app/models/document.model";
import { EditorActor } from "src/app/models/requestEditor";
import { BuilderRequest, ValidationSummaryClass } from "src/app/models/requests";
import { Workflow, WorkflowStep1Placeholder, WorkflowStep3Placeholder } from "src/app/models/workflow";
import { ActorApiClient } from "src/app/services/api-clients/actor.api-client";
import { WorkflowService } from "src/app/services/workflow.service";
import { EmailPreviewDialogComponent } from "./email-preview-dialog/email-preview-dialog.component";
import { LoggingService } from "src/app/services/logging.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PdfPreparationDialog } from "./pdf-preparation-dialog/pdf-preparation-dialog.component";
import { PageService } from "src/app/services/pages.service";
import { PlatformHubService } from "src/app/services/platformhub.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IdentityOrganisationApiClient } from "src/app/services/api-clients/identity-organisation.api-client";
import { User } from "src/app/models/user";
import { SendGroupModel, UpdateSendGroupModel } from "src/app/models/sendgroup.model";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "src/app/services/toast.service";
import { DocumentApiClient } from "src/app/services/document.apiclient";
import { RequestApiClient } from "src/app/services/request.apiclient";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { SendgroupApiClient } from "src/app/services/api-clients/sendgroup.api-client";
import { BaseActorCreateClass } from "src/app/models/api/actor-create.model";
import { DialogAddActionComponent } from "./dialog-add-action/dialog-add-action.component";

@Injectable({ providedIn: 'root' })
export class RequestBuilderService implements OnDestroy {
  private _request!: BuilderRequest;
  private _documents!: DocumentModel[];
  private _actors!: DocumentActor[];
  private _validationSummary: ValidationSummaryClass = new ValidationSummaryClass();
  private _persons!: DocumentActor[];
  private _sendgroups!: SendGroupModel[];
  private _requestGuid!: string;
  private _progress: number[] = [0, 0, 0];
  private _preloadedPagesByDocument: PreloadedPagesByDocument[] = [];

  public request$: BehaviorSubject<BuilderRequest> = new BehaviorSubject<BuilderRequest>({} as BuilderRequest);
  public documents$: BehaviorSubject<DocumentModel[]> = new BehaviorSubject<DocumentModel[]>([] as DocumentModel[]);
  public persons$: BehaviorSubject<DocumentActor[]> = new BehaviorSubject<DocumentActor[]>([] as DocumentActor[]);
  public validationSummary$: BehaviorSubject<ValidationSummaryClass> = new BehaviorSubject<ValidationSummaryClass>(this._validationSummary);
  public sendgroups$: BehaviorSubject<SendGroupModel[]> = new BehaviorSubject<SendGroupModel[]>([] as SendGroupModel[]);
  public actors$: BehaviorSubject<DocumentActor[]> = new BehaviorSubject<DocumentActor[]>([] as DocumentActor[]);
  public workflowSteps$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([] as string[]);
  public workflowData$: BehaviorSubject<Workflow> = new BehaviorSubject<Workflow>({} as Workflow);
  public progress$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([] as number[]);
  public isInFirstSendgroup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public requestGuid$: BehaviorSubject<string> = new BehaviorSubject<string>({} as string);
  private requestSignalRSubscription: Subscription | null = null;
  private usersubscription: Subscription | null = null;

  private downloadDeadlineDays = 30;
  private workflowId: string = "";

  public tempNewBulkActions: BaseActorCreateClass[] = [];


  workflowData?: Workflow;
  signYourself: boolean = false;
  secureFileSending: boolean = false;
  placeholdersStep3: boolean = false;
  sent: boolean = false;
  currentTab: string = "AddDocuments";
  isMobile: boolean = false;
  notSupported: boolean = false;
  pageId: number = 1;
  defaultPreloadPages: number = 5;

  /*
    This window that is openend when creating a request whree you are the first actor
    Needed as variable since the opening of the window needs special care.
  */
  windowToOpenForIfFirstActor: Window | null = null;

  public signYourselfWorkflow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentTab$: BehaviorSubject<string> = new BehaviorSubject(this.currentTab);



  /**
   * the ALLOWED identity providers linked to the initialized Workflow
   * Can be Empty, which means all NORMAL Identity providers should be used
   */
  onlyAllowedIdentityProviders: string[] = [];

  private builderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  builderData$: Observable<number> = this.builderSubject.asObservable();
  DEFAULT_MAX_DEADLINE_DATE = new Date("9999-12-31");
  modalRef!: any;

  deadlineDays!: number;
  deadline!: Date;
  workflowSteps: string[] = [];
  organisationHasKVK: boolean = false;
  user!: User;
  translations: any = <any>{};
  countDocuments: number = 0;
  countPersons: number = 0;

  constructor(private translateService: TranslateService, private toastService: ToastService, private workflowService: WorkflowService, private actorApiClient: ActorApiClient, private modalService: NgbModal, private loggingService: LoggingService, private sanitizer: DomSanitizer,
    private pageService: PageService, private platformHub: PlatformHubService, private authenticationService: AuthenticationService, private documentService: DocumentApiClient, private identityOrganisationService: IdentityOrganisationApiClient, private requestService: RequestApiClient,
    private router: Router, private sendgroupService: SendgroupApiClient
  ) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
    this.usersubscription = this.authenticationService.currentUser.subscribe((data) => {
      this.user = data;
    });
    this.requestSignalRSubscription = this.platformHub.builderRequest$.subscribe((request: BuilderRequest) => {
      if (request && request.id === this._requestGuid) {
        if (request.workgroup && !request.workgroupName) {
          let workgroup = this.authenticationService.workgroups.find(workgroup => workgroup.Guid === request.workgroup);
          if (workgroup != null) request.workgroupName = workgroup.Name;
        }
        if (request.documentInfo) {
          this.getAllDocuments().catch((error) => this.loggingService.logException(error));
        }
        this.setRequest(request);
      }
    });
    this.isMobile = /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/.exec(navigator.userAgent) !== null;
  }

  ngOnDestroy(): void {
    if (this.usersubscription) {
      this.usersubscription.unsubscribe();
    }
    if (this.requestSignalRSubscription) {
      this.requestSignalRSubscription.unsubscribe();
    }
  }

  getTranslations() {
    this.translateService.get([
      'Toasts.ToastsRequestDocumentDeleted',
      'Toasts.UpdateDossierError',
      'Toasts.GetDocumentError',
      'Toasts.RequestSentError',
      'Toasts.RequestsPurgeError',
      'Toasts.DocumentTypeNotSupportedInWorkflow',
      'Toasts.Error',
      'Toasts.Sent',
      'Toasts.RequestSent',
      'Toasts.Deleted',
      'Toasts.Saved',
      'Toasts.KvKHeader',
      'Toasts.KvKBody',
      'Toasts.ContainsPreviousSignatures',
      'Toasts.RequestDocumentDeleteError'
    ]).subscribe(translation => {
      this.translations.toastsRequestDocumentDeleted = translation['Toasts.ToastsRequestDocumentDeleted'];
      this.translations.toastsUpdateDossierError = translation['Toasts.UpdateDossierError'];
      this.translations.toastsGetDocumentError = translation['Toasts.GetDocumentError'];
      this.translations.toastsRequestSentError = translation['Toasts.RequestSentError'];
      this.translations.toastsRequestsPurgeError = translation['Toasts.RequestsPurgeError'];
      this.translations.toastsDocumentTypeNotSupportedInWorkflow = translation['Toasts.DocumentTypeNotSupportedInWorkflow'];
      this.translations.error = translation['Toasts.Error'];
      this.translations.toastsSent = translation['Toasts.Sent'];
      this.translations.toastsRequestSent = translation['Toasts.RequestSent'];
      this.translations.toastsDeleted = translation['Toasts.Deleted'];
      this.translations.toastsSaved = translation['Toasts.Saved'];
      this.translations.KvKHeader = translation['Toasts.KvKHeader'];
      this.translations.KvKBody = translation['Toasts.KvKBody'];
      this.translations.toastsContainsPreviousSignatures = translation['Toasts.ContainsPreviousSignatures'];
      this.translations.toastsRequestDocumentDeleteError = translation['Toasts.RequestDocumentDeleteError']
    });
  }

  // Method to update the count
  updateNotPlacedSignFieldCount(newCount: number): void {
    this.builderSubject.next(newCount);
  }
  /**
   * 
   * @param workflowId Loads the workflow associated with the Id into this service for Use
   */
  async initializeForWorkflow(workflowId: string) {
    this.workflowData = await this.workflowService.getWorkflowById(workflowId)
    this.onlyAllowedIdentityProviders = this.workflowData.step2.allowedIdentityProviders;

    this.checkWorkflows();
  }

  public changeCurrentTab(tabName: string) {
    this.currentTab = tabName;
    // if()
    this.currentTab$.next(this.currentTab);

    this.checkWorkflows();
  }

  private async checkWorkflows() {
    this.signYourself = (this.workflowData?.step2?.skipStep && this.workflowData?.step4?.skipStep) ?? false;
    this.signYourselfWorkflow$.next(this.signYourself);
    this.secureFileSending = this.workflowData?.step3?.skipStep ?? false;
    this.placeholdersStep3 = (this.workflowData?.step3?.placeholders && this.workflowData?.step3?.placeholders.length > 0) ?? false;

    if (this.signYourself && this.currentTab === "AddActions") await this.addActionsSignYourselfWorkflow(this._documents);
    else if (this.placeholdersStep3 && this.currentTab === "AddActions") await this.addPlaceholderActions(this.workflowData?.step3?.placeholders ?? []);
    if (this.secureFileSending && this.currentTab === "ReviewAndSend") await this.addSecureFileSendingActions(this._persons);
  }

  public getRequestSendGroups(): SendGroupModel[] {
    return this._request.builderSendgroups!;
  }

  public getRequestUniqueActors() {
    return this._actors.reduce((unique: any[], o: any) => {
      if (!unique.some((obj: EditorActor) => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
  }

  public getCountActionsOfActor(actorId: string) {
    return this._actors.filter(actor => actor.dossierPersonId === actorId).length ?? 0;
  }

  public getCountActorsOfDocument(documentId: string) {
    return this._actors.filter(actor => actor.documentId === documentId).length ?? 0;
  }

  public getActionsPerDocument(documentId: string) {
    return this._actors.filter(actor => actor.documentId === documentId);
  }

  async openEmailPreview(event: any) {
    let actor = this._actors.filter(item => item.dossierPersonId === event.id && item.action)[0];
    if (actor && actor !== undefined) {
      (await this.actorApiClient.getEmailTemplate(this._request.id, actor.id)).subscribe({
        next: (value: any) => {
          let ngbModalOptions: NgbModalOptions = {
            keyboard: false,
            centered: true,
            size: 'md'
          };
          const modalRef = this.modalService.open(EmailPreviewDialogComponent, ngbModalOptions);
          modalRef.componentInstance.emailTemplate = value;
        }
      });
    }
  }

  public openActionDialog(documentGuid: string, action: ActorActionEnum) {
    let ngbModalOptions: NgbModalOptions = {
      keyboard: false,
      centered: true,
      size: 'md',
      backdrop: 'static'
    };
    this.modalRef = this.modalService.open(DialogAddActionComponent, ngbModalOptions);
    this.modalRef.componentInstance.action = action;
    this.modalRef.componentInstance.request = this._request;
    let y = this;
    this.modalRef.componentInstance.newAction.subscribe(async (dataModel: any) => {
      this.modalRef.close();


      let actors: DocumentActor[] = [];
      dataModel.personIds.forEach(async (personId: string) => {
        this.AddNewBulkActorForRequest(action, personId, documentGuid);
        // let dossierPerson = y._request.dossierPersons.find((s: { dossierPersonId: string; }) => s.dossierPersonId === personId)!;
        // let actor = { ...dossierPerson };
        // actor.action = action;
        // actor.documentId = documentGuid;
        // actor.sigFieldX = 40;
        // actor.sigFieldY = 30;
        // // Set to null, to prevent the workflowPaceholderId to be filled with the one from dossierPerson (instead of actor).
        // actor.workflowPlaceholderId = null;
        // if (actor.fullName === null || actor.fullName === undefined) actor.fullName = actor.firstname + " " + (actor.prefix != null ? actor.prefix + " " : "") + actor.lastname;

        // actors.push(actor);

        // if (Is_Sign_Action(action) && actors && (actors.length == dataModel.personIds.length) && y._request.documentInfo[y._request.documentInfo.findIndex(s => s.id === actors[0].documentId)].documentType == "RegularPdf") {
        //   let actor = actors[0];
        //   actors.splice(0, 1);
        //   y.OpenPDFViewer({ documentGuid, actor, actors });
        // }
      });
      this.SendBulkActorsRequest(action);



      // if (Is_NON_Sign_Action(action)) {
      //   data.personIds.forEach(async (personId: string) => {
      //     let dossierPerson = y._request.dossierPersons.find((s: { dossierPersonId: string; }) => s.dossierPersonId === personId)!;
      //     let actor = { ...dossierPerson };
      //     actor.action = action;
      //     actor.documentId = documentGuid;
      //     actor = await y.addActionPerson({ documentId: documentGuid, actor: actor, actionIndex: y._request.actors.length - 1 });
      //   });
      // } else {
      //   let actors: DocumentActor[] = [];
      //   data.personIds.forEach(async (personId: string) => {
      //     let dossierPerson = y._request.dossierPersons.find((s: { dossierPersonId: string; }) => s.dossierPersonId === personId)!;
      //     let actor = { ...dossierPerson };
      //     actor.action = ActorActionEnum.Sign;
      //     actor.documentId = documentGuid;
      //     actor.sigFieldX = 40;
      //     actor.sigFieldY = 30;
      //     // Set to null, to prevent the workflowPaceholderId to be filled with the one from dossierPerson (instead of actor).
      //     actor.workflowPlaceholderId = null;
      //     if (actor.fullName === null || actor.fullName === undefined) actor.fullName = actor.firstname + " " + (actor.prefix != null ? actor.prefix + " " : "") + actor.lastname;

      //     actor = await y.addActionPerson({ documentId: actor.documentId, actor: actor, actionIndex: y._request.actors.length - 1 });
      //     actors.push(actor);

      //     if (actors && (actors.length == data.personIds.length) && y._request.documentInfo[y._request.documentInfo.findIndex(s => s.id === actors[0].documentId)].documentType == "RegularPdf") {
      //       let actor = actors[0];
      //       actors.splice(0, 1);
      //       y.OpenPDFViewer({ documentGuid, actor, actors });
      //     }
      //   });

      // }
    });
  }

  public openActionDialogWithoutDocumentId(action: ActorActionEnum) {
    this.openActionDialog(this._documents[0].id, action);
  }

  public setRequestGuid(requestGuid: string) {
    let previousRequestGuid = this._requestGuid;
    this._requestGuid = requestGuid;
    if (requestGuid && requestGuid !== "") {
      this.requestGuid$.next(this._requestGuid);
    }

    if (this._requestGuid === "") {
      // we are resetting requestGuid;
      this.setRequest({} as BuilderRequest);
      return;
    }
    if (previousRequestGuid == this._requestGuid) {
      // no changes
      return;
    }
  }
  public setRequest(request: BuilderRequest) {
    this._request = request;
    if (!this._request) return;
    this.request$.next(this._request);
    this.setDocuments(this._request.documentInfo);
    this.setPersons(this._request.dossierPersons);
    this.setActors(this._request.actors);
    this.setValidationSummary(this._request.validationSummary);
    this.setSendgroups(this._request.builderSendgroups);
    this.updateDocumentProgress();
    // set sendgroups
  }


  async OpenPDFViewer(event: any) {
    // if (this.modalService.hasOpenModals()) return;
    let doc = this._documents.find(d => d.id === event.documentGuid);

    // Always load the first few pages
    for (let i = 1; i <= 3; i++) {
      if (i <= 0) continue;
      await this.loadPage(doc!, i);
    }

    let ngbModalOptions: NgbModalOptions;
    if (!this.isMobile) {
      ngbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: false,
        modalDialogClass: 'pkiMd',
        windowClass: 'addSignatureFieldModal'
      };
    }
    else {
      ngbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: false,
        size: 'xl',
        windowClass: 'addSignatureFieldModalMobile'
      };
    }
    this.modalRef = this.modalService.open(PdfPreparationDialog, ngbModalOptions);
    let modal: PdfPreparationDialog = this.modalRef.componentInstance;

    //TODO: refactor to proper inputs and events
    modal.document = doc!;
    // modal.request = this._request;
    modal.actorGuid = event.actor;
    //modal.isMobile = this.isMobile!;
    modal.place = event.place ?? true;
    modal.displayfields = event.displayfields ?? true;
    modal.setMoreSignaturefields = event.actors && event.actors.length > 0;
    modal.allowedMultipleSignaturefields = event.allowedMultipleSignaturefields ?? true;
    modal.showEditDocument = event.showEditDocument ?? false;
    // modal.getRequest.subscribe(() => this.getRequest().catch((error: any) => {
    //   this.loggingService.logException(error);
    // }));
    if (!this.isMobile) {
      modal.zoomLevelChanged.subscribe(async (item: any) => {
        this.modalRef._windowCmptRef.instance.modalDialogClass = item.className;
        setTimeout(() => modal.afterZoomLevelChanged(), 100);
      });
    }
    modal.signatureFieldChanged.subscribe(async (sigField: any) => {
      modal.close();

      await this.updateActionPerson(sigField);
      this.updatePersonProgress();
      if (event.actors && event.actors.length > 0) {
        let actor = event.actors[0];
        event.actors.splice(0, 1);
        let existingModal = document.querySelector(".addSignatureFieldModal");
        if (existingModal) existingModal.parentNode?.removeChild(existingModal);
        this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: actor, actors: event.actors });
      } else if (sigField.newActorGuid) {
        this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: sigField.newActorGuid });
      }
    });
  }


  private async loadPage(doc: DocumentModel, pageId: number) {
    try {
      if (pageId < 1) return;
      if (pageId <= doc.pages) {
        let preloadedPage: PreloadedPages = {
          PageId: pageId,
          PageUrl: null
        }

        if (this._preloadedPagesByDocument === undefined) this._preloadedPagesByDocument = [];
        let pagesIndex = this._preloadedPagesByDocument.findIndex(pages => pages.documentId === doc.id);
        if (pagesIndex > -1) {
          let preloadedPagesIndex = this._preloadedPagesByDocument[pagesIndex].preloadedPages.findIndex(pp => pp.PageId === preloadedPage.PageId);
          if (preloadedPagesIndex > -1) {
            this._preloadedPagesByDocument[pagesIndex].preloadedPages[preloadedPagesIndex] = preloadedPage;
          } else {
            this._preloadedPagesByDocument[pagesIndex].preloadedPages.push(preloadedPage);
          }
        }
        else {
          let preloadedpagesByDocument: PreloadedPagesByDocument = {
            documentId: doc.id,
            preloadedPages: []
          }
          preloadedpagesByDocument.preloadedPages.push(preloadedPage);
          this._preloadedPagesByDocument.push(preloadedpagesByDocument);
        }
        let getPage = await this.pageService.getPage(this._request.id, doc.id, pageId);
        let page = await lastValueFrom(getPage);
        let pageImage = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page));

        if (this._preloadedPagesByDocument === undefined) this._preloadedPagesByDocument = [];
        if (pagesIndex > -1) {
          let preloadPageIndex = this._preloadedPagesByDocument[pagesIndex].preloadedPages.findIndex(s => s.PageId === pageId);
          if (preloadPageIndex > -1) {
            this._preloadedPagesByDocument[pagesIndex].preloadedPages[preloadPageIndex].PageUrl = pageImage;
          }
        }
      }
    }
    catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  public DeleteActorFromRequest(inviteId: string) {
    this.actorApiClient.Delete(this._requestGuid, inviteId).subscribe();
  }
  public CreateSingularActorForRequest(action: ActorActionEnum, personGuid: string, documentGuid: string) {
    this.actorApiClient.CreateSingular(this._requestGuid, action, personGuid, documentGuid).subscribe();
    // THIS REFRESHES IN BE
  }
  /**
   * YOU CAN ASSIGN NEW ACTIONS FOR THE BULK SENDING
   * ALWAYS USE:  SendBulkActorsRequest to finalize this call
   * @param action 
   * @param personGuid 
   * @param documentGuid 
   */
  public AddNewBulkActorForRequest(action: ActorActionEnum, personGuid: string, documentGuid: string) {
    if (!this.tempNewBulkActions) {
      this.tempNewBulkActions = [];
    }
    var newActionModel = new BaseActorCreateClass();
    newActionModel.action = action;
    newActionModel.personGuid = personGuid;
    newActionModel.documentGuid = documentGuid;
    this.tempNewBulkActions.push(newActionModel);
  }
  /**
   * SENDS all setup BULK actions
   * ALWAYS USE:  AddNewbulkActorForRequest to Setup this call
   * @param action 
   * @param personGuid 
   * @param documentGuid 
   */
  public SendBulkActorsRequest(action: string | null = null) {
    if (!this.tempNewBulkActions || this.tempNewBulkActions.length === 0) {
      return;
    }
    this.actorApiClient.CreateBulk(this._requestGuid, this.tempNewBulkActions).subscribe({
      next: (data: any) => {
        if (action === ActorActionEnum.Sign) {
          let actorInviteIds = data.filter((actor: any) => actor.inviteId != data[0].inviteId);
          this.OpenPDFViewer({ documentGuid: this.tempNewBulkActions[0].documentGuid, actor: data[0].inviteId, actors: actorInviteIds.map((d: any) => d.inviteId) });
        }
        this.tempNewBulkActions = [];
      }, error: () => {
        this.tempNewBulkActions = [];
      }
    });

  }

  private async addActiojfgnvgbjnbjnnPerson(event: any) {
    let actorData = event.actor as DocumentActor;
    let personGuid = actorData.dossierPersonId;
    let documentGuid = event.documentId;
    let requestGuid = this._requestGuid;
    let action = actorData.action;

    this.actorApiClient.CreateSingular(requestGuid, action, personGuid, documentGuid).subscribe();
  }

  private setPlaceholderIdIfPossible(event: any) {
    let document = this._request.documentInfo.find(document => document.id === event.actor.documentId);
    let dossierPerson = this._request.dossierPersons.find(person => person.dossierPersonId === event.actor.dossierPersonId);

    let correspondingPlaceholder: WorkflowStep3Placeholder | undefined = this.workflowData!.step3.placeholders!.find(placeholder =>
      placeholder.defaultDocumentPlaceholderId === document?.workflowPlaceholderId &&
      placeholder.defaultPersonPlaceholderId === dossierPerson?.workflowPlaceholderId &&
      placeholder.defaultActions.includes(event.actor.action));
    let noActorForPlaceholderYet = this._request?.actors?.findIndex(actor => actor.workflowPlaceholderId === correspondingPlaceholder?.placeholderId) === -1;

    if (correspondingPlaceholder && noActorForPlaceholderYet) {
      event.actor.workflowPlaceholderId = correspondingPlaceholder.placeholderId;
      event.actor.order = correspondingPlaceholder.placeholderId;
    }
  }

  public async updateActionPerson(event: any) {
    if (!event.actor || !event.actor.id) return;
    try {
      let updateActionPerson = await this.actorApiClient.updateActionToPerson(this._requestGuid!, event.documentId, event.actor);
      let value: any = await lastValueFrom(updateActionPerson);

      let actorIndex = this._actors?.findIndex(item => item.id == value.id);
      if (this.workflowData?.step3.placeholders && actorIndex && actorIndex === -1) {
        this._actors[actorIndex] = value;
        event.actor = value;
      } else if (actorIndex && actorIndex > -1) {
        this._actors[actorIndex] = value;
        event.actor = value;
      }
      if (this.signYourself && this._documents.length === 1 && ((event.actor.sigFieldW === 0 && event.actor.sigFieldH === 0 && event.actor.sigFieldX === 0 && event.actor.sigFieldY === 0) || (event.actor.sigFieldW === undefined && event.actor.sigFieldH === undefined && event.actor.sigFieldX === undefined && event.actor.sigFieldY === undefined))) {
        this.OpenPDFViewer({ documentGuid: event.documentGuid, actor: event.actor.id });
      }
    } catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  private async addPlaceholderActions(placeholders: WorkflowStep3Placeholder[], i: number = 0) {
    if (this._actors.length > 0) {
      return;
    }
    if (placeholders.length <= i) {
      let signActorsWithoutFields = [...this._actors.filter(s => Is_Sign_Action(s.action) && s.sigFieldPage < 0)];
      if (signActorsWithoutFields.length > 0 && this._documents.find(doc => doc.id === signActorsWithoutFields[0].documentId)?.documentType === "RegularPdf") {
        signActorsWithoutFields.splice(signActorsWithoutFields.findIndex(s => s.actorId === signActorsWithoutFields[0].actorId), 1);
        this.OpenPDFViewer({ documentGuid: this._actors.filter(s => Is_Sign_Action(s.action))[0].documentId, actor: this._actors.filter(s => Is_Sign_Action(s.action))[0].id, allowedMultipleSignaturefields: true });
      }
      return;
    }

    if (!placeholders || placeholders.length <= 0) {
      return;
    }

    placeholders.forEach(placeholder => {
      if ((placeholder.defaultPersonPlaceholderId === null || placeholder.defaultPersonPlaceholderId === undefined) &&
        (placeholder.defaultDocumentPlaceholderId === null || placeholder.defaultDocumentPlaceholderId === undefined) &&
        (placeholder.defaultActions === null || placeholder.defaultActions === undefined || placeholder.defaultActions.length == 0)) {
        return;
      }

      // ARRANGE document for creation
      let placeholderDocumentId = this._documents.find(s => s.workflowPlaceholderId === placeholder.defaultDocumentPlaceholderId)?.id;
      // some actions have no DOCUMENTid, but its still required
      if ((placeholderDocumentId === null || placeholderDocumentId === undefined)) {
        placeholderDocumentId = this._request?.documentInfo[0]?.id;
      }
      // Arrange personId(S) for creation
      let placeholderPersons: DocumentActor[] = this._persons.filter(s => s.workflowPlaceholderId === placeholder.defaultPersonPlaceholderId)!;
      if (placeholderPersons.length === 0 && placeholder.alternativePersonPlaceholderId) {
        placeholderPersons = this._persons.filter(s => s.workflowPlaceholderId === placeholder.alternativePersonPlaceholderId)!;
      }
      // now for the persons in placeholders:
      for (let dossierPerson of placeholderPersons) {
        // we are going to assign EVERY default action instead of 1
        for (let defaultAction of placeholder.defaultActions) {
          this.AddNewBulkActorForRequest(defaultAction as ActorActionEnum, dossierPerson.dossierPersonId, placeholderDocumentId!);
          // TODO:
          // if (placeholder.allowedQtsps != null && placeholder.allowedQtsps != undefined) {
          //   actor.allowedQtsps = placeholder.allowedQtsps;
          // }



        }
      }
    });

    this.SendBulkActorsRequest();

    // let documentId = this._request.documentInfo.find(s => s.workflowPlaceholderId === placeholder.defaultDocumentPlaceholderId)?.id;


    // if (this._request.actors.filter(d => d.workflowPlaceholderId === placeholder.placeholderId).length === 0) {
    //   let placeholderPersons: DocumentActor[] = this._request.dossierPersons.filter(s => s.workflowPlaceholderId === placeholder.defaultPersonPlaceholderId)!;
    //   if (placeholderPersons.length === 0 && placeholder.alternativePersonPlaceholderId) placeholderPersons = this._request.dossierPersons.filter(s => s.workflowPlaceholderId === placeholder.alternativePersonPlaceholderId)!;
    //   // now for the persons in placeholders:
    //   for (let dossierPerson of placeholderPersons) {
    //     // we are going to assign EVERY default action instead of 1
    //     for (let defaultAction of placeholder.defaultActions) {

    //       // some actions have no DOCUMENTid, but its still required
    //       if ((documentId === null || documentId === undefined) && Is_ANY_Download_Action(defaultAction)) {
    //         documentId = this._request?.documentInfo[0]?.id;
    //       }

    //       this.CreateActorForRequest(defaultAction as ActorActionEnum, dossierPerson.dossierPersonId, documentId!);
    // this.AddNewBulkActorForRequest(defaultAction as ActorActionEnum, dossierPerson.dossierPersonId, placeholderDocumentId!);
    // this.SendBulkActorsRequest();
    //       // TODO:
    //       // if (placeholder.allowedQtsps != null && placeholder.allowedQtsps != undefined) {
    //       //   actor.allowedQtsps = placeholder.allowedQtsps;
    //       // }


    //       let actor = { ...dossierPerson };
    //       actor.action = defaultAction;
    //       actor.documentId = documentId;
    //       actor.workflowPlaceholderId = placeholder.placeholderId;

    //       if (placeholder.allowedQtsps != null && placeholder.allowedQtsps != undefined) {
    //         actor.allowedQtsps = placeholder.allowedQtsps;
    //       }
    //       if (Is_Sign_Action(actor.action)) {
    //         actor.sigFieldX = 40;
    //         actor.sigFieldY = 30;
    //       }
    //       if (this._request.actors.length === 0 || this._request?.actors?.findIndex(s => s.dossierPersonId === actor.dossierPersonId) === 0) {
    //         actor.deadline = new Date(new Date(new Date().setDate(new Date().getDate() + placeholder.deadlineDateDaysFromNow)).setHours(new Date().getHours() + 1, 0, 0, 0));
    //       } else {
    //         actor.deadline = this.DEFAULT_MAX_DEADLINE_DATE;
    //         actor.deadlineDays = this.workflowData && this.workflowData.step3.placeholders && this.workflowData.step3.placeholders.find(s => s.placeholderId === placeholder.placeholderId) ? this.workflowData.step3.placeholders.find(s => s.placeholderId === placeholder.placeholderId)!.deadlineDaysAfterPreviousAction : this.deadlineDays;
    //       }
    //       actor.order = this._request.actors.filter(s => (s.workflowPlaceholderId != null ? s.workflowPlaceholderId : 1) <= placeholder.placeholderId).length > 0 ? Math.max(...this._request.actors.filter(s => (s.workflowPlaceholderId != null ? s.workflowPlaceholderId : 1) <= placeholder.placeholderId).map(a => a.order)) + 1 : 0;
    //       if (actor.fullName === null || actor.fullName === undefined) actor.fullName = actor.firstname + " " + (actor.prefix != null ? actor.prefix + " " : "") + actor.lastname;
    //       await this.addActionPerson({ documentId: actor.documentId, actor: actor, actionIndex: this._request.actors.length - 1 });

    //     }
    //   };
    // }
    // i++;
    // await this.addPlaceholderActions(placeholders, i);
  }

  private async addActionsSignYourselfWorkflow(documents: DocumentModel[], i: number = 0) {
    let tempDocumentList = documents;
    if (!tempDocumentList || tempDocumentList.length < 1) {
      return;
    }
    if (this._actors.length > 0) {
      return;
    }
    tempDocumentList.forEach(document => {
      this.AddNewBulkActorForRequest(ActorActionEnum.Sign, this._persons[0].dossierPersonId, document.id);
    });
    this.SendBulkActorsRequest(ActorActionEnum.Sign);
    // TODO:
    // this.OpenPDFViewer({ documentGuid: actor.documentId, actor: actor });
    // 



    // if (documents.length <= i) return;
    // let document: DocumentModel = documents[i];
    // i++;
    // if (this._actors.filter(s => s.documentId === document.id).length > 0)
    //   return;
    // else {
    //   let dossierPerson = this._persons[0];
    //   let actor = { ...dossierPerson };
    //   actor.action = ActorActionEnum.Sign;
    //   actor.documentId = document.id;
    //   actor.workflowPlaceholderId = null;
    //   actor.deadline = this.deadline;
    //   actor.deadlineDays = this.deadlineDays;
    //   actor.sigFieldX = 40;
    //   actor.sigFieldY = 30;
    //   if (actor.fullName === null || actor.fullName === undefined) actor.fullName = actor.firstname + " " + (actor.prefix != null ? actor.prefix + " " : "") + actor.lastname;
    //   await this.addActionPerson({ documentId: actor.documentId, actor: actor, actionIndex: this._actors.length - 1 });
    //   await this.addActionsSignYourselfWorkflow(documents, i);
    //   this.OpenPDFViewer({ documentGuid: actor.documentId, actor: actor });
    // }
  }

  private async addSecureFileSendingActions(persons: DocumentActor[]) {
    let tempPersonList = persons;
    if (!tempPersonList || tempPersonList.length < 1) {
      return;
    }
    tempPersonList.forEach(person => {
      this.AddNewBulkActorForRequest(ActorActionEnum.Download, person.dossierPersonId, this._documents[0].id);
    });
    this.SendBulkActorsRequest();
    // let person = dossierPersons[i];

    // if (this._actors.findIndex(d => d.dossierPersonId === person.dossierPersonId) < 0) {
    //   let dossierPerson = this._persons.find(s => s.dossierPersonId === person.dossierPersonId)!;
    //   let actor = { ...dossierPerson };
    //   actor.action = ActorActionEnum.Download;
    //   actor.documentId = this._documents[0].id;
    //   actor.workflowPlaceholderId = null;
    //   actor.deadline = new Date(new Date(new Date().setDate(new Date().getDate() + this.workflowData!.step3.defaultDeadlineDaysFromNow!)).setHours(new Date().getHours() + 1, 0, 0));
    //   actor.deadlineDays = this.workflowData!.step3.defaultDeadlineDaysFromNow!;
    //   if (actor.fullName === null || actor.fullName === undefined) actor.fullName = actor.firstname + " " + (actor.prefix != null ? actor.prefix + " " : "") + actor.lastname;
    //   let actorIndex = this._request.actors.length;
    //   this._request.actors.forEach((s: DocumentActor, i: number) => {
    //     if (s.dossierPersonId === actor.dossierPersonId) actorIndex = i + 1;
    //   });
    //   await this.addActionPerson({ documentId: actor.documentId, actor: actor, actionIndex: this._actors.length - 1 });
    // }
    // i++;
    // await this.addSecureFileSendingActions(dossierPersons, i);
  }

  public setPersons(persons: DocumentActor[] = []) {
    this._persons = persons;
    this.persons$.next(this._persons);
  }

  public setActors(actors: DocumentActor[] = []) {
    this._actors = actors;
    this.actors$.next(this._actors);
  }

  public setValidationSummary(validationSummary: ValidationSummaryClass | undefined | null) {
    if (!validationSummary) {
      this._validationSummary = new ValidationSummaryClass();
      this.validationSummary$.next(this._validationSummary);

      return;
    }
    this._validationSummary = validationSummary;
    this.validationSummary$.next(this._validationSummary);
  }

  public setSendgroups(sendgroups: SendGroupModel[] | undefined | null) {
    if (!sendgroups) {
      return;
    }
    this._sendgroups = sendgroups;
    this.sendgroups$.next(this._sendgroups);
    if (this._sendgroups?.length > 0 && this._actors?.length > 0) {
      let isCurrentUserAnActorIndex = this._actors.findIndex(s => s.email === this.user.email && s.mobile === this.user.phoneNumber);
      if (isCurrentUserAnActorIndex > -1) {
        let isCurrentUserAnActorAsDossierPersonId = this._actors[isCurrentUserAnActorIndex].dossierPersonId;
        if (this._sendgroups[0].actions.filter(s => s.personGuid === isCurrentUserAnActorAsDossierPersonId).length > 0) {
          this.isInFirstSendgroup$.next(true);
        }
      }
    }
  }

  public updateSendgroup(sendGroupGuid: string, sendGroupName: string, sendgroupDeadline: Date | undefined | null = null, sendGroupDeadlineDays: number | undefined | null = null) {
    var updateModel = new UpdateSendGroupModel();
    updateModel.sendGroupGuid = sendGroupGuid;
    updateModel.sendGroupName = sendGroupName;
    updateModel.deadline = sendgroupDeadline;
    updateModel.deadlineDays = sendGroupDeadlineDays;

    this.sendgroupService.updateSendgroup(this._requestGuid, updateModel).subscribe();
  }

  public setDocuments(documents: DocumentModel[] = []) {
    this._documents = documents;
    this._documents.forEach(async doc => {
      if (!doc.preloadedPages) {
        doc.preloadedPages = [];
      }
      for (let i = 1; i <= 3; i++) {
        if (i <= 0) continue;
        await this.loadPage(doc!, i);
      }
    });
    this.documents$.next(this._documents);
  }

  public setCurrentTab(currentTab: string = "AddDocuments") {
    this.currentTab = currentTab;
    this.currentTab$.next(this.currentTab);
  }

  public setWorkflowId(workflowId: string) {
    this.workflowId = workflowId;
  }

  public getWorkflowId() {
    return this.workflowId;
  }

  async setWorkflow() {
    this.workflowSteps = ['AddDocuments', 'AddPersons', 'AddActions', 'ReviewAndSend'];
    if (!this.workflowId) {
      // somehow you tried to open the builder without 'instructions' (workflow)
      // make sure instructions are loaded:
      let defaultWorkflowGuid = this.workflowService.getDefaultWorkflowGuid();
      this.workflowId = defaultWorkflowGuid;
    }
    // ALWAYS load a workflow:
    try {
      let value: Workflow = await this.workflowService.getWorkflowById(this.workflowId);
      // also load the workflow data into the service so it can be used anywhere in the page!
      this.initializeForWorkflow(this.workflowId);

      this.workflowData = value;
      if (this.workflowData?.step1?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('AddDocuments'), 1);
      if (this.workflowData?.step2?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('AddPersons'), 1);
      if (this.workflowData?.step3?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('AddActions'), 1);
      if (this.workflowData?.step4?.skipStep) this.workflowSteps.splice(this.workflowSteps.indexOf('ReviewAndSend'), 1);

      if (this.workflowData?.requireKVK) {
        this.checkKVK();
      }
      this.workflowData$.next(this.workflowData);
      this.workflowSteps$.next(this.workflowSteps);

    } catch (error: any) {
      this.loggingService.logException(error);
    }
    // this.activeTab = this.workflowSteps[0];
    this.signYourself = (this.workflowData?.step2?.skipStep && this.workflowData?.step4?.skipStep) ?? false;
  }

  private async checkKVK() {
    this.identityOrganisationService.getOrganisationKvk(this.user.organisationGuid).subscribe((KVK) => {
      this.organisationHasKVK = (KVK != null && KVK != "");
    });
  }
  async getAllDocuments() {
    for (let doc of this._request.documentInfo) {
      if (doc.containsPreviousSignatures && !this.workflowData?.step1.allowPreviousSignatures) {
        // Only delete documents if all documents are done loading, otherwise signalR adds the deleted documents again.
        let allDocumentsHaveBeenProcessed = this._request.documentInfo.filter(d => d.documentStatus !== 'New').length == 0;
        if (allDocumentsHaveBeenProcessed && (doc.documentStatus == 'New' || doc.documentStatus == 'Active')) {
          this.toastService.showError(this.translations.error, this.translations.toastsContainsPreviousSignatures);
          this.deleteDocument(doc.id, false);
        }
        continue;
      }
      if (!this.checkDocumenttypeAllowedInWorkflow(doc.documentType, doc.workflowPlaceholderId)) {
        let placeholder: WorkflowStep1Placeholder | undefined = this.shouldBeOnPlaceholderPosition(doc.documentType);
        if (placeholder == undefined) {
          doc.error = "DocumentTypeNotSupportedInWorkflow";
          if (!this.notSupported) {
            this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsDocumentTypeNotSupportedInWorkflow + "");
          }
          this.notSupported = true;

          if (doc.documentStatus == 'New') {
            this.deleteDocument(doc.id);
          }
        }
        else {
          let existingDocumentForPlaceholder = this._request.documentInfo.find(document => document.workflowPlaceholderId == placeholder!.placeholderId);

          if (existingDocumentForPlaceholder)
            this.updatePlaceholderId(existingDocumentForPlaceholder, null)

          this.updatePlaceholderId(doc, placeholder.placeholderId);
        }
      }

      if ((doc.documentStatus.toLocaleLowerCase() === "new" || doc.documentStatus.toLocaleLowerCase() === "active") && (!doc.error || doc.error === '')) {
        if ((doc.documentStatus !== 'Processing' && this._preloadedPagesByDocument.length === 0) || doc.fileSize === null || doc.fileSize === undefined) {
          await this.getDocument(doc);
        }
      }
    }
    if (this._request.documentInfo.length > 0) {
      this.updateDocumentProgress();
    }
    this.calcProgress();
  }

  public deleteDocument(docId: string, showToast: boolean = true): void {
    let document = this._request.documentInfo.find(document => document.id === docId);
    if (!document) return;
    let documentName = document.name;

    let documentIndex = this._request.documentInfo.findIndex(document => document.id == docId);
    this._request.documentInfo.splice(documentIndex, 1);

    this.documentService.deleteDocument(this._request.id, docId)
      .then((value: any) => {
        lastValueFrom(value).then(() => {
          if (showToast) { this.toastService.showSuccess("" + this.translations.toastsDeleted + "", "" + this.translations.toastsRequestDocumentDeleted + "") };

          if (documentName.split(".")[0] == this._request.jobName) {
            if (this._request.documentInfo.length > 0) {
              this._request.jobName = this._request.documentInfo[0].name.split(".")[0]
            } else {
              this._request.jobName = "";
            }
          }
        }).catch(() => {
          this._request.documentInfo.splice(documentIndex, 0, document!);
          this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestDocumentDeleteError + "");
        });
      }).catch(() => {
        this._request.documentInfo.splice(documentIndex, 0, document!);
        this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestDocumentDeleteError + "");
      });
  }

  public checkDocumenttypeAllowedInWorkflow(documentType: string, placeholderId?: number | null): boolean {
    if (!this.workflowData || !documentType || documentType.toLocaleLowerCase() === "unknown") {
      return true;
    } else if (this.workflowData
      && (!this.workflowData.step1.placeholders || (this.workflowData.step1.allowAddDocuments && placeholderId == null))
      && this.workflowData.step1.supportedDocumentTypes.includes(documentType)) {
      return true;
    }
    else if (this.workflowData
      && this.workflowData.step1.placeholders
      && this.workflowData.step1.placeholders.find(s => s.placeholderId === placeholderId)?.supportedDocumentTypes.includes(documentType)) {
      return true;
    }
    else if (this.workflowData && !this.workflowData.step1.allowRendering) {
      return true;
    }
    else {
      return false;
    }
  }

  shouldBeOnPlaceholderPosition(documentType: string): WorkflowStep1Placeholder | undefined {
    let hasStep1Placeholders: boolean | undefined = this.workflowData?.step1.placeholders && this.workflowData.step1.placeholders.length > 0;

    if (hasStep1Placeholders) {
      let placeholderWithMatchingDocumentType = this.workflowData!.step1.placeholders!.find(placeholder => placeholder.supportedDocumentTypes.includes(documentType));
      let placeholderHasCorrectDocument = this._request.documentInfo.findIndex(document => document.workflowPlaceholderId == placeholderWithMatchingDocumentType?.placeholderId
        && placeholderWithMatchingDocumentType?.supportedDocumentTypes.includes(document.documentType)) > -1;

      if (!placeholderWithMatchingDocumentType || placeholderHasCorrectDocument) return;

      return placeholderWithMatchingDocumentType;
    }
    return;
  }

  updatePlaceholderId(document: DocumentModel, placeholderId: number | null) {
    document.workflowPlaceholderId = placeholderId;
    this.documentService.updateDocumentPlaceholderId(this._request.id, document.id, placeholderId ?? -1)
      .then((data: any) => {
        lastValueFrom(data)
          .catch(error => this.loggingService.logException(error))
      }).catch(error => this.loggingService.logException(error));
  }

  async getDocument(doc: DocumentModel) {
    try {
      if (doc.fileSize && doc.pages && doc.pageSizes && doc.containsPreviousSignatures && this._preloadedPagesByDocument?.length != null) {
        return;
      }
      let getDoc = await this.documentService.getDocument(this._requestGuid!, doc.id);
      let document: any = await lastValueFrom(getDoc);
      if (document === null) return;
      doc.name = document.name;
      doc.fileSize = document.documentSize;
      doc.documentStatus = document.status;
      doc.pages = document.pages;
      doc.containsPreviousSignatures = document.containsPreviousSignatures;
      doc.pageSizes = document.pageSizes;
      this._documents.filter(s => s.id === doc.id)[0].fileSize = doc.fileSize;
      this._documents.filter(s => s.id === doc.id)[0].documentStatus = document.status;
      this._documents.filter(s => s.id === doc.id)[0].pages = document.pages;
      this._documents.filter(s => s.id === doc.id)[0].containsPreviousSignatures = document.containsPreviousSignatures;
      await this.getPages(doc);
      this.setDocuments(this._documents);
    }
    catch (error) {
      this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsGetDocumentError + "");
    }
  }

  async getPages(doc: DocumentModel) {
    this.pageId = 1;
    if (doc.documentStatus !== "Processing") {
      let loadPages = doc.pages < 5 ? doc.pages : this.defaultPreloadPages;
      for (let i: number = 1; i <= loadPages; i++) {
        try {
          let getPage = await this.pageService.getPage(this._requestGuid!, doc.id, i);
          let page = await lastValueFrom(getPage);
          this.pageId++;
          let preloadedPage: PreloadedPages = {
            PageId: i,
            PageUrl: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page))
          }
          if (this._preloadedPagesByDocument === undefined) this._preloadedPagesByDocument = [];
          let pagesIndex = this._preloadedPagesByDocument.findIndex(pages => pages.documentId === doc.id);
          if (pagesIndex > -1) {
            let preloadedPagesIndex = this._preloadedPagesByDocument[pagesIndex].preloadedPages.findIndex(pp => pp.PageId === preloadedPage.PageId);
            if (preloadedPagesIndex > -1) {
              this._preloadedPagesByDocument[pagesIndex].preloadedPages[preloadedPagesIndex] = preloadedPage;
            } else {
              this._preloadedPagesByDocument[pagesIndex].preloadedPages.push(preloadedPage);
            }
          }
          else {
            let preloadedpagesByDocument: PreloadedPagesByDocument = {
              documentId: doc.id,
              preloadedPages: []
            }
            preloadedpagesByDocument.preloadedPages.push(preloadedPage);
            this._preloadedPagesByDocument.push(preloadedpagesByDocument);
          }

        }
        catch (error: any) {
          this.loggingService.logException(error);
        }
      }
    } else {
      doc.documentStatus = this._request.documentInfo.filter(p => p.id === doc.id)[0].documentStatus;
    }
  }

  public updateDocumentProgress() {
    if (this.meetsDocumentAmount() && this._request.documentInfo && this._request.documentInfo.length > 0 && this._request.documentInfo.filter(s => s.error).length === 0) {
      this.setProgress(0, ((this._request.documentInfo.filter(p => p.documentStatus !== 'Processing').length / this._request.documentInfo.length) * 100));
    } else {
      this.setProgress(0, 0);
    }
  }

  public setProgress(index: number, progress: number) {
    this._progress[index] = progress;
    this.progress$.next(this._progress);
  }



  public meetsDocumentAmount(): boolean {
    return !this.workflowData
      || !this.workflowData.step1
      || (!this.workflowData.step1.minDocumentAmount && !this.workflowData.step1.placeholders)
      || (!this.workflowData.step1.placeholders && this.workflowData.step1.minDocumentAmount && this._request.documentInfo && this.workflowData.step1.minDocumentAmount <= this._request.documentInfo.length)
      || (this.workflowData.step1.placeholders !== undefined && this.workflowData.step1.placeholders.length === this._request.documentInfo?.filter(docInfo => docInfo.workflowPlaceholderId != null && docInfo.workflowPlaceholderId != undefined).length)
      || (this.workflowData.step1.placeholders !== undefined && this.workflowData.step1.placeholders.filter(placeholder => !placeholder.optional).map(s => s.placeholderId).every((placeholderId) => {
        return this._request.documentInfo?.filter(docInfo => docInfo.workflowPlaceholderId != null && docInfo.workflowPlaceholderId != undefined).map(d => d.workflowPlaceholderId).indexOf(placeholderId) !== -1
      }));
  }

  public calcProgress() {
    if (!this.meetsActionAmount()) {
      this.setProgress(2, 0);
    } else {
      let amountOfObjects: number = 0;
      let completedObjects: number = 0;
      this._request.dossierPersons?.forEach(p => {
        amountOfObjects += 1;
        if (this._request?.actors?.findIndex(a => a.dossierPersonId == p.dossierPersonId && a.action !== null && a.action !== undefined && a.documentId !== null && a.documentId !== undefined && a.documentId !== "") > -1) {
          completedObjects += 1;
        }
      });
      this._request.documentInfo?.filter(s => !s.attachment).forEach(p => {
        amountOfObjects += 1;
        if (this._request?.actors?.findIndex(actionActor => (actionActor.documentId == p.id && (actionActor.action !== null && actionActor !== undefined) && !Is_ANY_Download_Action(actionActor.action)) || (Is_ANY_Download_Action(actionActor.action) && this._request.actors.every(allActor => Is_ANY_Download_Action(allActor.action)))) > -1) {
          // do the sign actions WITHOUT fields but being PDFs match the Total sign actions?
          var signActorsWithoutFieldCount = this._request.actors.filter(signActorWithoutField => (Is_Sign_Action(signActorWithoutField.action) && (!(!signActorWithoutField.sigFieldW && !signActorWithoutField.sigFieldH) || p.documentType !== 'RegularPdf'))).length;
          var totalSignActorsCount = this._request.actors.filter(allSignActor => Is_Sign_Action(allSignActor.action)).length;
          if (signActorsWithoutFieldCount === totalSignActorsCount) {
            completedObjects += 1;
          }
        }
      });
      if (this._request.actors != null && this._request.actors != undefined) {
        this._request.actors.forEach(a => {
          amountOfObjects += 1;
          if (a.action !== null && a.action !== undefined && a.documentId !== null && a.documentId !== undefined && a.documentId !== "") {
            completedObjects += 1;
          }
        })
      }
      // cannot divide by 0
      if (amountOfObjects == 0) { amountOfObjects = 1; }
      let progress: number = ((completedObjects / amountOfObjects) * 100);
      this.setProgress(2, progress);
    }
  }

  public meetsActionAmount(): boolean {
    return !this.workflowData
      || !this.workflowData.step3
      || (!this.workflowData.step3.minActionAmount && !this.workflowData.step3.placeholders)
      || (!this.workflowData.step3.placeholders && this.workflowData.step3.minActionAmount && this._request.actors && this.workflowData.step3.minActionAmount <= this._request.actors.length)
      || (this.workflowData.step3.placeholders !== undefined && (this.workflowData.step3.placeholders.length === this._request.actors?.filter(actor => actor.workflowPlaceholderId != null && actor.workflowPlaceholderId != undefined).length
        || this.workflowData?.step3.placeholders?.filter(placeholder => !placeholder.optional).map(s => s.placeholderId).every((placeholderId) => {
          return this._request.actors?.filter(actor => actor.workflowPlaceholderId != null && actor.workflowPlaceholderId != undefined)
            .map(d => d.workflowPlaceholderId)
            .indexOf(placeholderId) !== -1 && this._request?.actors?.filter(s => Is_NON_Download_Action(s.action)).filter((v, i, a) => a.findIndex(v2 => (v2.documentId === v.documentId)) === i).length === this._request?.documentInfo?.length
        })));
  }

  updateProgress(position: number, progress: number) {
    this.setProgress(position, progress);
    this.getDocumentsWithoutActions();
    this.getPersonsWithoutActions();
    this.getActionsWithoutPlacedSignatures();
  }


  public getActionsWithoutPlacedSignatures() {
    let signAbleActions = this._request.actors?.filter(actor => Is_Sign_Action(actor.action) && this._request.documentInfo.find(x => x.id == actor.documentId)?.documentType == DocumentTypeEnum.RegularPdf);
    if (signAbleActions != null && signAbleActions != undefined) {
      let totalSignAbleActions = signAbleActions.length;
      let totalSignedActions = signAbleActions.filter(a => (!(!a.sigFieldW && !a.sigFieldH))).length;
      this.updateNotPlacedSignFieldCount(totalSignAbleActions - totalSignedActions);
    }
  }
  getDocumentsWithoutActions() {
    this.countDocuments = 0;
    // has only download Actors, and no other Actions?
    if (this._request.actors?.filter(p => Is_ANY_Download_Action(p.action)).length > 0 && this._request.actors?.filter(p => Is_NON_Download_Action(p.action)).length === 0) {
      this.countDocuments = 0;
    } else {
      // or document has no Actors/only download
      this._request.documentInfo?.forEach((document, i) => {
        if (!document.actors || document.actors === undefined || document.actors.filter(actor => Is_NON_Download_Action(actor.action)).length === 0) {
          this.countDocuments += 1;
        }
      });
    }
  }

  getPersonsWithoutActions() {
    this.countPersons = this._request.dossierPersons?.length;
    this._request.dossierPersons?.forEach((s, i) => {
      if (this._request.actors.filter(p => p.dossierPersonId === s.dossierPersonId).length > 0) {
        this.countPersons -= 1;
      }
    });
  }



  public async getRequest() {
    await this.requestService.getRequestForBuilder(this._requestGuid!).then(dossierResp => {
      lastValueFrom(dossierResp)
        .then(async (request: BuilderRequest) => {
          this._request = request;
          this.setRequestGuid(this._requestGuid!);
          this.setRequest(request);
          if (this._request.workflowId) {
            this.workflowId = this._request.workflowId;

            this.setWorkflowId(this.workflowId);
            // also load the workflow data into the service so it can be used anywhere in the page!
            this.initializeForWorkflow(this.workflowId);
          }
          await this.setWorkflow();
          if (Object.keys(this._request).length !== 0) {
            if (this._request.status && this._request.status.toLocaleLowerCase() != "new" && this._request.status.toLocaleLowerCase() != "active" && this._request.status.toLocaleLowerCase() != "error" && this._request.status.toLocaleLowerCase() != "processing")
              this.router.navigate(["/request/" + this._requestGuid + ""])
                .catch(error => {
                  this.loggingService.logException(error);
                });

            let documentModels: DocumentModel[] = this._request.documentInfo ?? [];
            for (let doc of documentModels) {
              if (!this.checkDocumenttypeAllowedInWorkflow(doc.documentType, doc.workflowPlaceholderId)) {
                doc.error = "DocumentTypeNotSupportedInWorkflow";
                if (!this.notSupported) {
                  this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsDocumentTypeNotSupportedInWorkflow + "");
                }
                this.notSupported = true;

                if (doc.documentStatus == 'New') {
                  this.deleteDocument(doc.id);
                }
              }

              if (doc.id && (((doc.documentStatus !== 'Processing' && this._preloadedPagesByDocument.length === 0)
                || doc.fileSize === null || doc.fileSize === undefined) && (!doc.error || doc.error === ''))) {
                await this.getDocument(doc);
              }
            }
            if (this._request.documentInfo && this._request.documentInfo.length > 0 && this._request.documentInfo.every(s => s.documentStatus.toLocaleLowerCase() === 'new' || s.documentStatus.toLocaleLowerCase() === 'active') && this.meetsDocumentAmount() && this._request.documentInfo.filter(s => s.error).length === 0) this.setProgress(0, 100)
            this.updatePersonProgress();

            if (this._request.actors && this._request.actors.length > 0) {
              this.calcProgress();
            }
          }
          this.getDocumentsWithoutActions();
          this.getPersonsWithoutActions();
          this.getActionsWithoutPlacedSignatures();
        }).catch((error) => this.loggingService.logException(error));
    });

  }

  public updatePersonProgress() {
    if (this.meetsPersonAmount() && this._request.dossierPersons && this._request.dossierPersons.length > 0) {
      this.setProgress(1, 100);
    } else {
      this.setProgress(1, 0);
    }
  }

  public meetsPersonAmount(): boolean {
    return !this.workflowData
      || !this.workflowData.step2
      || (!this.workflowData.step2.minPersonAmount && !this.workflowData.step2.placeholders)
      || (!this.workflowData.step2.placeholders && this.workflowData.step2.minPersonAmount && this._request.dossierPersons && this.workflowData.step2.minPersonAmount <= this._request.dossierPersons.length)
      || (this.workflowData.step2.placeholders != undefined && this.workflowData.step2.placeholders.length === this._request.dossierPersons?.filter(person => person.workflowPlaceholderId != null && person.workflowPlaceholderId != undefined).length)
      || (this.workflowData.step2.placeholders !== undefined && this.workflowData.step2.placeholders.filter(placeholder => !placeholder.optional).map(s => s.placeholderId).every((placeholderId) => {
        return this._request.dossierPersons?.filter(dossierPerson => dossierPerson.workflowPlaceholderId != null && dossierPerson.workflowPlaceholderId != undefined).map(d => d.workflowPlaceholderId).indexOf(placeholderId) !== -1
      }));
  }

  public updateRequest(event: any) {
    if (event?.dossierPersons == undefined) event.dossierPersons = [];
    this.setRequest(event);
    if (this._requestGuid == undefined || this._requestGuid == null) {
      this._requestGuid = event.id;
    }
    if (this._request.documentInfo?.length > 0 && this.meetsDocumentAmount()) {
      this.setProgress(0, ((this._request.documentInfo.filter(p => p.documentStatus !== 'Processing').length / this._request.documentInfo.length) * 100));
    }
    this.calcProgress();
    this.getDocumentsWithoutActions();
    this.getPersonsWithoutActions();
    this.getActionsWithoutPlacedSignatures();
  }

  public getRequestById(event: string) {
    if (this._requestGuid === null || this._requestGuid === undefined) {
      this.setRequestGuid(event);
    }
    this.requestService.extendDeadlinesIfNeeded(this._requestGuid).subscribe();
    this.getRequest().then().catch((error: any) => {
      this.loggingService.logException(error);
    });
  }

  public async changeOrderActors(actor: DocumentActor) {
    (await (this.actorApiClient.changeOrder(this._requestGuid, actor.documentId, actor.id, this._request.actors))).subscribe({
      error: (error: HttpErrorResponse) => { },
      next: (value: any) => {
      }
    });
  }

  public async mergeSendgroups() {
    await (this.sendgroupService.mergeSendgroups(this._requestGuid)).subscribe();
  }

  async SendRequest() {
    if (this.workflowData?.requireKVK && !this.organisationHasKVK) {
      if (this.toastService.toasts.findIndex(p => p.header === this.translations.KvKHeader) == -1)
        this.toastService.showError(this.translations.KvKHeader, this.translations.KvKBody, 10000);
      return;
    }
    let futureSigners = this._request.actors.filter(item => { return item.hasSigned === false });
    let userIsFirstSigner = futureSigners.length > 0 && futureSigners.find(s => s.dossierPersonId === this._request.actors[0].dossierPersonId) &&
      futureSigners.find(s => s.dossierPersonId === this._request.actors[0].dossierPersonId)!.email === this.user.email;

    if (userIsFirstSigner) {
      this.windowToOpenForIfFirstActor = window.open(undefined, '_blank');
    }
    try {
      let sendReq = await this.requestService.sendRequest(this._request.id);
      await lastValueFrom(sendReq);
      if (userIsFirstSigner) {
        let url = this.router.serializeUrl(this.router.createUrlTree(["/view/" + this._request.id]));
        if (Is_ANY_Download_Action(futureSigners[0].action)) {
          url = this.router.serializeUrl(this.router.createUrlTree(["/download/" + this._request.id]));
        }
        if (this.windowToOpenForIfFirstActor !== undefined && this.windowToOpenForIfFirstActor !== null) {
          this.windowToOpenForIfFirstActor.location.href = url;
        }
        this.router.navigate(["/dashboard"])
          .catch(error => {
            this.loggingService.logException(error);
          });
      } else {
        this.toastService.showSuccess(this.translations.toastsSent, this.translations.toastsRequestSent);
        this.router.navigate(["/dashboard"])
          .catch(error => {
            this.loggingService.logException(error);
          });
      }
    } catch (error: any) {
      this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestSentError + "")
    }
  }

  getPreloadedPagesByDocument(documentId: string) {
    let documentIndexForPreloadedPages = this._preloadedPagesByDocument.findIndex(doc => doc.documentId === documentId);
    if (documentIndexForPreloadedPages > -1) {
      return this._preloadedPagesByDocument[documentIndexForPreloadedPages].preloadedPages;
    }
    return [];
  }

  public async createSendgroup(requestGuid: string, order: number, ForPersonGuids: string[]) {
    await (this.sendgroupService.createSendgroup(requestGuid, order, ForPersonGuids)).subscribe();
  }
}
