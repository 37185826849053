<div class="container-fuid bg-white default-boxrounded">
    <div class="row">
        <div class="col-12">
            <h2 class="title" translate>Request.Person.EditPerson</h2>
        </div>
    </div>

    <div class="px-4">
        <form class="row needs-validation has-validation" [formGroup]="form">
            <div class="mb-3 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="firstname" class="form-label" translate>Request.Person.Firstname</label>
                <input type="text" id="firstname" class="form-control" formControlName="firstname" [value]="person.firstname">
            </div>
            <div class="mb-3 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="prefix" class="form-label" translate>Request.Person.Prefix</label>
                <input type="text" id="prefix" class="form-control" [value]="person.prefix" formControlName="prefix" placeholder="{{ 'Request.Person.Prefix' | translate }}">
            </div>

            <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="lastname" class="form-label" translate>Request.Person.LastName</label>
                <input type="text" id="lastname" class="form-control" formControlName="lastname" [value]="person.lastname">
            </div>

            <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="email" class="form-label" translate>Request.Person.EmailAddress</label>
                <input type="email" id="email" class="form-control" (keyup)="personChange('email',$event)" (focusout)="personChange('email',$event)" value="{{person.email}}" 
                    formControlName="email" placeholder="{{ 'Request.Person.EmailAddress' | translate }}" required email="true" [value]="person.email"
                    popupClass="dropdown-menu-person-dialog" autocomplete="off">
                <app-formcontrol-error-messages [forControl]="emailControl"></app-formcontrol-error-messages>
            </div>

            <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="mobile" class="form-label" translate>Request.Person.Mobile</label>

                <input type="text" id="mobile" class="form-control" (keyup)="personChange('phone',$event)" (focusout)="personChange('mobile',$event)" value="{{person.phone}}" 
                    formControlName="mobile" placeholder="{{ 'Request.Person.Mobile' | translate }}" required [value]="person.phone"
                    popupClass="dropdown-menu-person-dialog" autocomplete="off">
                <app-formcontrol-error-messages [forControl]="phoneControl"></app-formcontrol-error-messages>
            </div>
            
            <div class="mb-3 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="personLanguage" class="form-label" translate="Request.Person.Language"><app-info-button labelName="Request.Person.LanguageInfoButton"></app-info-button></label>
                <div ngbDropdown class="dropdown" ngDefaultControl name="personLanguage" id="personLanguage">
                    <button class="btn language dropdown-toggle form-control text-truncate default" type="button" id="dropdownMenu" ngbDropdownToggle>
                        {{ personLanguage | translate }}
                        <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                        <button ngbDropdownItem value="NL" (click)="setPersonLanguage('NL')" translate>Language.Dutch</button>
                        <button ngbDropdownItem value="EN" (click)="setPersonLanguage('EN')" translate>Language.English</button>
                    </div>
                </div>
            </div>
            <div class="mb-3 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <label for="identificationMethod" class="form-label" translate="Request.Person.IdentificationMethod"><app-info-button labelName="Request.Person.IdentificationMethodInfoButton"></app-info-button></label>
                <div ngbDropdown class="dropdown" ngDefaultControl name="identificationMethod" id="identificationMethod">
                    <button class="btn language dropdown-toggle form-control text-truncate default" type="button" id="dropdownMenu" [disabled]="true">
                        {{ person.verifyRealIdentity ? ('ValidationMethod.iDIN' | translate) : ('ValidationMethod.' + person.identityProvider | translate) }}
                        <em class="bi bi-chevron-down float-end" aria-hidden="true"></em>
                    </button>
                </div>
            </div>

            <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <label for="message" class="form-label w-100" translate>
                    Request.Person.PersonalNote 

                    <span class="float-end">{{form.controls['message'].value?.length ?? 0}}/500</span>
                </label>
                <textarea rows="4" id="message" class="form-control" value="{{person.personalMessage}}" formControlName="message" maxlength="500"></textarea>
            </div>
        
            <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <app-closable-wrapper [title]="closeableWrapperTitle" [collapse]="false" [floatIconRight]="true" [help]="helpStatusEditPerson" [sessionStorageName]="'RequestBuilderPersonDialog'">
                    <!-- TODO List of other request of this persons -->
                </app-closable-wrapper>
            </div>

            <div class="mb-3 w-100">
                <button type="button" class="btn btn-sm btn-outline-primary me-1 float-start" (click)="back()" translate>Button.Close</button>
                <button type="submit" class="btn btn-sm btn-primary ms-1 order-2 float-end" (click)="save()" [disabled]="!form.valid" translate>Button.Save</button>
            </div>
        </form>       
    </div>
</div>