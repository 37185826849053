import { Pipe, PipeTransform } from '@angular/core';
import { FilterOptions } from '../models/filterOptions';
import { RequestItemInDashboard, RequestsInDashboard } from '../models/dashboard';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(requests: RequestsInDashboard, filterMetadata:any): any {
        let filterOptions : FilterOptions[] = this.getSessionStorageFilterParams();
        let filteredRequests : RequestsInDashboard = [];
        
        if (filterOptions.length === 0) {
            filterMetadata.count = -1;
            requests = requests.filter(request => request.status != undefined && request.status != null && request.status.toLowerCase() !== "expired");
            return requests;
        }

        if (filterOptions.length > 0 && !filterOptions.find(s => s.type.toLowerCase() === "show"))  {
            requests = requests.filter(request => request.status != undefined && request.status != null && request.status.toLowerCase() !== "expired");
        }

        if(filterOptions.filter(s => s.type.toLowerCase() === "status").length === 0) filteredRequests = requests;
        filterOptions.filter(s => s.type.toLowerCase() === "status")?.forEach(filter => {
            filteredRequests = filteredRequests.concat(requests.filter(request => request.status?.toLowerCase() === filter.value.toLowerCase()));
        });

        let filteredRequestsExpiresIn:RequestsInDashboard = [];
        filterOptions.filter(s => s.type.toLowerCase() === "expiresin")?.forEach(filter => {
            filteredRequestsExpiresIn = filteredRequestsExpiresIn.concat(filteredRequests.filter(request => 
                new Date(request.deadline!).getTime() < (new Date().getTime() + (1000 * 60 * 60 * Number(filter.value))) &&
                new Date(request.deadline!).getTime() > (new Date().getTime())
            ));
        });
        if(filterOptions.filter(s => s.type.toLowerCase() === "expiresin").length > 0) filteredRequests = filteredRequestsExpiresIn;
        
        let filteredRequestsWorkgroup:RequestsInDashboard = [];
        filterOptions.filter(s => s.type.toLowerCase() === "workgroup")?.forEach(filter => {
            filteredRequestsWorkgroup = filteredRequestsWorkgroup.concat(filteredRequests.filter(request => request.workgroupName!.toLowerCase().indexOf(filter.value.toLowerCase())> -1));
        });
        if(filterOptions.filter(s => s.type.toLowerCase() === "workgroup").length > 0) filteredRequests = filteredRequestsWorkgroup;

        let filteredRequestsType:RequestsInDashboard = [];
        filterOptions.filter(s => s.type.toLowerCase() === "type")?.forEach(filter => {
            filteredRequestsType = filteredRequestsType.concat(filteredRequests.filter(request => request.dossierType?.toLowerCase().indexOf(filter.value.toLowerCase())> -1));
        });
        if(filterOptions.filter(s => s.type.toLowerCase() === "type").length > 0) filteredRequests = filteredRequestsType;

        let filteredMyRequests:RequestsInDashboard = [];
        if(filterOptions.filter(s => s.type.toLowerCase() === "myrequests").length > 0)
        filterOptions?.forEach(filter => {
            filteredMyRequests = filteredMyRequests.concat(filteredRequests.filter(request => request.ownerGuid.toLowerCase() == filter.value.toLowerCase()));
        });

        if(filterOptions.filter(s => s.type.toLowerCase() === "myrequests").length > 0) filteredRequests = filteredMyRequests;

        let filteredRequestsSearch:RequestsInDashboard = [];
        filterOptions.filter(s => s.type.toLowerCase() === "search")?.forEach(filter => {
            filteredRequestsSearch = filteredRequestsSearch.concat(filteredRequests
                .filter(request => 
                    request.requestName.toLowerCase().indexOf(filter.value.toLowerCase())> -1 || 
                    request.ownerName.toLowerCase().indexOf(filter.value.toLowerCase())> -1 || 
                    request.previousActor?.toLowerCase().indexOf(filter.value.toLowerCase())> -1 || 
                    request.stage?.toLowerCase().indexOf(filter.value.toLowerCase())> -1));
        });

        if(filterOptions.filter(s => s.type.toLowerCase() === "search").length > 0) filteredRequests = filteredRequestsSearch;

        filterMetadata.count = filteredRequests.length;

        return this.removeDuplicateRequests(filteredRequests);
    }

    getSessionStorageFilterParams(){
        let params: FilterOptions[] = [];
        let filter = sessionStorage.getItem("Filter");
        if(filter === null) return params;
        let search = new URLSearchParams(filter);
        search.forEach(function(value, key) {
            params.push({
                type: key,
                value: value
            });

        });
        return params;
    }

    removeDuplicateRequests(filteredRequests: RequestsInDashboard) {
        let uniqueRequests = filteredRequests.reduce((unique: RequestsInDashboard, o: RequestItemInDashboard) => {
            if(!unique.some(obj => obj.requestId === o.requestId)) {
                unique.push(o);
            }
            return unique;
        },[]);
        return uniqueRequests;
    }
}