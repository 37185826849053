<ng-container *ngIf="IsHelpStyle()">
    <i class="bi bi-question-circle ms-1" placement="{{placement}}" [ngbTooltip]="tooltipText"
        [closeDelay]="url == '' ? 0 : 1000"></i>
    <ng-template #tooltipText>
        <span innerHTML="{{ labelName  | translate: {url: url} }}"></span>
    </ng-template>
</ng-container>

<ng-container *ngIf="IsAlertStyle()">
    <i class="bi bi-exclamation-circle-fill text-danger me-2" placement="{{placement}}" [ngbTooltip]="tooltipText"
        [closeDelay]="url == '' ? 0 : 1000"></i>
    <ng-template #tooltipText>
        <span innerHTML="{{ labelName  | translate: {url: url} }}"></span>
    </ng-template>
</ng-container>

<ng-container *ngIf="IsCloseableWrapperInfoStyle()">
    <i class="bi bi-question-circle ms-1 text-primary" placement="{{placement}}" [ngbTooltip]="tooltipText"
        [closeDelay]="url == '' ? 0 : 1000"></i>
    <ng-template #tooltipText>
        <span innerHTML="{{ labelName  | translate: {url: url} }}"></span>
    </ng-template>
</ng-container>