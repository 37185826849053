import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CannotContainOnlySpaceValidator } from 'src/app/directives/cannot-contain-only-space-validator.directive';
import { StringHelper } from 'src/app/directives/string-helper';
import { VerifyAsEmailValidator } from 'src/app/directives/verify-as-email-validator.directive';
import { VerifyAsMobileValidator } from 'src/app/directives/verify-as-mobile-validator.directive';
import { EditPersonModel } from 'src/app/models/api/actor-status-information.model';
import { UpdateExistingActorModel } from 'src/app/models/api/update-existing-actor.model';

@Component({
  templateUrl: './request-status-person-dialog.modal.html',
  providers: [NgbInputDatepickerConfig]
})
export class RequestStatusPersonDialogModal implements OnInit {

  @Input() personToEdit!: EditPersonModel;
  @ViewChild('mobileElement') mobileElement: any;
  form: UntypedFormGroup;
  emailControl: FormControl;
  phoneControl: FormControl;

  person!: EditPersonModel;
  personLanguage: string = 'Language.Dutch';
  translations: any = <any>{};

  stringHelper = new StringHelper();
  helpStatusEditPerson: string = 'Dialog.HelpStatusEditPerson';
  closeableWrapperTitle: string = 'Dialog.RequestsWillBeUpdated';

  constructor(private formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal,
    private translateService: TranslateService) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
    this.emailControl = new UntypedFormControl({ value: '', disabled: true }, [Validators.required, VerifyAsEmailValidator()])
    this.phoneControl = new FormControl({ value: '', disabled: true }, [Validators.required, CannotContainOnlySpaceValidator(), Validators.maxLength(15),VerifyAsMobileValidator()]);
    this.form = this.formBuilder.group(
      {
        firstname: new UntypedFormControl({ value: '', disabled: true }),
        prefix: new UntypedFormControl({ value: '', disabled: true }),
        lastname: new UntypedFormControl({ value: '', disabled: true }),
        email: this.emailControl,
        mobile: this.phoneControl,
        language: new UntypedFormControl({ value: '', disabled: true }),
        identificationMethod: new UntypedFormControl({ value: '', disabled: true }),
        message: new UntypedFormControl(({ value: '', disabled: true })),
        showPersonalNote: new UntypedFormControl({ value: false, disabled: true })
      });
  }

  getTranslations() {
    this.translateService.get([
      'Toasts.RequestsResendOne',
      'Toasts.RequestsResendOne'
    ]).subscribe(translation => {
      this.translations.toastsRequestsResendOne = translation['Toasts.RequestsResendOne'];
      this.translations.requestsWillBeUpdated = translation['Dialog.RequestsWillBeUpdated'];
    });
  }

  ngOnInit(): void {
    if (this.personToEdit) {
      // Clone personToEdit-object, so the object only changes when it is saved.
      this.person = { ...this.personToEdit };
      this.setPersonLanguage(this.person.language);

      this.form.controls['firstname'].setValue(this.person.firstname);
      this.form.controls['prefix'].setValue(this.person.prefix);
      this.form.controls['lastname'].setValue(this.person.lastname);
      this.form.controls['email'].setValue(this.person.email);
      this.form.controls['mobile'].setValue(this.person.phone);
      this.form.controls['message'].setValue(this.person.personalMessage);
      // enable the only fields you are allowed to
      this.form.controls['email'].enable();
      this.form.controls['mobile'].enable();
      this.form.controls['email'].enable();

    }
// add count of items that will be updated
    this.closeableWrapperTitle = this.translateService.instant('Dialog.RequestsWillBeUpdated', { count: 0 });
  }

  personChange(key: string, event: any): boolean {
    const formField = document.getElementById(key);
    if (!formField) return true;

    if (this.form.controls[key].invalid) {
      formField.classList.add('is-invalid');
      if (key === 'mobile') (event.target as HTMLInputElement).classList.add('is-invalid');
      return false;
    }

    formField.classList.remove('is-invalid');
    if (key === 'mobile') (event.target as HTMLInputElement).classList.remove('is-invalid');
    return true;
  }

  setPersonLanguage(language: string) {
    if (language === 'NL') {
      this.person.language = 'NL';
      this.personLanguage = 'Language.Dutch';
    } else {
      this.person.language = 'EN';
      this.personLanguage = 'Language.English';
    }
  }


  back() {
    this.activeModal.close();
  }

  save() {
    let returnModel = new UpdateExistingActorModel();
    returnModel.mobile = this.form.controls['mobile'].value;
    returnModel.email = this.form.controls['email'].value;
    returnModel.language = this.person.language;
    returnModel.personalMessage = this.form.controls['message'].value;
    this.activeModal.close(returnModel);
  }
}
