export enum BadgeStatusEnum {
    Info = 1,
    Success = 2,
    Warning = 3,
    Danger = 4,
    Secondary = 5,
    Primary = 6,
}

export enum PillBadgeStatusEnum {
    Info = "Info",
    Success = "Success",
    Warning = "Warning",
    Danger = "Danger",
    Secondary = "Secondary",
    Primary = "Primary"
}