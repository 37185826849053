import { DataTypeEnum } from "../constants/data-type.enum";

export class TableColumn {
    columnName!: string;
    transTag!: string;
    noOfColumns!: number;
    showMobile: boolean = false;
    type!: DataTypeEnum;
    visible: boolean = true;
}

export class TableColumnFilter {
    name!: string;
    isSelected: boolean = true;
    disabled: boolean = false;
}

/**
 * A helper function that can create columns, usefull to add non value columns (like 'actions')
 * @param propertyName property to display in the column, NOT TYPED
 * @param HeaderTranslationKey translation key (?)
 * @param dataType if any parsing of the value needs to be done, you can change this enum for default parsing/formatting
 * @param noOfColumns if not all columns need to be the same size use this to allow columns to be larger
 * @param showMobile whether this column should be displayed for mobile tables
 * @returns a TableColumn model with all properties set 
 */
export function CreateNonTypedColumn(propertyName: string, HeaderTranslationKey: string, dataType: DataTypeEnum = DataTypeEnum.General, noOfColumns: number = 2, showMobile: boolean = false) {

    var column = new TableColumn();
    column.columnName = propertyName.toString();
    column.transTag = HeaderTranslationKey;
    column.noOfColumns = noOfColumns;
    column.showMobile = showMobile;
    column.type = dataType;

    return column;
}
/**
 * A helper function that can create DEFAULT columns relying on the defaults set
 * THIS function allows us to later change defaults without having to go past ALL functions, but we can just set the defaults here if needed
 * @param propertyName property to display in the column, by typed name
 * @param HeaderTranslationKey translation key
 * @returns a TableColumn model with DEFAULT properties set
 */
export function CreateDefaultBasicColumn<T>(propertyName: keyof T, HeaderTranslationKey: string) {
    return CreateColumn<T>(propertyName, HeaderTranslationKey);
}


/**
 * A helper function that can create columns
 * @param propertyName property to display in the column, by typed name
 * @param HeaderTranslationKey translation key (?)
 * @param dataType if any parsing of the value needs to be done, you can change this enum for default parsing/formatting
 * @param noOfColumns if not all columns need to be the same size use this to allow columns to be larger
 * @param showMobile whether this column should be displayed for mobile tables
 * @returns a TableColumn model with all properties set 
 */
export function CreateColumn<T>(propertyName: keyof T, HeaderTranslationKey: string, dataType: DataTypeEnum = DataTypeEnum.General, noOfColumns: number = 2, showMobile: boolean = false, visible: boolean = true) {

    var column = new TableColumn();
    column.columnName = propertyName.toString();
    column.transTag = HeaderTranslationKey;
    column.noOfColumns = noOfColumns;
    column.showMobile = showMobile;
    column.type = dataType;
    column.visible = visible

    return column;
}

export function CreateColumnFilter(propertyName: string, defaultSelected: boolean = true, disabled: boolean = false) {

    var columnFilter = new TableColumnFilter();
    columnFilter.name = propertyName.toString();
    columnFilter.isSelected = defaultSelected;
    columnFilter.disabled = disabled;
    return columnFilter;
}
