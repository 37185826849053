<div class="rounded-corners">
<table class="mat-mdc-table">
  <tr class="mat-mdc-header-row">
    <th class="mat-mdc-header-cell mdc-data-table__header-cell" translate="Request.Status.Details"></th>
    <th class="mat-mdc-header-cell mdc-data-table__header-cell" translate="Request.Status.Data"></th>
  </tr>
  <ng-container *ngFor="let column of displayedColumns">
  <tr class="mat-mdc-row cols-12" *ngIf="column.columnName !== 'status'">
    <td class="mat-mdc-cell mdc-data-table__cell col-4" translate="Table.{{column.transTag}}"></td>
    <td class="mat-mdc-cell mdc-data-table__cell col-8 fw-bold">
      <app-status-display *ngIf="column.columnName === 'status'" [status]="tableData.status"
        [displayStatusString]="false" [displayStatusOrb]="true" class="badge-status-table"></app-status-display>
      {{ tableData[column.columnName] | valueByTypePipe : column.type }}
      <!-- Add copy to clipboard function, based on datatype Guid -->
      <ng-container *ngIf="column.type === 'Guid'">
        <em class="bi bi-copy cursor-pointer copy-icon" aria-hidden="true" (click)="copyToClipboard(tableData[column.columnName])"></em>
      </ng-container>
    </td>
  </tr>
  </ng-container>
</table>
</div>